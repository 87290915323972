import Icons from "../utils/icons";

class ScrollableContainer{

    arrowIcon = Icons.arrowIcon;

    element;
    navElement = null;
    type = 'paginated';
    initialPane = 0;
    isScrolling = false;
    numberOfPanes = 0;

    constructor(element,navElement = null, type = 'paginated', initialPane = 0) {

        if (element === null || element === undefined )
            return;
        if (!(element instanceof HTMLElement)) {
            throw new Error('Element must be an HTMLElement');
        }
        this.element = element;

        if (navElement !== null && navElement !== undefined) {
            if (!(navElement instanceof HTMLElement)) {
                throw new Error('navElementMain must be an HTMLElement');
            }
            this.navElement = navElement;
        }
        this.type = type;
        this.initialPane = initialPane;
        this.render();
        setTimeout(()=>{
            this.reInitialise();
        },0)
    }

    render() {
        const scrollableContainer = this.element;
        scrollableContainer.tabIndex = -1;

        switch (this.type) {
            case 'paginated':
                this.addPaginatedNav();
                break;
            case 'category':
                this.addCategoryNav();
                break;
        }


        // On scroll, check if we are at the start or end of the container and disable the nav buttons accordingly
        scrollableContainer.addEventListener('scroll', () => {
            if (this.navElement === null)
                return;

            this.leftNav.disabled = (scrollableContainer.scrollLeft === 0);
            const scrollMargin = 5;


            this.rightNav.disabled = (scrollableContainer.scrollLeft + scrollMargin >= scrollableContainer.scrollWidth - scrollableContainer.clientWidth);

            if (!this.leftNav.disabled && this.rightNav.disabled){
                scrollableContainer.classList.add('left-nav-active');
                scrollableContainer.classList.remove('right-nav-active');
                scrollableContainer.classList.remove('both-nav-active');
            }
            else if (this.leftNav.disabled && !this.rightNav.disabled){
                scrollableContainer.classList.remove('left-nav-active');
                scrollableContainer.classList.add('right-nav-active');
                scrollableContainer.classList.remove('both-nav-active');
            }
            else if (this.leftNav.disabled && this.rightNav.disabled){
                scrollableContainer.classList.remove('left-nav-active');
                scrollableContainer.classList.remove('right-nav-active');
                scrollableContainer.classList.remove('both-nav-active');
            }
            else{
                scrollableContainer.classList.remove('left-nav-active');
                scrollableContainer.classList.remove('right-nav-active');
                scrollableContainer.classList.add('both-nav-active');
            }

        });

        // Focus the container when the mouse is over it to enable key and scroll wheel navigation
        scrollableContainer.addEventListener('mouseover', () => {
            scrollableContainer.focus();
        });

        // Scroll the container a pane at a time when the mouse wheel is used
        scrollableContainer.addEventListener('wheel', (e) => {
            if (this.numberOfPanes < 2)
                return;
            e.preventDefault();
            if (this.isScrolling)
                return;
            e.deltaY < 0 ? this.scrollPaneLeft() : this.scrollPaneRight();
            this.isScrolling = true;
            setTimeout(()=>{
                this.isScrolling = false;
            }
            ,500);

        });



        this.initialisePosition(this.initialPane);


    }
    scrollPaneRight() {
        this.element.scrollBy({top:0,left:this.element.clientWidth,behavior:'smooth'});
    }

    // Scroll the container left a pane at a time
    scrollPaneLeft(){
        this.element.scrollBy({top:0,left:-this.element.clientWidth,behavior:'smooth'});
    }

    initialisePosition(initialPane = 0){
        this.element.scrollTo({top:0,left:this.element.clientWidth*initialPane,behavior:'instant'})
    }

    addCategoryNav(){
        if (this.navElement !== null) {
            const navMain = this.navElement;

            // Left nav button
            const containerLeft = document.createElement('div');
            containerLeft.classList.add('scrollable-container-nav');
            containerLeft.classList.add('left');
            containerLeft.classList.add('absolute');

            containerLeft.innerHTML = `
                <button class="scrollable-nav left">
                    <div class="scrollable-nav-icon left">${this.arrowIcon}</div>
                </button>
            `;

            const left = containerLeft.querySelector('.scrollable-nav');
            left.addEventListener('click', () => {
                this.scrollPaneLeft();
            });
            left.disabled = true;

            navMain.appendChild(containerLeft);

            // Right nav button
            const containerRight = document.createElement('div');
            containerRight.classList.add('scrollable-container-nav');
            containerRight.classList.add('right');
            containerRight.classList.add('absolute');

            containerRight.innerHTML = `
                <button class="scrollable-nav right">
                    <div class="scrollable-nav-icon right">${this.arrowIcon}</div>
                </button>
            `;
            const right = containerRight.querySelector('.scrollable-nav');
            right.addEventListener('click', () => {
                this.scrollPaneRight();
            });
            right.disabled = true;
            navMain.appendChild(containerRight);

            // Store the nav buttons for later use
            this.leftNav = left;
            this.rightNav = right;
        }
    }

    addPaginatedNav() {
        if (this.navElement !== null) {
            const navMain = this.navElement;
            const scrollableContainer = this.element;

            this.numberOfPanes = Math.round(scrollableContainer.scrollWidth / scrollableContainer.clientWidth);
            if (this.numberOfPanes < 2) {
                this.navElement = null;
                return;
            }

            // Left nav button
            const containerLeft = document.createElement('div');
            containerLeft.classList.add('scrollable-container-nav');
            containerLeft.classList.add('left');

            containerLeft.innerHTML = `
                <button class="paginated-scrollable-nav left">
                    <div class="scrollable-nav-icon left">${this.arrowIcon}</div>
                </button>
            `;

            const left = containerLeft.querySelector('.paginated-scrollable-nav');
            left.addEventListener('click', () => {
                this.scrollPaneLeft();
            });
            left.disabled = true;
            navMain.appendChild(containerLeft);

            // Add center pagination element to display the current scroll position and total number of scrollable panes

            const pagination = document.createElement('div');
            pagination.classList.add('scrollable-pagination');
            navMain.appendChild(pagination);

            for (let i = 0; i < this.numberOfPanes; i++) {
                const dot = document.createElement('span');
                dot.classList.add('page-dot');
                if (i === 0)
                    dot.classList.add('active');
                pagination.appendChild(dot);
            }

            scrollableContainer.addEventListener('scroll', () => {
                const dots = pagination.querySelectorAll('.page-dot');
                if (dots.length === 0)
                    return;
                const activeDot = Math.round(scrollableContainer.scrollLeft / scrollableContainer.clientWidth);
                if (activeDot < dots.length && activeDot >= 0) {
                    for (let i = 0; i < dots.length; i++) {
                        dots[i].classList.remove('active');
                    }
                    dots[activeDot].classList.add('active');
                }
            });

            // Right nav button
            const containerRight = document.createElement('div');
            containerRight.classList.add('scrollable-container-nav');
            containerRight.classList.add('right');

            containerRight.innerHTML = `
                <button class="paginated-scrollable-nav right">
                    <div class="scrollable-nav-icon right">${this.arrowIcon}</div>
                </button>
            `;
            const right = containerRight.querySelector('.paginated-scrollable-nav');
            right.addEventListener('click', () => {
                this.scrollPaneRight();
            });

            navMain.appendChild(containerRight);

            // Store the nav buttons for later use
            this.leftNav = left;
            this.rightNav = right;
        }
    }

    reInitialise(){
        this.element.dispatchEvent(new Event('scroll'));
    }


}
export default ScrollableContainer;