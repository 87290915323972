import {setCookie} from "../utils/cookieHelpers";

class FilterObject {
    filterName = 'filter';

    constructor(filterName = 'filter') {
        this.filterName = filterName;
        this.getQueryData();
    }

    filterData = {
        categories: [],
        days: [],
        durations: [],
        times: [],
        term: ''
    }

    addFilter(filterType, filterValue) {
        if (filterType === 'term') {
            this.filterData['term'] = filterValue;
            return;
        }

        if (this.filterData[filterType]?.indexOf(filterValue) === -1) {
            this.filterData[filterType].push(filterValue);
        }
    }

    removeFilter(filterType, filterValue) {
        if (filterType === 'term') {
            this.filterData['term'] = '';
            return;
        }

        const index = this.filterData[filterType]?.indexOf(filterValue);
        if (index !== -1) {
            this.filterData[filterType].splice(index, 1);
        }
    }

    getQueryData() {

        const queryData = new URLSearchParams(window.location.search);
        if (queryData.size === 0)
            return;

        if (queryData.has('categories') && queryData.get('categories') !== 'null') {
            this.filterData.categories = queryData.get('categories').split('_');
        }

        if (queryData.has('days') && queryData.get('days') !== 'null') {
            this.filterData.days = queryData.get('days').split('_');
        }

        if (queryData.has('durations') && queryData.get('durations') !== 'null') {
            this.filterData.durations = queryData.get('durations').split('_');
        }

        if (queryData.has('times') && queryData.get('times') !== 'null') {
            this.filterData.times = queryData.get('times').split('_');
        }

        if (queryData.has('filter') && queryData.get('filter') !== 'null') {
            this.filterData.term = queryData.get('filter') ?? '';
        }
    }

    hasFilters(includeCategories = true) {
        if (includeCategories)
            return this.filterData.categories.length > 0 || this.filterData.days.length > 0 || this.filterData.durations.length > 0 || this.filterData.times.length > 0 || this.filterData.term !== '';
        else
            return this.filterData.days.length > 0 || this.filterData.durations.length > 0 || this.filterData.times.length > 0 || this.filterData.term !== '';
    }

    static getCurrentFilterData() {

        let filterData = {
            categories: [],
            days: [],
            durations: [],
            times: [],
            term: ''
        }

        const filterItems = document.querySelectorAll('.filter-item');

        for (let item of filterItems) {
            let input = item.querySelector('input');
            if (input.checked) {
                switch (input.name){
                    case 'categories':
                        filterData.categories.push(input.value);
                        break;
                    case 'days':
                        filterData.days.push(input.value);
                        break;
                    case 'durations':
                        filterData.durations.push(input.value);
                        break;
                    case 'times':
                        filterData.times.push(input.value);
                        break;

                }
            }
        }

        const filterInput = document.querySelector('.filter-input input[type="text"]');

        if (filterInput.value.length > 0) {
            filterData.term = filterInput.value;
        }

        return filterData;
    }
}

export default FilterObject;