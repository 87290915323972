export function getCookie(cookieName){
    let name = cookieName + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let cookies = decodedCookie.split(';');
    let cookie = null;
    for(let c of cookies) {
        c = c.trimStart();
        if (c.indexOf(name) === 0) {
            cookie = c;
            break;
        }
    }
    if (cookie === null) {
        return null;
    }

    let cookieData = cookie.split('=')[1];
    cookieData = cookieData.split('|');
    if (cookieData.length === 0) {
        return null;
    }

    if (cookieData.length === 1){
        if (cookieData[0] === '' || cookieData[0] === 'null') {
            return null;
        }

        return decodeURIComponent(cookieData[0]);
    }

    let cookieObject = {};
    for (let data of cookieData) {
        let dataSplit = data.split(':');

        if (dataSplit.length !== 2) {
            continue;
        }

        if (dataSplit[1] === null || dataSplit[1].toLowerCase() === 'null') {
            cookieObject[dataSplit[0]] = null;
            continue;
        }

        cookieObject[dataSplit[0]] = decodeURIComponent(dataSplit[1]);
    }
    return cookieObject;
}

export function setCookie(cookieName, cookieData = null, maxAge = null){
    let cookieString = cookieName + "=";
    let ageString= '';

    if (maxAge !== null) {
        ageString = `;max-age=${maxAge}`;
    }

    if (cookieData === null) {
        document.cookie = `${cookieString};path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC`;
        return;
    }

    if (typeof cookieData === 'string') {
        document.cookie = `${cookieString}${cookieData};path=/${ageString}`;
        return;
    }

    for (let key in cookieData) {
        cookieString += key + ':' + encodeURIComponent(cookieData[key]) + '|';
    }
    cookieString = cookieString.slice(0, -1);

    document.cookie = `${cookieString};path=/${ageString}`;
}
