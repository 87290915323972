import FavouritesButton from "./favouritesButton";

class FavouriteButton{

    element = null;
    isFavourite = false;
    id = '';
    title = '';

    constructor(element, id, title, onPage = false){

        this.element = element;
        this.id = id;
        this.title = title;

        this.isFavourite = localStorage.getItem(`favourites`)?.split('|').filter(Boolean).indexOf(id) >= 0;
        this.isFavourite ? element.classList.add('saved') : element.classList.remove('saved');

        if (onPage) {
            this.render();
        }
    }

    render(){
        this.element.classList.add('favourite');

        this.element.addEventListener('click',()=>{
            if (this.isFavourite){
                let favourites = localStorage.getItem(`favourites`)?.split('|').filter(Boolean);
                if (favourites === null || favourites === undefined)
                    return;
                favourites.splice(favourites.indexOf(this.id),1);
                localStorage.setItem(`favourites`,favourites.join('|'));
            }
            else {
                let favourites = localStorage.getItem(`favourites`)?.split('|').filter(Boolean)
                if (favourites === null || favourites === undefined)
                    favourites = [];
                favourites.push(this.id);
                localStorage.setItem(`favourites`,favourites.join('|'));
            }

            this.isFavourite = !this.isFavourite;

            FavouritesButton.updateFavouritesCount();
            FavouritesButton.showNotification(this.title,this.isFavourite);

            if (this.isFavourite) {
                this.element.classList.add('saved');
            }
            else {
                if (this.element.parentElement.classList.contains('favourite-card'))
                    this.element.parentElement.remove();
                this.element.classList.remove('saved');
            }
        });
    }
}

export default FavouriteButton;