export async function getFavouriteProducts(offset,favourites) {
    const response = await fetch(`/api/services/products?source_type=favourites&offset=${offset}&code=${favourites}`);
    const json = await response.json();
    if (response.status !== 200) {
        return [];
    }
    return json;
}

export async function getDestinationProducts(destinationCode,offset,shelfId = null,params = null, filter = null) {

    let paramsString = '';
    if (params) {
        const from = params.from;
        const to = params.to;
        if (from){
            paramsString += '&from=' + from;
        }
        if (to){
            paramsString += '&to=' + to;
        }
    }

    let filterString = '';

    if (filter?.filterData.categories.length > 0)
        filterString += '&filter_category=' + filter.filterData.categories.join('|');

    if (filter?.filterData.days.length > 0)
        filterString += '&filter_day=' + filter.filterData.days.join('');

    if (filter?.filterData.durations.length > 0)
        filterString += '&filter_length=' + filter.filterData.durations.join('');

    if (filter?.filterData.times.length > 0)
        filterString += '&filter_time=' + filter.filterData.times.join('');

    if (filter?.filterData.term !== '')
        filterString += '&filter_term=' + filter.filterData.term;

    let shelfString = '';
    if (shelfId) {
        shelfString = '&shelf=' + shelfId;
    }

    const response = await fetch(`/api/services/products?source_type=destination&source=${destinationCode}&offset=${offset}${shelfString}${filterString}${paramsString}`);
    const json = await response.json();
    if (response.status !== 200) {
        return Promise.reject('Request failed');
    }

    return json;
}

export async function getCollectionProducts(collectionCode,offset,shelfId = null, params = null, filter = null) {

    let paramsString = '';
    if (params) {
        const from = params.from;
        const to = params.to;
        if (from){
            paramsString += '&from=' + from;
        }
        if (to){
            paramsString += '&to=' + to;
        }
    }

    let filterString = '';

    if (filter?.filterData.categories.length > 0)
        filterString += '&filter_category=' + filter.filterData.categories.join('|');

    if (filter?.filterData.days.length > 0)
        filterString += '&filter_day=' + filter.filterData.days.join('');

    if (filter?.filterData.durations.length > 0)
        filterString += '&filter_length=' + filter.filterData.durations.join('');

    if (filter?.filterData.times.length > 0)
        filterString += '&filter_time=' + filter.filterData.times.join('');

    if (filter?.filterData.term !== '')
        filterString += '&filter_term=' + filter.filterData.term;

    let shelfString = '';
    if (shelfId !== null) {
        shelfString = '&shelf=' + shelfId;
    }

    const response = await fetch(`/api/services/products?source_type=collection&source=${collectionCode}&offset=${offset}${shelfString}${filterString}${paramsString}`);
    const json = await response.json();
    if (response.status !== 200) {
        return Promise.reject('Request failed');
    }

    return json;
}

export async function getShelfProducts(destinationCode,offset,shelfId,params = null, filter = null) {

    let paramsString = '';
    if (params) {
        const from = params.from;
        const to = params.to;
        if (from){
            paramsString += '&from=' + from;
        }
        if (to){
            paramsString += '&to=' + to;
        }
    }



    let filterString = '';

    if (filter?.filterData.categories.length > 0)
        filterString += '&filter_category=' + filter.filterData.categories.join('|');

    if (filter?.filterData.days.length > 0)
        filterString += '&filter_day=' + filter.filterData.days.join('');

    if (filter?.filterData.durations.length > 0)
        filterString += '&filter_length=' + filter.filterData.durations.join('');

    if (filter?.filterData.times.length > 0)
        filterString += '&filter_time=' + filter.filterData.times.join('');

    if (filter?.filterData.term !== '')
        filterString += '&filter_term=' + filter.filterData.term;

    const response = await fetch(`/api/services/products?source_type=property&source=${destinationCode}&offset=${offset}${filterString}&shelf=${shelfId}${paramsString}`);
    const json = await response.json();
    if (response.status !== 200) {
        return Promise.reject('Request failed');
    }
    return json;
}

export async function getActivitySearchProducts(params, filter = null, codes = '') {

    let paramsString = '';
    if (params) {
        const from = params.from;
        const to = params.to;
        const term = params.term;
        if (from){
            paramsString += '&from=' + from;
        }
        if (to){
            paramsString += '&to=' + to;
        }
        if (term){
            paramsString += '&term=' + term;
        }
    }

    let filterString = '';

    if (filter?.filterData.categories.length > 0)
        filterString += '&filter_category=' + filter.filterData.categories.join('|');

    if (filter?.filterData.days.length > 0)
        filterString += '&filter_day=' + filter.filterData.days.join('');

    if (filter?.filterData.durations.length > 0)
        filterString += '&filter_length=' + filter.filterData.durations.join('');

    if (filter?.filterData.times.length > 0)
        filterString += '&filter_time=' + filter.filterData.times.join('');

    if (filter?.filterData.term !== '')
        filterString += '&filter_term=' + filter.filterData.term;


    const response = await fetch(`/api/services/products?source_type=activitysearch&code=${codes}${filterString}${paramsString}`);
    const json = await response.json();
    if (response.status !== 200) {
        return Promise.reject('Request failed');
    }

    return json;
}

export async function getEventsProducts(urlParams, majorCategories, minorCategories){

    let term = '';
    let dates = '';
    let categories = '';
    if (urlParams.term) {
        term = `term=${urlParams.term}`;
    }

    if (urlParams.start && urlParams.end) {
        if (term !== '') {
            dates = `&start=${urlParams.start}&end=${urlParams.end}`;
        }
        else{
            dates = `start=${urlParams.start}&end=${urlParams.end}`;
        }
    }

    if (majorCategories.length > 0 || minorCategories.length > 0) {

        if (term === '' && dates === '') {
            categories = `categories=`;
        }
        else {
            categories = `&categories=`;
        }

        if (majorCategories.length > 0) {
            categories += `${majorCategories.join(',')}`;
        }

        if (minorCategories.length > 0) {
            categories += `|${minorCategories.join(',')}`;
        }
    }

    const response = await fetch(`/api/services/search/events?${term}${dates}${categories}`);
    const json = await response.json();
    if (response.status !== 200) {
        return {status: 'failed', data:''};
    }

    return {status: 'success', data:json};
}