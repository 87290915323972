import RequestLimiter from "../utils/requestLimiter";
import DestinationSearch from "./destinationSearch";
import DatePickerDualMonth from "./datePickerDualMonth";
import DatePicker from "./datePicker";
import {getOfficeSlots} from "../services/carHire";
import {formatDate, formatTimeToAMPM, generateDate, getNextActiveDay} from "../utils/dateTimeHelpers";
import {getCookie, setCookie} from "../utils/cookieHelpers";
import OmniSearch from "./omniSearch";
import EventsSearch from "./eventSearch";
import TransfersSearch from "./transfersSearch";

class SearchWidget{

    i18n = com.tcl.data.i18n;

    element;
    mobileSize = false;
    headerSearchOpen = false;

    tabs = [];
    modeElements = [];
    summaryElements = [];

    requestLimiter;
    validationPattern = new RegExp(/^[\p{L}\p{N}\-\s\.,'/&]{3,}$/u);
    keyboardPosition = -1;

    widgetData = {
        currentMode: 'omni',
        modes : {
            omni: {
                displayName: this.i18n['i18n_SEARCH'],
                data: {
                    result: {
                        code: null,
                        url: null,
                        display: null,
                        secondary: ''
                    },
                    term: null,
                    dates: {
                        from: null,
                        to: null
                    }
                },
                instances: {
                    input: null,
                    dateElement : null,
                    dates: null,
                },
                redraw(that){
                    this.instances.input.setTerm(this.data.result.display ?? this.data.term, this.data.term === '' ? null : this.data.term);
                    this.instances.dates.setDates(this.data.dates);
                    that.displayDateRange(this.data.dates, this.instances.dateElement);
                },
                isValid(){
                    return (
                        (
                            this.data.result.code !== null &&
                            this.data.result.display !== null &&
                            this.data.result.url !== null
                        ) ||
                        (
                            this.data.term !== null &&
                            this.data.term !== '' &&
                            this.data.term.length >= 2
                        )
                    );
                },
                reset(target = null) {

                    if (target === 'result' || target === null) {
                        this.data.result = {
                            code: null,
                            url: null,
                            display: null,
                            secondary: ''
                        }
                    }

                    if (target === 'term' || target === null) {
                        this.data.term = null;
                    }

                    if (target === 'dates' || target === null) {
                        this.data.dates = {
                            from: null,
                            to: null
                        };
                    }

                }
            },
            carRental: {
                displayName: this.i18n['i18n_CAR_RENTAL'],
                data: {
                    locations: {
                        pickup : {
                            additionals: {},
                            code: null,
                            display: null,
                            slots: null
                        },
                        dropoff : {
                            additionals: {},
                            code: null,
                            display: null,
                            slots: null
                        }
                    },
                    dateTimes: {
                        from: {
                            date: null,
                            time: null
                        },
                        to: {
                            date: null,
                            time: null
                        }
                    },
                },
                instances: {
                    pickupLocation: null,
                    dropoffLocation: null,
                    pickupDatePicker: null,
                    pickupTimePicker: null,
                    dropoffDatePicker: null,
                    dropoffTimePicker: null,
                    pickupDateTimeElement: null,
                    dropoffDateTimeElement: null,
                },

                hasDifferentDropOff() {
                    return (
                        this.data.locations.pickup.code !== this.data.locations.dropoff.code && this.data.locations.dropoff.code !== null
                    );
                },

                redraw(that) {
                    this.instances.pickupLocation.setTerm(this.data.locations.pickup.display ? this.data.locations.pickup.display.split(',')[0] : '');
                    this.instances.dropoffLocation.setTerm(this.data.locations.dropoff.code !== this.data.locations.pickup.code ? this.data.locations.dropoff.display ? this.data.locations.dropoff.display.split(',')[0] : '' : '');
                    const from = this.data.dateTimes.from;
                    const to = this.data.dateTimes.to;
                    this.instances.pickupDatePicker?.setDate(from.date);
                    this.instances.dropoffDatePicker?.setDate(to.date);
                    this.instances.pickupTimePicker?.setTime(from.time);
                    this.instances.dropoffTimePicker?.setTime(to.time);
                    that.displayDateTime(from.date, from.time, this.instances.pickupDateTimeElement);
                    that.displayDateTime(to.date, to.time, this.instances.dropoffDateTimeElement);
                },

                isValid(){
                    return (
                        this.data.locations.slots !== null &&
                        this.data.locations.pickup.additionals !== null &&
                        this.data.locations.pickup.code !== null &&
                        this.data.locations.pickup.display !== null &&
                        this.data.locations.dropoff.additionals !== null &&
                        this.data.locations.dropoff.slots !== null &&
                        this.data.locations.dropoff.code !== null &&
                        this.data.locations.dropoff.display !== null &&
                        this.data.dateTimes.from.date !== null &&
                        this.data.dateTimes.from.time !== null &&
                        this.data.dateTimes.to.date !== null &&
                        this.data.dateTimes.to.time !== null
                    );
                },
                reset(target){

                    if (target === 'pickup' || target === null) {
                        this.data.locations.pickup = {
                            code: null,
                            display: null,
                            additionals: {},
                            slots: null
                        }
                    }

                    if (target === 'dropoff' || target === null) {
                        this.data.locations.dropoff ={
                            code: null,
                            display: null,
                            additionals: {},
                            slots: null
                        }
                    }

                    if (target === 'from' || target === null) {
                        this.data.dateTimes.from = {
                            date: null,
                            time: null
                        }
                    }

                    if (target === 'to' || target === null) {
                        this.data.dateTimes.to = {
                            date: null,
                            time: null
                        }
                    }
                }
            },
            transfers: {
                displayName: this.i18n['i18n_TRANSFERS'],
                data: {
                    result: {
                        type: null,
                        code: null,
                        url: null,
                        display: null,
                        secondary: ''
                    },
                    term: null,
                    date: null
                },
                instances: {
                    location: null,
                    dateElement : null,
                    date: null,
                },

                redraw(that){
                    this.instances.location.setTerm(this.data.result.display ?? this.data.term, this.data.term === '' ? null : this.data.term);
                    this.instances.date.setDate(this.data.date);
                    that.displayDate(this.data.date, this.instances.dateElement);
                },

                isValid(){
                    return (
                        (
                            this.data.result.type !== null &&
                            this.data.result.code !== null &&
                            this.data.result.display !== null &&
                            this.data.result.url !== null &&
                            this.data.date !== null
                        ) ||
                        (
                            this.data.term !== null &&
                            this.data.term !== '' &&
                            this.data.term.length >= 2 &&
                            this.data.date !== null
                        )
                    );
                },
                reset(target){

                    if (target === 'result' || target === null) {
                        this.data.result = {
                            type: null,
                            code: null,
                            url: null,
                            display: null,
                            secondary: ''
                        }
                    }

                    if (target === 'term' || target === null) {
                        this.data.term = null;
                    }

                    if (target === 'date' || target === null) {
                        this.data.date = null;
                    }
                }
            },
            events: {
                displayName: this.i18n['i18n_EVENTS'],
                data: {
                    term: null,
                    dates: {
                        from: null,
                        to: null
                    }
                },
                instances: {
                    input: null,
                    dateElement: null,
                    dates: null,
                },
                redraw(that) {
                    this.instances.input.setTerm(this.data.term, this.data.term === '' ? null : this.data.term);
                    this.instances.dates.setDates(this.data.dates);
                    that.displayDateRange(this.data.dates, this.instances.dateElement);
                },
                isValid() {
                    return (
                        (
                            this.data.term === null ||
                            this.data.term === '' ||
                            this.data.term.length >= 2
                        )
                    );
                },
                reset(target = null) {

                    if (target === 'term' || target === null) {
                        this.data.term = null;
                    }

                    if (target === 'dates' || target === null) {
                        this.data.dates = {
                            from: null,
                            to: null
                        };
                    }

                }
            }
        }
    };

    headerInstance = null;

    constructor(element, availableModes = ['omni','carRental','transfers','events'],headerInstance = null) {

        this.element = element;
        this.availableModes = availableModes;
        this.requestLimiter = new RequestLimiter();
        this.mobileSize = window.innerWidth <= 925;

        this.headerInstance = headerInstance;

        this.tabs = document.querySelectorAll('.sw-tab');
        this.modeElements = document.querySelectorAll('.sw-inputs');

        this.restoreWidgetData();

        if (window.location.pathname.match(/\/cars/)) {
            this.widgetData.currentMode = 'carRental';
        }
        else if (window.location.pathname.match(/\/transfers/)) {
            this.widgetData.currentMode = 'transfers';
        }
        else if (window.location.pathname.match(/\/events/)) {
            this.widgetData.currentMode = 'events';
        }
        else if (availableModes.includes('hotelActivities')) {
            this.widgetData.currentMode = 'hotelActivities';
        }
        else{
            this.widgetData.currentMode = 'omni';
        }

        if (availableModes.includes('omni'))
            this.initialiseOmniSearch();

        if (availableModes.includes('carRental'))
            this.initialiseCarRentalSearch();

        if (availableModes.includes('transfers'))
            this.initialiseTransferSearch();

        if (availableModes.includes('events'))
            this.initialiseEventSearch();

        this.initializeEventHandlers();

        this.setMode();

        return this;
    }

    initializeEventHandlers() {
        document.addEventListener('click', (event) => {
            const target = event.target;

            if (this.mobileSize) {

                const header = document.querySelector('header');

                if (!target.classList.contains('button') && target.closest('.search-widget') && !header.classList.contains('searchOpen')){
                    event.preventDefault();
                    event.stopPropagation();
                    header.classList.add('searchOpen');
                    header.querySelector('.burger-button .icon').classList.add('burgActive');
                    this.headerSearchOpen = true;
                    window.dispatchEvent(new Event('scroll'));
                }

                if (target.closest('.sw-summary') && !header.classList.contains('searchOpen')) {
                    event.preventDefault();
                    event.stopPropagation();
                    header.classList.add('searchOpen');
                    header.querySelector('.burger-button .icon').classList.add('burgActive');
                    this.headerSearchOpen = true;

                    if (this.headerInstance) {
                        this.headerInstance.searchToHeader();
                    }

                    window.dispatchEvent(new Event('scroll'));
                }


                if (target.closest('.burger-button') && this.headerSearchOpen){
                    event.preventDefault();
                    event.stopPropagation();
                    header.classList.remove('searchOpen');
                    this.headerSearchOpen = false;
                    header.querySelector('.burger-button .icon').classList.remove('burgActive');
                    window.dispatchEvent(new Event('scroll'));
                }
            }

            const tab = target.closest('.sw-tab');

            if (tab) {
                const mode = tab.dataset.mode;

                if (mode !== this.widgetData.currentMode){

                    if (this.widgetData.currentMode === 'hotelDirectory'){

                        if(mode === 'hotelActivities') {
                            document.getElementById('hero')?.classList.add('directory');
                            document.dispatchEvent(new CustomEvent('toggle-directory', {detail: {open: false}}));
                        }
                        else{
                            document.querySelector('header .search-widget.body')?.classList.remove('hidden');
                        }
                    }

                    if (this.widgetData.currentMode === 'hotelActivities'){

                        if(mode === 'hotelDirectory') {
                            document.getElementById('hero')?.classList.add('directory');
                            document.dispatchEvent(new CustomEvent('toggle-directory', {detail: {open: true}}));
                        }
                        else{
                            document.querySelector('header .search-widget.body')?.classList.remove('hidden');
                        }
                    }

                    if (!this.mobileSize) {

                        if (window.location.pathname !== '/' && mode === 'omni')
                        {
                            try {
                                window.open('/', '_self');
                            } catch {
                                window.location = '/';
                            }
                            return;
                        }

                        if (!window.location.pathname.match(/\/cars/) && mode === 'carRental')
                        {
                            try {
                                window.open('/cars','_self');
                            } catch {
                                window.location = '/cars';
                            }
                            return;
                        }

                        if (!window.location.pathname.match(/\/transfers/) && mode === 'transfers')
                        {
                            try {
                                window.open('/transfers', '_self');
                            } catch {
                                window.location = '/transfers';
                            }
                            return;
                        }

                        if (!window.location.pathname.match(/\/events/) && mode === 'events')
                        {
                            try {
                                window.open('/events', '_self');
                            } catch {
                                window.location = '/events';
                            }
                            return;
                        }

                    }

                    this.widgetData.currentMode = mode;
                    this.saveWidgetData();
                    this.setMode();
                }
            }
        });

        window.addEventListener('resize', () => {
            this.mobileSize = window.innerWidth <= 925;
            window.dispatchEvent(new Event('scroll'));
        });
    }

    showNotification(element, message) {

        if (!element || !message)
            return;

        if (element.classList.contains('notified'))
            return;

        const notification = document.createElement('div');
        notification.className = 'popup-info';
        notification.innerText = message;

        element.appendChild(notification);
        element.classList.add('notified')

        setTimeout(() => {
            notification.remove();
            element.classList.remove('notified')
        }, 1000);
    }

    compareKeys(object1, object2, deep = false) {

        if (object1 === null || object2 === null || typeof object1 !== 'object' || typeof object2 !== 'object' || object1 === undefined || object2 === undefined){
            return false;
        }


        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);


        if (keys1.length !== keys2.length)
            return false;

        for (let key of keys1) {
            if (!keys2.includes(key))
                return false;

            if (deep){
                const key1IsObject = typeof object1[key] === 'object' && object1[key] !== null;
                const key2IsObject = typeof object2[key] === 'object' && object2[key] !== null;
                if (key1IsObject || key2IsObject) {
                    if (key1IsObject && key2IsObject) {
                        if (!this.compareKeys(object1[key], object2[key], true)) {
                            return false;
                        }
                    }
                    else{
                        return false;
                    }
                }
            }
        }

        return true;
    }

    restoreWidgetData() {

        if (localStorage.getItem("searchWidget") !== null) {

            const savedData = JSON.parse(localStorage.getItem("searchWidget"));

            if (savedData.currentMode === undefined){
                localStorage.removeItem("searchWidget");
            }
            else {
                let unexpectedData = false;

                if (!this.availableModes.includes(savedData.currentMode)) {
                    this.widgetData.currentMode = this.availableModes[0];
                }
                else {
                    this.widgetData.currentMode = savedData.currentMode;
                }

                if (
                    !this.compareKeys(this.widgetData.modes['omni'].data, savedData['omni'],true) ||
                    !this.compareKeys(this.widgetData.modes['transfers'].data, savedData['transfers'], true) ||
                    !this.compareKeys(this.widgetData.modes['events'].data, savedData['events'], true)
                ) {
                    unexpectedData = true;
                    localStorage.removeItem("searchWidget");
                }

                if (!unexpectedData) {
                    this.widgetData.modes['omni'].data = savedData['omni'];
                    this.widgetData.modes['carRental'].data = savedData['carRental'];
                    this.widgetData.modes['transfers'].data = savedData['transfers'];
                    this.widgetData.modes['events'].data = savedData['events'];
                    this.checkForInvalidDates();
                }
            }

        }

        const query = new URLSearchParams(window.location.search);
        const queryFrom = query.get('from');
        const queryTo = query.get('to');
        const queryTerm = query.get('term');

        if ((queryFrom && queryTo) || queryTerm) {
            if (
                (window.location.pathname.split('-').pop().match(/^D/) && com.tcl.data.propertyName) ||
                window.location.pathname.match(/\/activities$/) ||
                window.location.pathname.match(/\/hotels\//)
            ) {

                const cookieDatesData = getCookie('availabilitySearchDates');
                const cookieTermData = getCookie('availabilitySearchTerm');

                let cookieData = null;
                if (cookieDatesData || cookieTermData){

                    cookieData = {
                        from:null,
                        to:null,
                        term:null
                    }

                    if (cookieDatesData){
                        cookieData.from = cookieDatesData.from;
                        cookieDatesData.to = cookieData.to;
                    }

                    if (cookieTermData){
                        cookieData.term = cookieTermData;
                    }

                }

                if (cookieData) {
                    if (cookieData.from) {
                        this.widgetData.modes['omni'].data.dates.from = cookieData.from.slice(0,4) + '-' + cookieData.from.slice(4,6) + '-' + cookieData.from.slice(6,8);
                    }
                    else{
                        this.widgetData.modes['omni'].reset('dates');
                    }

                    if (cookieData.to) {
                        this.widgetData.modes['omni'].data.dates.to = cookieData.to.slice(0,4) + '-' + cookieData.to.slice(4,6) + '-' + cookieData.to.slice(6,8);
                    }
                    else{
                        this.widgetData.modes['omni'].reset('dates');
                    }

                    if (cookieData.term) {
                        this.widgetData.modes['omni'].reset('result');
                        this.widgetData.modes['omni'].data.result.display = cookieData.term[0].toUpperCase() + cookieData.term.slice(1).toLowerCase();
                        this.widgetData.modes['omni'].data.term = cookieData.term;
                        this.widgetData.modes['omni'].data.result.secondary = '';
                    }
                    else{
                        this.widgetData.modes['omni'].reset('term');
                    }
                }
            }
        }

        const summary = document.getElementById('searchSummary');
        if (summary) {
            const summaryClass = summary.classList[summary.classList.length - 1];

            switch (summaryClass) {
                case 'transfers': {
                    const data = this.widgetData.modes['transfers'].data;
                    document.getElementById('sw-location').innerText = data.result.display ?? this.i18n['i18n_LOCATION'];
                    this.displayDate(data.date, document.getElementById('sw-date'), true);
                    break;
                }
                case 'carRental': {
                    const data = this.widgetData.modes['carRental'].data;

                    document.getElementById('sw-pickup').innerText = data.locations.pickup.display?.split(',')[0] ?? this.i18n['i18n_PICK_UP_LOCATIONS'];
                    document.getElementById('sw-dropoff').innerText = data.locations.dropoff.display?.split(',')[0] ?? this.i18n['i18n_DROP_OFF_LOCATIONS'];
                    this.displayDateTime(data.dateTimes.from.date, data.dateTimes.from.time, document.getElementById('sw-pickup-date-time'), true);
                    this.displayDateTime(data.dateTimes.to.date, data.dateTimes.to.time, document.getElementById('sw-dropoff-date-time'), true);

                    break;
                }
                case 'events': {
                    const data = this.widgetData.modes['events'].data;
                    document.getElementById('sw-head').innerText = data.term ?? this.i18n['i18n_FIND_YOUR_PERFECT_TICKET'];
                    this.displayDateRange(this.widgetData.modes['events'].data.dates, document.getElementById('sw-dates'), true);
                    break;
                }
                case 'product': {
                    this.displayDateRange(this.widgetData.modes['omni'].data.dates, document.getElementById('sw-dates'), true);
                    break;
                }
                case 'destination': {

                    let destination = this.i18n['i18n_DESTINATION'];
                    if (com.tcl.data.propertyName){
                        destination = com.tcl.data.propertyName;
                    }
                    else if (com.tcl.data.destination?.name){
                        destination = com.tcl.data.destination.name;
                    }
                    else if(com.tcl.data.destinationBranch){
                        destination = com.tcl.data.destinationBranch;
                    }

                    document.getElementById('sw-head').innerText = destination;
                    this.displayDateRange(this.widgetData.modes['omni'].data.dates, document.getElementById('sw-dates'), true);
                    break;
                }
                case 'searchResults': {
                    const data = this.widgetData.modes['omni'].data;
                    document.getElementById('sw-head').innerText = data.result.display ?? this.i18n['i18n_FIND_YOUR_NEXT_ADVENTURE'];
                    this.displayDateRange(data.dates, document.getElementById('sw-dates'), true);
                    break;
                }
            }
        }

    }

    checkForInvalidDates() {
        const today = new Date();
        today.setHours(0,0,0,0);

        if (generateDate(this.widgetData.modes['omni'].data.dates.from) < today){
            this.widgetData.modes['omni'].reset('dates');
        }
        if (generateDate(this.widgetData.modes['carRental'].data.dateTimes.from.date) < today){
            this.widgetData.modes['carRental'].reset('from');
            this.widgetData.modes['carRental'].reset('to');
        }
        if (generateDate(this.widgetData.modes['transfers'].data.date) < today) {
            this.widgetData.modes['transfers'].reset('date');
        }
        if (generateDate(this.widgetData.modes['events'].data.dates.from) < today){
            this.widgetData.modes['events'].reset('dates');
        }

        this.saveWidgetData();
    }

    saveWidgetData() {

        let savedData = {
            currentMode: this.widgetData.currentMode,
            omni: this.widgetData.modes['omni'].data,
            carRental: this.widgetData.modes['carRental'].data,
            transfers: this.widgetData.modes['transfers'].data,
            events: this.widgetData.modes['events'].data
        };

        localStorage.setItem("searchWidget", JSON.stringify(savedData));
    }

    setMode(inputMode = null) {

        if (inputMode){
            this.widgetData.currentMode = inputMode;
            this.saveWidgetData();
        }

        const mode = this.widgetData.currentMode;

        for (let tab of this.tabs) {

            tab.classList.remove('selected');

            if (tab.dataset.mode === mode){
                tab.classList.add('selected');
            }
        }

        if (mode === 'hotelDirectory') {
            document.querySelector('header .search-widget.body')?.classList.add('hidden');
            document.getElementById('hero')?.classList.add('directory');
            document.dispatchEvent(new CustomEvent('toggle-directory', {detail: {open: true}}));
        }

        if (mode === 'hotelActivities') {
            document.querySelector('header .search-widget.body')?.classList.add('hidden');
            document.getElementById('hero')?.classList.add('directory');
            document.dispatchEvent(new CustomEvent('toggle-directory', {detail: {open: false}}));
        }

        for (let modeElement of this.modeElements) {

            modeElement.classList.add('hidden');

            if (modeElement.classList.contains(mode)){
                this.widgetData.modes[mode].redraw(this);

                modeElement.classList.remove('hidden');

                let searchButton = modeElement.querySelector('.button');
                this.widgetData.modes[mode].isValid() ? searchButton.classList.remove('disabled') : searchButton.classList.add('disabled');
            }
        }

        document.dispatchEvent(new CustomEvent('search-mode-change', {}));
    }

    initialiseOmniSearch() {
        const thisMode = this.widgetData.modes['omni'];

        let element = null;

        for (let modeElement of this.modeElements) {
            if (modeElement.classList.contains('omni')){
                element = modeElement;
                break;
            }
        }

        if (!element)
            return;

        const searchButton = element.querySelector('.button');

        const omniElement = element.querySelector('.sw-input.destination');
        const omniCrumb = element.querySelector('.sw-input-crumb');

        const initialDisplay = thisMode.data.result.display ?? '';
        const initialSearchTerm = thisMode.data.term ?? '';

        if (thisMode.data.result.secondary !== '' && thisMode.data.result.secondary !== null && thisMode.data.result.secondary !== undefined) {
            omniCrumb.innerText = thisMode.data.result.secondary;
            omniCrumb.classList.remove('hidden');
        }
        else{
            omniCrumb.innerText = '';
            omniCrumb.classList.add('hidden');
        }

        let ignoreClick = false;

        thisMode.instances.input = new OmniSearch(element, omniElement, initialDisplay, initialSearchTerm, 50, thisMode.data);

        omniCrumb.addEventListener('click', () => {
            thisMode.instances.input.termInput.focus();
        });

        element.addEventListener('result-selected', (event) => {

            thisMode.data.result = event.detail.value;
            thisMode.data.term = event.detail.term;
            // Only sync with transfers if the result is a location not a property
            if (event.detail.type === 'location') {
                this.widgetData.modes['transfers'].data.result.code = event.detail.value.code ?? null;
                this.widgetData.modes['transfers'].data.result.display = event.detail.value.display;
            }

            if (thisMode.data.result.secondary) {
                omniCrumb.innerText = thisMode.data.result.secondary;
                omniCrumb.classList.remove('hidden');
            }
            else{
                omniCrumb.innerText = '';
                omniCrumb.classList.add('hidden');
            }

            thisMode.isValid() ? searchButton.classList.remove('disabled') : searchButton.classList.add('disabled');
            this.saveWidgetData();

            if(event.detail.type !== 'reset' && thisMode.data.dates.from === null) {
                dateDropdown.classList.remove('hidden');
                ignoreClick = true;
            }

        });

        element.addEventListener('input-changed', (event) => {

            thisMode.data.result = event.detail.value;
            thisMode.data.term = event.detail.term;

            if (thisMode.data.result.secondary) {
                omniCrumb.innerText = thisMode.data.result.secondary;
                omniCrumb.classList.remove('hidden');
            }
            else{
                omniCrumb.innerText = '';
                omniCrumb.classList.add('hidden');
            }

            thisMode.isValid() ? searchButton.classList.remove('disabled') : searchButton.classList.add('disabled');
            this.saveWidgetData();
        });

        element.addEventListener('search-requested', () => {
            if (thisMode.data.dates.from === null) {
                dateDropdown.classList.remove('hidden');
            }
            else if (thisMode.isValid()) {
                searchButton.click();
            }
        });

        const dateElement = element.querySelector('.sw-input.date-range');
        const dateDropdown = dateElement.querySelector('.sw-dropdown');
        const datePicker = dateDropdown.querySelector('.sw-dd-content');
        datePicker.classList.add('date-picker-dual');

        this.displayDateRange(thisMode.data.dates, dateElement);

        thisMode.instances.dateElement = dateElement;
        thisMode.instances.dates = new DatePickerDualMonth(datePicker, thisMode.data.dates, null, true);

        dateElement.addEventListener('click', (event) => {

            if (event.target.closest('.search-clear-button')) {
                dateDropdown.classList.add('hidden');
                datePicker.dispatchEvent(new CustomEvent('date-selected', {detail: {value: {from: null, to: null}}})); // Reset date picker
                thisMode.instances.dates.setDates({from: null, to: null});
                return;
            }
            else if (event.target.classList.contains('skip-btn') || event.target.classList.contains('apply-btn')){
                return;
            }
            dateDropdown.classList.remove('hidden');
        });

        document.addEventListener('click', (event) => {

            if (ignoreClick) {
                ignoreClick = false;
                return;
            }

            if (!event.target.closest('.sw-input.date-range') && !event.target.classList.contains('day')){
                dateDropdown.classList.add('hidden');
            }
        });

        datePicker.addEventListener('date-selected', (event) => {

            const dates = {
                from: event.detail.value.from,
                to: event.detail.value.to
            }

            if (dates.from !== null && dates.to !== null) {
                thisMode.data.dates = dates;
                // Sync dates with other modes
                this.widgetData.modes['transfers'].data.date = dates.from;
                this.widgetData.modes['carRental'].data.dateTimes.from.date = dates.from;
                this.widgetData.modes['carRental'].data.dateTimes.to.date = dates.to;
                this.widgetData.modes['events'].data.dates = dates;
            }
            else{
                thisMode.reset('dates');
                // Sync dates with other modes
                this.widgetData.modes['transfers'].reset('date');
                this.widgetData.modes['carRental'].reset('from');
                this.widgetData.modes['carRental'].reset('to');
                this.widgetData.modes['events'].reset('dates');
            }

            if (event.detail.type === 'skip'){
                thisMode.instances.dates.setDates({from: null, to: null});
            }

            this.displayDateRange(dates, dateElement);

            thisMode.isValid() ? searchButton.classList.remove('disabled') : searchButton.classList.add('disabled');
            this.saveWidgetData();

            if (event.detail.type === 'apply' || event.detail.type === 'skip') {
                dateDropdown.classList.add('hidden');
                searchButton.click();
            }
        });

        searchButton.addEventListener('click', (event) => {

            if (searchButton.classList.contains('disabled'))
                return;

            this.closeMobileHeaderSearch();

            const result = thisMode.data.result;

            const term = thisMode.data.term ? thisMode.data.term.toLowerCase() : null;

            const dates = thisMode.data.dates;
            const from = dates.from ? dates.from.replace(/-/g,'') : null;
            const to = dates.to ? dates.to.replace(/-/g,'') : null;

            setCookie('availabilitySearchTerm', term);

            setCookie('availabilitySearchDates', {
                    from: from,
                    to: to
                }
            );

            if (result.url !== null) {
                thisMode.instances.input.storeSearchTerm(thisMode.data.result.display, thisMode.data.term, thisMode.data.result.url);

                let target = '_self';

                if (result.url.match('\/hotels\/|\/activities\/')) {
                    target = '_blank';
                }

                try{
                    window.open(result.url, target);
                }
                catch{
                    window.location = result.url;
                }
            }
            else if (result.display !== null) {

                thisMode.instances.input.storeSearchTerm(thisMode.data.result.display, thisMode.data.term);
                try {
                    window.open(`/activities`, '_self');
                } catch {
                    window.location = `/activities`;
                }
            }

        });

        thisMode.isValid() ? searchButton.classList.remove('disabled') : searchButton.classList.add('disabled');
    }

    initialiseCarRentalSearch() {
        const thisMode = this.widgetData.modes['carRental'];

        let element = null;

        for (let modeElement of this.modeElements) {
            if (modeElement.classList.contains('carRental')){
                element = modeElement;
                break;
            }
        }

        let typeSelected = null;
        let ignoreClick = false;
        let ignoreDropClick = false;

        if (!element)
            return;

        const searchButton = element.querySelector('.button');

        const pickupElement = element.querySelector('.sw-input.pickup');
        const dropoffElement = element.querySelector('.sw-input.dropoff');

        thisMode.instances.pickupDateTimeElement = element.querySelector('.sw-input.date-time.from');
        thisMode.instances.dropoffDateTimeElement = element.querySelector('.sw-input.date-time.to');

        const locationData = {
            pickup: {
                allowToday: true,
                activeDays : [],
                slots: [
                    [], // Sunday
                    [], // Monday
                    [], // Tuesday
                    [], // Wednesday
                    [], // Thursday
                    [], // Friday
                    []  // Saturday
                ]
            },
            dropoff: {
                allowToday: true,
                activeDays : [],
                slots: [
                    [], // Sunday
                    [], // Monday
                    [], // Tuesday
                    [], // Wednesday
                    [], // Thursday
                    [], // Friday
                    []  // Saturday
                ]
            },
            dayIndex: {
                Sunday: 0,
                Monday: 1,
                Tuesday: 2,
                Wednesday: 3,
                Thursday: 4,
                Friday: 5,
                Saturday: 6
            },
            setData(key,data){
                if (data?.slots === null)
                    return;

                const activeDays = [];
                const today = new Date().getDay();
                this[key].allowToday = true;
                for(let day in data.slots) {
                    let dayIndex = this.dayIndex[day];

                    if (data.slots[day].length === 0) {
                        if (dayIndex === today){
                            this[key].allowToday = false;
                        }
                        continue;
                    }

                    if (dayIndex === today){
                        const slots = data.slots[day];
                        const lastSlot = slots[slots.length - 1];
                        const slotComponents = lastSlot.split(':');
                        const slotAsMinutes = parseInt(slotComponents[0]) * 60 + parseInt(slotComponents[1]);
                        const now = new Date();
                        const nowAsMinutes = now.getHours() * 60 + now.getMinutes();
                        if (slotAsMinutes < nowAsMinutes) {
                            this[key].allowToday = false;
                        }
                    }

                    activeDays.push(dayIndex);
                    this[key].slots[dayIndex] = data.slots[day];
                }
                this[key].activeDaysOfWeek = activeDays;
            },
            getDayTimes(key,dayIndex) {
                let times  = [];
                for(let time of this[key].slots[dayIndex]) {
                    times.push({value: time, label: formatTimeToAMPM(time)});
                }
                if (times.length === 0)
                    times.push({value: null, label: '--:--'});
                return times;
            },
        };

        locationData.setData('pickup', thisMode.data.locations.pickup);
        locationData.setData('dropoff', thisMode.data.locations.dropoff);

        const disablePastTimes = () =>{
            if(thisMode.data.dateTimes.from.date === null || thisMode.data.dateTimes.to.date === null || !thisMode.instances.dropoffTimePickerElement)
                return;

            if (thisMode.data.dateTimes.from.date === thisMode.data.dateTimes.to.date) {
                const dropOffTimeOptions = thisMode.instances.dropoffTimePickerElement.children;
                const fromTime = thisMode.data.dateTimes.from.time?.replace(':','');

                if (!fromTime || dropOffTimeOptions.length === 0)
                    return;

                for (let i = dropOffTimeOptions.length-1; i >= 0; i--) {
                    const option = dropOffTimeOptions[i];
                    if (parseInt(option.dataset.value.replace(':','')) <= parseInt(fromTime)) {
                        dropOffTimeOptions[i].classList.add('disabled');
                    }
                }
            }
        }

        const updateTimeSelect = (key, date) => {

            const picker = key === 'pickup' ? thisMode.instances.pickupTimePickerElement : thisMode.instances.dropoffTimePickerElement;

            if (!picker)
                return false;

            const dayTimes = locationData.getDayTimes(key, generateDate(date).getDay());
            if (dayTimes.length === 0) {
                return false;
            }

            const defaultTime = '10:00';

            const previousTime = key === 'pickup' ? thisMode.data.dateTimes.from.time : thisMode.data.dateTimes.to.time;


            picker.innerHTML = '';

            let options = [];

            for (let time of dayTimes) {
                const option = document.createElement('div');
                option.classList.add('time-option');
                option.dataset.value = time.value;
                option.innerHTML = time.label;

                option.addEventListener('click', () => {

                    if (option.classList.contains('disabled'))
                        return;

                    for (let option of options) {
                        option.classList.remove('selected');
                    }
                    option.classList.add('selected');

                    if (key === 'pickup') {
                        thisMode.data.dateTimes.from.time = option.dataset.value === 'null' ? null : option.dataset.value;
                        this.displayDateTime(thisMode.data.dateTimes.from.date,thisMode.data.dateTimes.from.time, thisMode.instances.pickupDateTimeElement);
                        updateTimeSelect('dropoff',thisMode.data.dateTimes.from.date);
                        disablePastTimes();
                    }
                    else {
                        thisMode.data.dateTimes.to.time = option.dataset.value === 'null' ? null : option.dataset.value;
                        this.displayDateTime(thisMode.data.dateTimes.to.date,thisMode.data.dateTimes.to.time, thisMode.instances.dropoffDateTimeElement);
                    }

                    this.saveWidgetData();

                    thisMode.isValid() ? searchButton.classList.remove('disabled') : searchButton.classList.add('disabled');

                    if (document.getElementById('sw-pickup')) {
                        document.getElementById('sw-pickup').innerText = thisMode.data.locations.pickup.display?.split(',')[0] ?? this.i18n['i18n_PICK_UP_LOCATIONS'];
                        document.getElementById('sw-dropoff').innerText = thisMode.data.locations.dropoff.display?.split(',')[0] ?? this.i18n['i18n_DROP_OFF_LOCATIONS'];
                        this.displayDateTime(thisMode.data.dateTimes.from.date, thisMode.data.dateTimes.from.time, document.getElementById('sw-pickup-date-time'), true);
                        this.displayDateTime(thisMode.data.dateTimes.to.date, thisMode.data.dateTimes.to.time, document.getElementById('sw-dropoff-date-time'), true);
                    }
                });

                picker.appendChild(option);
                options.push(option);
            }

            disablePastTimes();

            const availableOptions = options.filter(option => !option.classList.contains('disabled'));

            if (availableOptions.length > 0) {

                let tgtTime = parseInt(defaultTime.replace(':',''));
                if (previousTime) {
                    tgtTime = parseInt(previousTime.replace(':',''));
                }
                else if (key === 'pickup'){
                    const localNow = new Date();
                    const localTarget = parseInt(`${localNow.getHours()+1}${localNow.getMinutes()}`);
                    if (thisMode.data.dateTimes.from.date === formatDate(localNow)) {
                        tgtTime = localTarget;
                    }
                }

                const closestAvailable = availableOptions.reduce((prev, curr) => {
                    return (Math.abs(parseInt(prev.dataset.value.replace(':','')) - tgtTime) < Math.abs(parseInt(curr.dataset.value.replace(':','')) - tgtTime) ? prev : curr);
                });

                closestAvailable.dispatchEvent(new Event('click'));
                if (closestAvailable === options[options.length - 1] && key === 'pickup') {
                    thisMode.instances.pickupDatePicker.element.dispatchEvent(new CustomEvent('date-selected', {detail: {value: thisMode.data.dateTimes.from.date}}));
                }

            }
            return true;
        }

// PICKUP LOCATION
        let initialTermPickup = '';
        const pickupData= thisMode.data.locations.pickup;
        const pickupCrumb = element.querySelector('#pickupLocationCrumb');

        if (pickupData && pickupData.display !== null && pickupData.code !== null) {
            initialTermPickup = pickupData.display;
        }

        thisMode.instances.pickupLocation = new DestinationSearch(element, pickupElement,initialTermPickup,50,'carHireOffice');

        pickupCrumb.addEventListener('click', () => {
            thisMode.instances.pickupLocation.termInput.focus();
        });

        pickupElement.addEventListener('destination-selected', (event) => {
            if (event.detail.value && event.detail.value.code && event.detail.value.display) {

                if (!thisMode.hasDifferentDropOff()){
                    thisMode.data.locations.dropoff = event.detail.value;
                }

                thisMode.data.locations.pickup = event.detail.value;

                getOfficeSlots(event.detail.value.code).then((response) => {
                    locationData.setData('pickup', response);
                    thisMode.data.locations.pickup.slots = response.slots;
                    typeSelected = 'pickup';
                    element.dispatchEvent(new CustomEvent('office-slots-returned', {detail: {type: 'pickup'}}));

                    if(!thisMode.hasDifferentDropOff()){
                        locationData.setData('dropoff', response);
                        thisMode.data.locations.dropoff.slots = response.slots;
                        element.dispatchEvent(new CustomEvent('office-slots-returned', {detail: {type: 'dropoff'}}));
                    }
                });
            }
            else{
                thisMode.reset('pickup');
                fromDatePicker.dispatchEvent(new CustomEvent('date-selected', {detail: {value: null}}));

                thisMode.reset('dropoff');
                thisMode.instances.dropoffLocation.setTerm('');
                dropoffElement.dispatchEvent(new CustomEvent('destination-selected', {detail: {value: null,type: 'reset'}}));

            }

            thisMode.isValid() ? searchButton.classList.remove('disabled') : searchButton.classList.add('disabled');

            if (document.getElementById('sw-pickup')) {
                document.getElementById('sw-pickup').innerText = thisMode.data.locations.pickup.display?.split(',')[0] ?? this.i18n['i18n_PICK_UP_LOCATIONS'];
                document.getElementById('sw-dropoff').innerText = thisMode.data.locations.dropoff.display?.split(',')[0] ?? this.i18n['i18n_DROP_OFF_LOCATIONS'];
                this.displayDateTime(thisMode.data.dateTimes.from.date, thisMode.data.dateTimes.from.time, document.getElementById('sw-pickup-date-time'), true);
                this.displayDateTime(thisMode.data.dateTimes.to.date, thisMode.data.dateTimes.to.time, document.getElementById('sw-dropoff-date-time'), true);
            }

            let secondary = ''
            if (event.detail.value?.display){
                const displayArr = event.detail.value.display.split(',');

                if (displayArr.length > 0) {
                    for(let i= displayArr.length-1; i>=1; i--){
                        secondary = displayArr[i].trim() + ', ' + secondary;
                    }
                }

                secondary = secondary.slice(0,-2);
                const primary = displayArr[0];

                if (secondary.length > 0) {
                    pickupCrumb.innerText = secondary;
                    pickupCrumb.classList.remove('hidden');
                }
                else{
                    pickupCrumb.innerText = '';
                    pickupCrumb.classList.add('hidden');
                }

                thisMode.instances.pickupLocation.termInput.value = primary;
            }
            else{
                pickupCrumb.innerText = '';
                pickupCrumb.classList.add('hidden');
            }

            this.saveWidgetData();
        });

        pickupElement.addEventListener('input-changed', (event) => {

            pickupCrumb.innerText = '';
            pickupCrumb.classList.add('hidden');

        });


// DROPOFF LOCATION
        let initialTermDropoff = '';
        const dropCrumb = element.querySelector('#dropOffLocationCrumb');

        const dropoffData= thisMode.data.locations.dropoff;
        if (dropoffData && dropoffData.display !== null && dropoffData.code !== null) {
            initialTermDropoff = dropoffData.display;
        }

        dropoffElement.addEventListener('click', (e) => {
            if (thisMode.data.locations.pickup.code === null || thisMode.data.dateTimes.from.date === null || thisMode.data.dateTimes.from.time === null) {
                e.preventDefault();
                this.showNotification(dropoffElement, this.i18n['i18n_SELECT_PICKUP_LOCATION_FIRST'])
            }
        });

        thisMode.instances.dropoffLocation = new DestinationSearch(element, dropoffElement,initialTermDropoff,50,'carHireOffice');

        dropCrumb.addEventListener('click', () => {
            thisMode.instances.dropoffLocation.termInput.focus();
        });

        dropoffElement.addEventListener('destination-selected', (event) => {

            let value = event.detail.value;

            if (event.detail.type === 'reset'){
                if (thisMode.data.locations.pickup.code !== null) {
                    value = thisMode.data.locations.pickup;
                }
                else{
                    dropoffElement.querySelector('.sw-dd-content').innerHTML = '';
                }
            }

            if (value && value.code && value.display) {
                thisMode.data.locations.dropoff = value;

                getOfficeSlots(value.code).then((response) => {
                    locationData.setData('dropoff', response);
                    thisMode.data.locations.dropoff.slots = response.slots;

                    typeSelected = 'dropoff';
                    element.dispatchEvent(new CustomEvent('office-slots-returned', {detail: {type: 'dropoff'}}));
                });
            }
            else{
                thisMode.reset('dropoff');
            }

            thisMode.isValid() ? searchButton.classList.remove('disabled') : searchButton.classList.add('disabled');

            if (document.getElementById('sw-pickup')) {
                document.getElementById('sw-pickup').innerText = thisMode.data.locations.pickup.display?.split(',')[0] ?? this.i18n['i18n_PICK_UP_LOCATIONS'];
                document.getElementById('sw-dropoff').innerText = thisMode.data.locations.dropoff.display?.split(',')[0] ?? this.i18n['i18n_DROP_OFF_LOCATIONS'];
                this.displayDateTime(thisMode.data.dateTimes.from.date, thisMode.data.dateTimes.from.time, document.getElementById('sw-pickup-date-time'), true);
                this.displayDateTime(thisMode.data.dateTimes.to.date, thisMode.data.dateTimes.to.time, document.getElementById('sw-dropoff-date-time'), true);
            }

            let secondary = ''
            if (value?.display && event.detail.type !== 'reset'){
                const displayArr = value.display.split(',');

                if (displayArr.length > 0) {
                    for(let i= displayArr.length-1; i>=1; i--){
                        secondary = displayArr[i].trim() + ', ' + secondary;
                    }
                }

                secondary = secondary.slice(0,-2);
                const primary = displayArr[0];

                if (secondary.length > 0) {
                    dropCrumb.innerText = secondary;
                    dropCrumb.classList.remove('hidden');
                }
                else{
                    dropCrumb.innerText = '';
                    dropCrumb.classList.add('hidden');
                }

                thisMode.instances.dropoffLocation.termInput.value = primary;
            }
            else{
                dropCrumb.innerText = '';
                dropCrumb.classList.add('hidden');
            }


            this.saveWidgetData();
        });

        dropoffElement.addEventListener('input-changed', (event) => {

            dropCrumb.innerText = '';
            dropCrumb.classList.add('hidden');

        });

// PICKUP DATE TIME
        const fromElement = thisMode.instances.pickupDateTimeElement;
        const fromDropdown = fromElement.querySelector('.sw-dropdown');
        const fromDatePicker = fromDropdown.querySelector('.sw-dd-content .date-picker');

        thisMode.instances.pickupTimePickerElement = fromDropdown.querySelector('.sw-dd-content .time-picker');

        thisMode.instances.pickupDatePicker = new DatePicker(fromDatePicker, thisMode.data.dateTimes.from.date, locationData.pickup.activeDaysOfWeek, locationData.pickup.allowToday,null,null,false,true);
        updateTimeSelect('pickup', thisMode.data.dateTimes.from.date);

        this.displayDateTime(thisMode.data.dateTimes.from.date, thisMode.data.dateTimes.from.time, thisMode.instances.pickupDateTimeElement);

        fromElement.addEventListener('click', (event) => {
            if (event.target.closest('.search-clear-button')) {
                fromDropdown.classList.add('hidden');
                fromDatePicker.dispatchEvent(new CustomEvent('date-selected', {detail: {value: null}})); // Reset date picker
                thisMode.instances.pickupDatePicker.setDate(null);
                return;
            }
            else if (thisMode.data.locations.pickup.code === null) {
                event.preventDefault();
                this.showNotification(fromElement, this.i18n['i18n_SELECT_PICKUP_LOCATION_FIRST'])
                return;
            }
            else if(this.headerSearchOpen && !fromDropdown.classList.contains('hidden') && event.target.closest('.sw-input-holder')){
                fromDropdown.classList.add('hidden');
                return;
            }
            if(event.target.classList.contains('apply-btn')) {
                return;
            }

            fromDropdown.classList.remove('hidden');

            for (let option of thisMode.instances.pickupTimePickerElement.children) {
                if (option.classList.contains('selected')){
                    thisMode.instances.pickupTimePickerElement.scrollTop = option.offsetTop - 150;
                }
            }
        });

        document.addEventListener('click', (event) => {

            if (ignoreClick) {
                ignoreClick = false;
                return;
            }

            if (!event.target.closest('.sw-input.date-time.from') && !event.target.classList.contains('day')){
                fromDropdown.classList.add('hidden');
            }
        });

        fromDatePicker.addEventListener('date-selected', (event) => {

            const date = event.detail.value

            if (date !== null) {
                thisMode.data.dateTimes.from.date = formatDate(date);
                updateTimeSelect('pickup', thisMode.data.dateTimes.from.date);

                const activeDays = locationData.dropoff.activeDaysOfWeek;
                let pickup = generateDate(thisMode.data.dateTimes.from.date);
                const currentPickupDayOfMonth = pickup.getDate();

                const firstAllowedDay = formatDate(new Date(pickup.setDate(currentPickupDayOfMonth + 1)));
                const [nextDay,dateOfNextDay,todaySelected] = getNextActiveDay(activeDays, thisMode.data.dateTimes.to.date,false,firstAllowedDay);
                thisMode.instances.dropoffDatePicker.updateSettings(dateOfNextDay,activeDays,false,thisMode.data.dateTimes.from.date);
                toDatePicker.dispatchEvent(new CustomEvent('date-selected', {detail: {value: dateOfNextDay}}));

                if (event.detail.type ==='apply'){

                    ignoreDropClick = true;
                    toDropdown.classList.remove('hidden');
                    fromDropdown.classList.add('hidden');
                }

            }
            else{
                thisMode.reset('from');
                toDatePicker.dispatchEvent(new CustomEvent('date-selected', {detail: {value: null}}));
            }


            this.displayDateTime(thisMode.data.dateTimes.from.date, thisMode.data.dateTimes.from.time, thisMode.instances.pickupDateTimeElement);

            thisMode.isValid() ? searchButton.classList.remove('disabled') : searchButton.classList.add('disabled');

            if (document.getElementById('sw-pickup')) {
                document.getElementById('sw-pickup').innerText = thisMode.data.locations.pickup.display?.split(',')[0] ?? this.i18n['i18n_PICK_UP_LOCATIONS'];
                document.getElementById('sw-dropoff').innerText = thisMode.data.locations.dropoff.display?.split(',')[0] ?? this.i18n['i18n_DROP_OFF_LOCATIONS'];
                this.displayDateTime(thisMode.data.dateTimes.from.date, thisMode.data.dateTimes.from.time, document.getElementById('sw-pickup-date-time'), true);
                this.displayDateTime(thisMode.data.dateTimes.to.date, thisMode.data.dateTimes.to.time, document.getElementById('sw-dropoff-date-time'), true);
            }

            this.saveWidgetData();
        });

// DROPOFF DATE TIME
        const toElement = thisMode.instances.dropoffDateTimeElement;

        const toDropdown = toElement.querySelector('.sw-dropdown');
        const toDatePicker = toDropdown.querySelector('.sw-dd-content .date-picker');

        thisMode.instances.dropoffTimePickerElement = toDropdown.querySelector('.sw-dd-content .time-picker');

        thisMode.instances.dropoffDatePicker = new DatePicker(toDatePicker, thisMode.data.dateTimes.to.date, locationData.dropoff.activeDaysOfWeek, locationData.dropoff.allowToday, thisMode.data.dateTimes.from.date, null, false, true);
        updateTimeSelect('dropoff', thisMode.data.dateTimes.to.date);

        this.displayDateTime(thisMode.data.dateTimes.to.date, thisMode.data.dateTimes.to.time, thisMode.instances.dropoffDateTimeElement);

        toElement.addEventListener('click', (event) => {
            if (event.target.closest('.search-clear-button')) {
                toDropdown.classList.add('hidden');
                toDatePicker.dispatchEvent(new CustomEvent('date-selected', {detail: {value: null}})); // Reset date picker
                thisMode.instances.dropoffDatePicker.setDate(null);
                return;
            }
            else if (thisMode.data.locations.pickup.code === null ||thisMode.data.locations.dropoff.code === null || thisMode.data.dateTimes.from.date === null || thisMode.data.dateTimes.from.time === null) {
                event.preventDefault();
                this.showNotification(toElement, this.i18n['i18n_SELECT_DROPOFF_LOCATION_FIRST'])
                return;
            }
            else if(this.headerSearchOpen && !toDropdown.classList.contains('hidden')  && event.target.closest('.sw-input-holder')){
                toDropdown.classList.add('hidden');
                return;
            }
            else if(event.target.classList.contains('apply-btn')) {
                return;
            }

            toDropdown.classList.remove('hidden');

            for (let option of thisMode.instances.dropoffTimePickerElement.children) {
                if (option.classList.contains('selected')){
                    thisMode.instances.dropoffTimePickerElement.scrollTop = option.offsetTop - 150;
                }
            }

        });

        document.addEventListener('click', (event) => {

            if (ignoreDropClick) {
                ignoreDropClick = false;
                return;
            }

            if (!event.target.closest('.sw-input.date-time.to') && !event.target.classList.contains('day')){
                toDropdown.classList.add('hidden');
            }
        });

        toDatePicker.addEventListener('date-selected', (event) => {

            const date = event.detail.value

            if (date !== null) {
                thisMode.data.dateTimes.to.date = formatDate(date);
                updateTimeSelect('dropoff', thisMode.data.dateTimes.to.date);

                if (event.detail.type ==='apply'){
                    toDropdown.classList.add('hidden');
                }
            }
            else{
                thisMode.reset('to');
            }

            this.displayDateTime(thisMode.data.dateTimes.to.date, thisMode.data.dateTimes.to.time, thisMode.instances.dropoffDateTimeElement);

            thisMode.isValid() ? searchButton.classList.remove('disabled') : searchButton.classList.add('disabled');

            if (document.getElementById('sw-pickup')) {
                document.getElementById('sw-pickup').innerText = thisMode.data.locations.pickup.display?.split(',')[0] ?? this.i18n['i18n_PICK_UP_LOCATIONS'];
                document.getElementById('sw-dropoff').innerText = thisMode.data.locations.dropoff.display?.split(',')[0] ?? this.i18n['i18n_DROP_OFF_LOCATIONS'];
                this.displayDateTime(thisMode.data.dateTimes.from.date, thisMode.data.dateTimes.from.time, document.getElementById('sw-pickup-date-time'), true);
                this.displayDateTime(thisMode.data.dateTimes.to.date, thisMode.data.dateTimes.to.time, document.getElementById('sw-dropoff-date-time'), true);
            }

            this.saveWidgetData();

            if (event.detail.type ==='apply'){
                toDropdown.classList.add('hidden');
                searchButton.dispatchEvent(new Event('click'));
            }
        });

// SEARCH BUTTON
        searchButton.addEventListener('click', (event) => {

            if (searchButton.classList.contains('disabled'))
                return;

            this.saveWidgetData();
            this.storeCarSearchData();

            this.closeMobileHeaderSearch();

            if (window.location.href.includes('/cars/search')) {

                this.element.dispatchEvent(new CustomEvent('search-cars'));
            }
            else {

                try {
                    window.open(`/cars/search`, '_self');
                } catch {
                    window.location = `/cars/search`;
                }
            }
        });


        element.addEventListener('office-slots-returned',(e)=> {
            const key = e.detail.type;

            if (thisMode.data.dateTimes.from.date === null || thisMode.data.dateTimes.to.date === null) {
                const today = new Date();
                const todayDateNumber = today.getDate();

                let tomorrow = new Date();
                tomorrow.setDate(todayDateNumber + 1);

                let dayAfterTomorrow = new Date()
                dayAfterTomorrow.setDate(todayDateNumber + 2);
                if (thisMode.data.dateTimes.from.date === null )
                    thisMode.data.dateTimes.from.date = formatDate(tomorrow);
                if (thisMode.data.dateTimes.to.date === null )
                    thisMode.data.dateTimes.to.date = formatDate(dayAfterTomorrow);
            }

            if (key === 'pickup') {

                const activeDays = locationData[key].activeDaysOfWeek;
                const allowToday = locationData[key].allowToday;

                const [nextDay,dateOfNextDay,todaySelected] = getNextActiveDay(locationData[key].activeDaysOfWeek, thisMode.data.dateTimes.from.date,true,null);

                thisMode.instances.pickupDatePicker.updateSettings(dateOfNextDay, activeDays, allowToday);
                thisMode.data.dateTimes.from.date = formatDate(dateOfNextDay);

                this.displayDateTime(thisMode.data.dateTimes.from.date, thisMode.data.dateTimes.from.time, thisMode.instances.pickupDateTimeElement);
                updateTimeSelect(key, thisMode.data.dateTimes.from.date);
                //updateDropOffDateTime();

            }
            else if (key === 'dropoff') {

                const activeDays = locationData[key].activeDaysOfWeek;
                let pickup = generateDate(thisMode.data.dateTimes.from.date);

                const currentPickupDayOfMonth = pickup.getDate();
                const firstAllowedDay = formatDate(new Date(pickup.setDate(currentPickupDayOfMonth + 1)));
                const [nextDay,dateOfNextDay,todaySelected] = getNextActiveDay(activeDays, thisMode.data.dateTimes.to.date,false,firstAllowedDay);

                thisMode.instances.dropoffDatePicker.updateSettings(dateOfNextDay,activeDays,false,thisMode.data.dateTimes.from.date);
                thisMode.data.dateTimes.to.date = formatDate(dateOfNextDay);

                this.displayDateTime(thisMode.data.dateTimes.to.date, thisMode.data.dateTimes.to.time, thisMode.instances.dropoffDateTimeElement);
                updateTimeSelect(key, thisMode.data.dateTimes.to.date);
            }

            thisMode.isValid() ? searchButton.classList.remove('disabled') : searchButton.classList.add('disabled');

            if (key === 'pickup' && typeSelected === 'pickup') {
                ignoreClick = true;
                fromElement.dispatchEvent(new Event('click'));
            }
            else if (key === 'dropoff' && typeSelected === 'dropoff') {
                ignoreDropClick = true;
                toElement.dispatchEvent(new Event('click'));
            }
        });

        let secondary = ''
        if (thisMode.data.locations.pickup?.display){
            const displayArr = thisMode.data.locations.pickup.display.split(',');

            if (displayArr.length > 0) {
                for(let i= displayArr.length-1; i>=1; i--){
                    secondary = displayArr[i].trim() + ', ' + secondary;
                }
            }

            secondary = secondary.slice(0,-2);
            const primary = displayArr[0];

            if (secondary.length > 0) {
                pickupCrumb.innerText = secondary;
                pickupCrumb.classList.remove('hidden');
            }
            else{
                pickupCrumb.innerText = '';
                pickupCrumb.classList.add('hidden');
            }

            thisMode.instances.pickupLocation.termInput.value = primary;
        }

        secondary = ''
        if (thisMode.data.locations.dropoff.display && thisMode.data.locations.dropoff.code !== thisMode.data.locations.pickup.code){
            const displayArr = thisMode.data.locations.dropoff.display.split(',');

            if (displayArr.length > 0) {
                for(let i= displayArr.length-1; i>=1; i--){
                    secondary = displayArr[i].trim() + ', ' + secondary;
                }
            }

            secondary = secondary.slice(0,-2);
            const primary = displayArr[0];

            if (secondary.length > 0) {
                dropCrumb.innerText = secondary;
                dropCrumb.classList.remove('hidden');
            }
            else{
                dropCrumb.innerText = '';
                dropCrumb.classList.add('hidden');
            }

            thisMode.instances.dropoffLocation.termInput.value = primary;
        }
        else{
            dropCrumb.innerText = '';
            dropCrumb.classList.add('hidden');
        }

    }

    initialiseTransferSearch() {
        const thisMode = this.widgetData.modes['transfers'];

        let element = null;

        for (let modeElement of this.modeElements) {
            if (modeElement.classList.contains('transfers')){
                element = modeElement;
                break;
            }
        }

        if (!element)
            return;

        const searchButton = element.querySelector('.button');

        const searchElement = element.querySelector('.sw-input.destination');
        const transferCrumb = element.querySelector('#transfersDestCrumb');

        const initialDisplay = thisMode.data.result.display ?? '';
        const initialSearchTerm = thisMode.data.term ?? '';

        let ignoreClick = false;

        if (thisMode.data.result.secondary !== '' && thisMode.data.result.secondary !== null && thisMode.data.result.secondary !== undefined) {
            transferCrumb.innerText = thisMode.data.result.secondary;
            transferCrumb.classList.remove('hidden');
        }
        else{
            transferCrumb.innerText = '';
            transferCrumb.classList.add('hidden');
        }

        thisMode.instances.location = new TransfersSearch(element, searchElement,initialDisplay,initialSearchTerm,50, thisMode.data);

        transferCrumb.addEventListener('click', () => {
            thisMode.instances.location.termInput.focus();
        });

        element.addEventListener('result-selected', (event) => {

            thisMode.data.result = event.detail.value;
            thisMode.data.term = event.detail.term;

            if (thisMode.data.result.secondary) {
                transferCrumb.innerText = thisMode.data.result.secondary;
                transferCrumb.classList.remove('hidden');
            }
            else{
                transferCrumb.innerText = '';
                transferCrumb.classList.add('hidden');
            }

            if (window.location.pathname.includes('transfers')) {
                this.element.dispatchEvent(new CustomEvent('clear-transfers'));
            }

            thisMode.isValid() ? searchButton.classList.remove('disabled') : searchButton.classList.add('disabled');
            this.saveWidgetData();

            if (event.detail.type !== 'reset' && thisMode.data.date === null) {
                dateElement.dispatchEvent(new Event('click'));
                ignoreClick = true;
            }

            if (document.getElementById('sw-location')) {
                document.getElementById('sw-location').innerText = thisMode.data.result.display ?? this.i18n['i18n_LOCATION'];
                this.displayDate(thisMode.data.date, document.getElementById('sw-date'), true);
            }

        });

        element.addEventListener('input-changed', (event) => {

            thisMode.data.result = event.detail.value;
            thisMode.data.term = event.detail.term;

            if (thisMode.data.result.secondary) {
                transferCrumb.innerText = thisMode.data.result.secondary;
                transferCrumb.classList.remove('hidden');
            }
            else{
                transferCrumb.innerText = '';
                transferCrumb.classList.add('hidden');
            }

            thisMode.isValid() ? searchButton.classList.remove('disabled') : searchButton.classList.add('disabled');
            this.saveWidgetData();
        });

        element.addEventListener('search-requested', () => {
            if (thisMode.data.date === null) {
                dateElement.dispatchEvent(new Event('click'));
            }
            else if (thisMode.isValid()) {
                searchButton.click();
            }
        });

        const dateElement = element.querySelector('.sw-input.date');
        const dateDropdown = dateElement.querySelector('.sw-dropdown');
        const datePicker = dateDropdown.querySelector('.sw-dd-content');
        datePicker.classList.add('date-picker');

        this.displayDate(thisMode.data.date, dateElement);

        thisMode.instances.dateElement = dateElement;
        thisMode.instances.date = new DatePicker(datePicker, thisMode.data.date, null, true,null,null,false,true);

        dateElement.addEventListener('click', (event) => {

            if (event.target.closest('.search-clear-button')) {
                dateDropdown.classList.add('hidden');
                datePicker.dispatchEvent(new CustomEvent('date-selected', {detail: {value: null}})); // Reset date picker
                thisMode.instances.date.setDate(null);
                return;
            }
            else if (event.target.classList.contains('skip-btn') || event.target.classList.contains('apply-btn')) {
                return;
            }
            else if(event.target.closest('.day') && this.mobileSize && this.headerSearchOpen) {
                dateDropdown.classList.add('hidden');
                return;
            }

            dateDropdown.classList.remove('hidden');
        });

        document.addEventListener('click', (event) => {

            if (ignoreClick) {
                ignoreClick = false;
                return;
            }

            if (!event.target.closest('.sw-input.date') && !event.target.classList.contains('day')){
                dateDropdown.classList.add('hidden');
            }
        });

        datePicker.addEventListener('date-selected', (event) => {

            if(this.mobileSize && this.headerSearchOpen) {
                dateDropdown.classList.add('hidden');
            }

            if (event.detail.value !== null) {
                thisMode.data.date = formatDate(event.detail.value);
            }
            else{
                thisMode.reset('date');
            }

            if (window.location.pathname.includes('transfers')) {
                this.element.dispatchEvent(new CustomEvent('clear-transfers'));
            }

            if (event.detail.type === 'skip'){
                thisMode.instances.date.setDate(null);
            }

            this.displayDate(thisMode.data.date, dateElement);

            thisMode.isValid() ? searchButton.classList.remove('disabled') : searchButton.classList.add('disabled');
            this.saveWidgetData();

            if (document.getElementById('sw-location')) {
                document.getElementById('sw-location').innerText = thisMode.data.result.display ?? this.i18n['i18n_LOCATION'];
                this.displayDate(thisMode.data.date, document.getElementById('sw-date'), true);
            }

            if (event.detail.type === 'apply' || event.detail.type === 'skip') {
                dateDropdown.classList.add('hidden');
                searchButton.dispatchEvent(new Event('click'));
            }
        });

        searchButton.addEventListener('click', (event) => {

            if (searchButton.classList.contains('disabled'))
                return;

            this.closeMobileHeaderSearch();

            const data = thisMode.data;

            if (data.result.type === 'activity'){

                if (data.result.code && data.result.url) {

                    localStorage.setItem('useTransferDate',data.result.code);

                    try {
                        window.open(data.result.url, '_blank');
                    }
                    catch (e) {
                        window.location = data.result.url;
                    }
                }
                return;
            }

            if (data.result.type === 'location') {
                const destinationCode = thisMode.data.result.code;
                let date = thisMode.data.date;
                date = date.replace(/-/g, '');

                try {
                    window.open(`/transfers/search?destination=${destinationCode}&date=${date}`, '_self');
                }
                catch {
                    window.location = `/transfers/search?destination=${destinationCode}&date=${date}`;
                }

                return;
            }

            if (data.result.type === 'term'){
                const term = thisMode.data.term;
                let date = thisMode.data.date;
                date = date.replace(/-/g, '');

                try {
                    window.open(`/transfers/search?term=${term}&date=${date}`, '_self');
                }
                catch {
                    window.location = `/transfers/search?term=${term}&date=${date}`;
                }

                return;

            }

        });

        thisMode.isValid() ? searchButton.classList.remove('disabled') : searchButton.classList.add('disabled');
    }

    initialiseEventSearch() {
        const thisMode = this.widgetData.modes['events'];

        let element = null;

        for (let modeElement of this.modeElements) {
            if (modeElement.classList.contains('events')){
                element = modeElement;
                break;
            }
        }

        if (!element)
            return;

        const searchButton = element.querySelector('.button');

        const eventElement = element.querySelector('.sw-input.destination');

        const initialSearchTerm = thisMode.data.term ?? '';

        let ignoreClick = false;

        thisMode.instances.input = new EventsSearch(element, eventElement, initialSearchTerm, 50, thisMode.data);


        element.addEventListener('result-selected', (event) => {

            thisMode.data.term = event.detail.term;

            thisMode.isValid() ? searchButton.classList.remove('disabled') : searchButton.classList.add('disabled');
            this.saveWidgetData();

            if(event.detail.type !== 'reset' && thisMode.data.dates.from === null) {
                dateDropdown.classList.remove('hidden');
                ignoreClick = true;
            }

        });

        element.addEventListener('input-changed', (event) => {

            thisMode.data.term = event.detail.term;

            thisMode.isValid() ? searchButton.classList.remove('disabled') : searchButton.classList.add('disabled');
            this.saveWidgetData();
        });

        element.addEventListener('search-requested', () => {
            if (thisMode.data.dates.from === null) {
                dateDropdown.classList.remove('hidden');
            }
            else if (thisMode.isValid()) {
                searchButton.click();
            }
        });

        const dateElement = element.querySelector('.sw-input.date-range');
        const dateDropdown = dateElement.querySelector('.sw-dropdown');
        const datePicker = dateDropdown.querySelector('.sw-dd-content');
        datePicker.classList.add('date-picker-dual');

        this.displayDateRange(thisMode.data.dates, dateElement);

        thisMode.instances.dateElement = dateElement;
        thisMode.instances.dates = new DatePickerDualMonth(datePicker, thisMode.data.dates, null, true);

        dateElement.addEventListener('click', (event) => {

            if (event.target.closest('.search-clear-button')) {
                dateDropdown.classList.add('hidden');
                datePicker.dispatchEvent(new CustomEvent('date-selected', {detail: {value: {from: null, to: null}}})); // Reset date picker
                thisMode.instances.dates.setDates({from: null, to: null});
                return;
            }
            else if (event.target.classList.contains('skip-btn') || event.target.classList.contains('apply-btn')){
                return;
            }
            dateDropdown.classList.remove('hidden');
        });

        document.addEventListener('click', (event) => {

            if (ignoreClick) {
                ignoreClick = false;
                return;
            }

            if (!event.target.closest('.sw-input.date-range') && !event.target.classList.contains('day')){
                dateDropdown.classList.add('hidden');
            }
        });

        datePicker.addEventListener('date-selected', (event) => {

            const dates = {
                from: event.detail.value.from,
                to: event.detail.value.to
            }

            if (dates.from !== null && dates.to !== null) {
                thisMode.data.dates = dates;
            }
            else{
                thisMode.reset('dates');
            }

            if (event.detail.type === 'skip'){
                thisMode.instances.dates.setDates({from: null, to: null});
            }

            this.displayDateRange(dates, dateElement);

            thisMode.isValid() ? searchButton.classList.remove('disabled') : searchButton.classList.add('disabled');
            this.saveWidgetData();

            if (event.detail.type === 'apply' || event.detail.type === 'skip') {
                dateDropdown.classList.add('hidden');
                searchButton.click();
            }
        });

        searchButton.addEventListener('click', (event) => {

            if (searchButton.classList.contains('disabled'))
                return;

            this.closeMobileHeaderSearch();

            const term = thisMode.data.term ? thisMode.data.term.toLowerCase() : null;

            if (term === undefined)
                return;

            const dates = thisMode.data.dates ?? null;

            if (dates === null)
                return;


            const from = dates.from ? dates.from.replace(/-/g,'') : null;
            const to = dates.to ? dates.to.replace(/-/g,'') : null;

            let queryString = '';

            if (term !== null && term !== '') {

                if (queryString === '') {
                    queryString += '?';
                }
                else {
                    queryString += '&';
                }

                queryString +=`term=${term}`;
            }

            if(from !== null && to !== null){
                if (queryString === '') {
                    queryString += '?';
                }
                else {
                    queryString += '&';
                }

                queryString += `start=${from}&end=${to}`;
            }

            thisMode.instances.input.storeSearchTerm(thisMode.data.term);

            try {
                window.open(`/events/search${queryString}`, '_self');
            } catch {
                window.location = `/events/search${queryString}`;
            }

        });

        thisMode.isValid() ? searchButton.classList.remove('disabled') : searchButton.classList.add('disabled');
    }

    closeMobileHeaderSearch(){
        if (this.mobileSize && this.headerSearchOpen) {
            const header = document.querySelector('header');
            header.classList.remove('searchOpen');
            this.headerSearchOpen = false;
            header.querySelector('.burger-button .icon').classList.remove('burgActive');
            window.dispatchEvent(new Event('scroll'));
        }
    }

    displayDate(date,dateElement , textOnly = false) {

        let dateString = this.i18n['i18n_WHEN_ARE_YOU_TRAVELLING'];

        const displayElement = textOnly ? dateElement : dateElement.querySelector('.sw-input-field h2');

        if (date) {

            const UTCStringArr = new Date(date).toUTCString().split(/[\s,]+/);

            dateString = `${UTCStringArr[0]}, ${UTCStringArr[2]} ${UTCStringArr[1]}`;

            if (this.mobileSize && !this.headerSearchOpen) {
                dateString = `${UTCStringArr[2]} ${UTCStringArr[1]}`;
            }

            if (!textOnly) {
                dateElement.querySelector('.search-clear-button').classList.remove('hidden');
                displayElement.classList.remove('placeholder');
            }
        }
        else {
            if (!textOnly) {
                dateElement.querySelector('.search-clear-button').classList.add('hidden');
                displayElement.classList.add('placeholder');
            }
        }

        displayElement.innerText = dateString;
    }

    displayDateTime(date, time, dateTimeElement, textOnly = false) {

        let dateTimeString = this.i18n['i18n_WHEN_ARE_YOU_TRAVELLING'];

        let displayElement = textOnly ? dateTimeElement : dateTimeElement.querySelector('.sw-input-field h2');

        if (date) {

            const UTCStringArr = new Date(date).toUTCString().split(/[\s,]+/);

            dateTimeString = `${UTCStringArr[2]} ${UTCStringArr[1]}`;

            if (time) {
                const timeString = formatTimeToAMPM(time);
                dateTimeString += ` - ${timeString}`;
            }

            if (!textOnly) {
                dateTimeElement.querySelector('.search-clear-button').classList.remove('hidden');
                displayElement.classList.remove('placeholder');
            }
        }
        else {
            if(!textOnly) {
                dateTimeElement.querySelector('.search-clear-button').classList.add('hidden');
                displayElement.classList.add('placeholder');
            }
        }

        displayElement.innerText = dateTimeString;
    }

    displayDateRange(dates, dateElement, textOnly = false) {

        let dateString = this.i18n['i18n_WHEN_ARE_YOU_TRAVELLING'];

        const displayElement = textOnly ? dateElement : dateElement.querySelector('.sw-input-field h2');
        if (dates.from && dates.to) {
            const UTCFromStringArr = new Date(dates.from).toUTCString().split(/[\s,]+/);
            const UTCToStringArr = new Date(dates.to).toUTCString().split(/[\s,]+/);

            let fromString = `${UTCFromStringArr[0]}, ${UTCFromStringArr[2]} ${UTCFromStringArr[1]}`;
            let toString = `${UTCToStringArr[0]}, ${UTCToStringArr[2]} ${UTCToStringArr[1]}`;

            if (this.mobileSize && !this.headerSearchOpen) {
                fromString = `${UTCFromStringArr[2]} ${UTCFromStringArr[1]}`;
                toString = `${UTCToStringArr[2]} ${UTCToStringArr[1]}`;
            }

            dateString = fromString;

            if (fromString !== toString) {
                dateString += ` - ${toString}`;
            }

            if (!textOnly) {
                dateElement.querySelector('.search-clear-button').classList.remove('hidden');
                displayElement.classList.remove('placeholder');
            }
        }
        else {
            if (!textOnly) {
                dateElement.querySelector('.search-clear-button').classList.add('hidden');
                displayElement.classList.add('placeholder');
            }
        }

        displayElement.innerText = dateString;
    }

    storeCarSearchData(){
        const carRentalSearchData = {
            differentDropOff: this.widgetData.modes.carRental.hasDifferentDropOff(),
            dropOffLocation:{
                additionals: this.widgetData.modes.carRental.data.locations.dropoff.additionals,
                code: this.widgetData.modes.carRental.data.locations.dropoff.code,
                displayName: this.widgetData.modes.carRental.data.locations.dropoff.display,
                date: this.widgetData.modes.carRental.data.dateTimes.to.date,
                time: this.widgetData.modes.carRental.data.dateTimes.to.time
            },
            pickupLocation: {
                additionals: this.widgetData.modes.carRental.data.locations.pickup.additionals,
                code: this.widgetData.modes.carRental.data.locations.pickup.code,
                displayName: this.widgetData.modes.carRental.data.locations.pickup.display,
                date: this.widgetData.modes.carRental.data.dateTimes.from.date,
                time: this.widgetData.modes.carRental.data.dateTimes.from.time
            }
        }

        localStorage.setItem('carRentalSearch', JSON.stringify(carRentalSearchData));
    }

}
export default SearchWidget;