import {formatDate, generateDate} from "../utils/dateTimeHelpers";

class DatePicker{

    element;
    i18n = com.tcl.data.i18n;
    currentSelectedDate;
    displayedDate;
    activeDaysOfWeek = [0,1,2,3,4,5,6];
    allowToday = true;
    allowFrom = null;
    useDateArray = false;
    availableDates = [];
    fallbackToSchedule = false;
    strictAvailability = false;
    showButtons = false;

    constructor(element,currentSelectedDate = formatDate(new Date()),activeDaysOfWeek = null,allowToday = true,allowFrom = null,availableDates = null, fallbackToSchedule = false, showButtons = false, strictAvailability = false) {
        this.element = element;
        this.showButtons = showButtons;

        const date = currentSelectedDate ? generateDate(currentSelectedDate) : formatDate(new Date());

        this.currentSelectedDate = generateDate(date);
        this.currentSelectedDate.setHours(0, 0, 0, 0);

        this.displayedDate = generateDate(date);

        this.allowToday = allowToday;

        if (allowFrom !== null) {
            this.allowFrom = generateDate(allowFrom)
        }

        this.fallbackToSchedule = fallbackToSchedule;
        this.strictAvailability = strictAvailability;

        if (activeDaysOfWeek !== null)
            this.activeDaysOfWeek = activeDaysOfWeek;

        if (availableDates !== null) {
            this.useDateArray = true;
            this.availableDates = availableDates;
        }

        this.render();
        return this;
    }

    render() {
        if (!this.showButtons){
            this.element.innerHTML = `            
            <div class="month-container">
                <div class="prev-month"></div>
                <div class="month-year">October 2023</div>
                <div class="next-month"></div>
            </div>
            <div class="day-initials">
                <div class="day-initial">S</div>
                <div class="day-initial">M</div>
                <div class="day-initial">T</div>
                <div class="day-initial">W</div>
                <div class="day-initial">T</div>
                <div class="day-initial">F</div>
                <div class="day-initial">S</div>
            </div>
            <div class="days"></div>
            `
        }
        else{
            this.element.innerHTML = `
            <div class="pane">
                <div class="month-container">
                    <div class="prev-month"></div>
                    <div class="month-year">October 2023</div>
                    <div class="next-month"></div>
                </div>
                <div class="day-initials">
                    <div class="day-initial">S</div>
                    <div class="day-initial">M</div>
                    <div class="day-initial">T</div>
                    <div class="day-initial">W</div>
                    <div class="day-initial">T</div>
                    <div class="day-initial">F</div>
                    <div class="day-initial">S</div>
                </div>
                <div class="days"></div>
            </div>
            <div class="buttons">
                <div class="apply-btn">${this.i18n['i18n_APPLY']}</div>
            </div>   
            `
        }
        const days = this.element.querySelector('.days');
        const monthYear = this.element.querySelector('.month-year');

        if (this.showButtons) {
            const applyBtn = this.element.querySelector('.apply-btn');

            applyBtn.addEventListener('click', () => {
                this.element.dispatchEvent(new CustomEvent('date-selected', {
                    detail: {
                        type: 'apply',
                        value: this.currentSelectedDate
                    }
                }));
            });
        }

        const refreshMonth = (dateToDisplay) => {
            const today = new Date();
            today.setHours(0,0,0,0);

            days.innerHTML = '';
            monthYear.innerHTML = `${dateToDisplay.toLocaleString('default', { month: 'long' })} ${dateToDisplay.getFullYear()}`;
            const month = dateToDisplay.getMonth();
            const year = dateToDisplay.getFullYear();


            const daysInMonth = new Date(year, month+1, 0).getDate();
            const startDay = new Date(year, month, 1).getDay();


            let dayNumber = 1;
            for (let i = 0; i < daysInMonth+startDay;i++) {
                const dayElement = document.createElement('div');
                if (i >= startDay) {
                    dayElement.classList.add('day');
                    let currentDate = new Date(year,month,dayNumber)
                    currentDate.setHours(13,0,0,0);
                    const dayOfWeek = currentDate.getDay();

                    let lastAvailableDate = null;

                    if (this.useDateArray){
                        lastAvailableDate = generateDate(this.availableDates[this.availableDates.length-1]);

                        if(this.availableDates.indexOf(formatDate(currentDate)) === -1 && currentDate <= lastAvailableDate)
                            dayElement.classList.add('disabled');
                        else if (currentDate.toDateString() === this.currentSelectedDate.toDateString())
                            dayElement.classList.add('selected');
                        else if (this.strictAvailability && this.availableDates.indexOf(formatDate(currentDate)) === -1)
                            dayElement.classList.add('disabled');
                    }

                    const fallbackToActiveDays =  this.fallbackToSchedule && this.availableDates.length > 0 && lastAvailableDate && currentDate > lastAvailableDate;

                    if (!this.useDateArray || fallbackToActiveDays){
                        if (!this.allowToday && formatDate(currentDate) === formatDate(today)) {
                            dayElement.classList.add('disabled');
                        }
                        else if (currentDate < today || !this.activeDaysOfWeek.includes(dayOfWeek)) {
                            dayElement.classList.add('disabled');
                        }
                        else if (this.allowFrom !== null && currentDate < this.allowFrom) {
                            dayElement.classList.add('disabled');
                        }
                        else if (formatDate(currentDate) === formatDate(this.currentSelectedDate)){
                            dayElement.classList.add('selected');
                        }
                    }


                    dayElement.innerHTML = dayNumber.toString();
                    dayNumber++;

                    dayElement.addEventListener('click',()=>{
                        if(dayElement.classList.contains('disabled'))
                            return;
                        this.currentSelectedDate = currentDate;
                        refreshMonth(this.currentSelectedDate);
                        this.element.dispatchEvent(new CustomEvent('date-selected', {
                            detail: {
                                type: 'date',
                                value: currentDate
                            }
                        }));
                    });
                }
                days.appendChild(dayElement);
            }
        };

        refreshMonth(this.displayedDate);

        const prevMonth = this.element.querySelector('.prev-month');
        const nextMonth = this.element.querySelector('.next-month');
        prevMonth.addEventListener('click',()=>{
            this.displayedDate.setMonth(this.displayedDate.getMonth()-1);
            if (this.useDateArray)
                this.element.dispatchEvent(new CustomEvent('month-changed', {detail:{direction:-1}}));
            refreshMonth(this.displayedDate);
        }
        );
        nextMonth.addEventListener('click',()=>{
            this.displayedDate.setMonth(this.displayedDate.getMonth()+1);
            if (this.useDateArray)
                this.element.dispatchEvent(new CustomEvent('month-changed', {detail:{direction:1}}));
            refreshMonth(this.displayedDate);
        }
        );

    }

    updateSettings(dateToSelect = formatDate(new Date()),activeDaysOfWeek = [0,1,2,3,4,5,6], allowToday = true,allowFrom = null){

        const date = dateToSelect ? generateDate(dateToSelect) : formatDate(new Date());

        this.currentSelectedDate = generateDate(date);
        this.currentSelectedDate.setHours(0, 0, 0, 0);

        this.displayedDate = generateDate(date);

        this.activeDaysOfWeek = activeDaysOfWeek;
        this.allowToday = allowToday;

        if (allowFrom !== null) {
            this.allowFrom = generateDate(allowFrom);
            this.allowFrom.setHours(12, 0, 0, 0);
        }
        else {
            this.allowFrom = null;
        }
        this.render();
    }

    setDate(dateToSelect){

        const date = dateToSelect ? generateDate(dateToSelect) : formatDate(new Date());

        this.currentSelectedDate = generateDate(date);
        this.currentSelectedDate.setHours(0, 0, 0, 0);

        this.displayedDate = generateDate(date);

        this.render();
    }


}
export default DatePicker;