import ScrollableContainer from "./components/scrollableContainer";
import Header from "./components/header";
import Footer from "./components/footer";
import LazyLoader from "./components/lazyLoader";
import PageBlocks from "./components/pageBlocks";
import SearchWidget from "./components/searchWidget";
import FavouritesButton from "./components/favouritesButton";

new Header(document.querySelector('header'));

new PageBlocks();

new LazyLoader('.lazy-load');

function initHeroImages() {
    const images = com.tcl.data.heroImages
    const preload = new Image();
    const image1 = document.getElementById('image1');
    const image2 = document.getElementById('image2');
    const count = images.length;
    let index = 0 // Math.floor(Math.random() * count) // BEGIN WITH RANDOM IMAGE, COULD CAUSE JITTER ON PAGE LOAD
    let show = false;

    // Initialise
    setBackgroundImage(image1);
    setBackgroundImage(image2);
        setInterval(function() {
            preload.src = images[index];
            image1.style.opacity = show ? '1' : '0';

            setTimeout(function() {
                setBackgroundImage(show === true ? image1 : image2);
            }, 2000);
            show = !show;
        }, 5000);

        function setBackgroundImage(image) {
            image.style.backgroundImage =`url(${images[index]})`;
            

            index++;
            if (index >= count) {
                index = 0;
            }
        }
}
//initHeroImages();

document.addEventListener('click',(e)=> {
    const tgt = e.target;
    if (tgt.classList.contains('card-favourite')) {
        e.preventDefault();

        const saved = e.target.classList.contains('saved');
        const element = e.target;
        const cardElement = e.target.closest('.product-card');
        const id = cardElement.dataset.id;
        const title = cardElement.dataset.title;

        if (saved) {
            let favourites = localStorage.getItem(`favourites`)?.split('|').filter(Boolean);
            if (favourites === null || favourites === undefined)
                return;
            favourites.splice(favourites.indexOf(id), 1);
            localStorage.setItem(`favourites`, favourites.join('|'));
        } else {
            let favourites = localStorage.getItem(`favourites`)?.split('|').filter(Boolean)
            if (favourites === null || favourites === undefined)
                favourites = [];
            favourites.push(id);
            localStorage.setItem(`favourites`, favourites.join('|'));
        }

        FavouritesButton.updateFavouritesCount();
        FavouritesButton.showNotification(title, !saved);

        if (!saved) {
            element.classList.add('saved');
        } else {
            if (element.parentElement.classList.contains('favourite-card')) {
                element.parentElement.remove();
            }

            element.classList.remove('saved');
        }
    }

    if (tgt.closest('.event-category-card ')) {

        let widgetData = localStorage.getItem('searchWidget');
        if (widgetData) {
            widgetData = JSON.parse(widgetData);
            widgetData.events = {
                term: null,
                dates: {
                    from: null,
                    to: null
                }
            };
            localStorage.setItem('searchWidget', JSON.stringify(widgetData));
        }
    }
});

new SearchWidget(document.getElementById('search-widget'));

// Add scrollable containers and nav elements if applicable
const scrollableContainer = document.getElementById('review-scroll');
const navTarget = scrollableContainer.getAttribute('data-nav');
const navElement = document.getElementById(`${navTarget}`);
new ScrollableContainer(scrollableContainer, navElement);


new Footer(document.querySelector('footer'));
