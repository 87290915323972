import {getLoginURL} from "../services/login";
import {getCookie, setCookie} from "../utils/cookieHelpers";

class LoginWindow{

    i18n = com.tcl.data.i18n;
    element;

    showing = false;

    useIFrame = false;

    constructor(useIframe = false, error = false) {
        this.useIFrame = useIframe;
        this.showWindow(error);

        return this;
    }

    showWindow(error = false){

        if(this.showing === true) {
            return;
        }

        this.showing = true;

        if (this.useIFrame) {
            this.renderInIFrame();
        }
        else{
            this.renderInExternalTab(error);
        }
    }

    renderInIFrame(){
        getLoginURL().then(response => {

            this.element = document.createElement('div');
            this.element.id = 'login-window';
            this.element.classList.add('overlay-login-background');
            this.element.innerHTML = `
                <div class="overlay-login-window">
                    <div class="overlay-login-content loading">
                        <div class="overlay-login-loader"><div></div></div></div>
                        <div class="overlay-login-close-button"></div>
                    </div>
                </div>
            `;

            this.element.querySelector('.overlay-login-close-button').addEventListener('click', () => {

                this.element.remove();
                this.showing = false;
            });

            const modalContent = this.element.querySelector('.overlay-login-content');

            if (response.status === 'error') {
                modalContent.classList.remove('loading');
                modalContent.classList.add('error');

                const errorHTML = `
                    <div class="login-error">
                        <div>${this.i18n['i18n_LOGIN_ERROR']}</div>
                        <div>${this.i18n['i18n_TRY_AGAIN_LATER']}</div>
                    </div>  
                `;

                modalContent.insertAdjacentHTML('beforeend', errorHTML);
                document.body.appendChild(this.element);
                return;
            }

            modalContent.insertAdjacentHTML('beforeend', `
                <iframe class="overlay-login-iframe" src="${response.url}"><iframe>
            `);

            const iframe = modalContent.querySelector('iframe');
            iframe.addEventListener('load', () => {
                modalContent.classList.remove('loading');
            });

            document.body.appendChild(this.element);

        });

        window.addEventListener('message', (event) => {
            if (event.data.bgWhite === true) {
                this.element.querySelector('.overlay-login-content').classList.add('success');
                return;
            }

            if (event.data.loginSuccessful === true) {
                let cookieData = event.data.customer;
                cookieData.points = event.data.points;

                setCookie('customer', cookieData);
                document.dispatchEvent(new CustomEvent('login-event', {detail: {eventType: 'login'}}));

                this.element.remove();
                this.showing = false;
            }
        });


    }

    renderInExternalTab(error = false) {

        let loginPoll = null;

        this.element = document.createElement('div');
        this.element.id = 'login-window';
        this.element.classList.add('overlay-login-background');

        this.element.innerHTML = `
            <div class="overlay-login-window">
                <div class="overlay-login-content loading">
                    <div class="overlay-login-external">${this.i18n['i18n_LOGGING_IN_ON_EXTERNAL_PAGE']}</div>
                    <div class="overlay-login-waiting"></div>
                    <div class="overlay-login-loader">${this.i18n['i18n_WAITING_FOR_LOGIN']}<div></div></div>
                    <div class="overlay-login-close-button"></div>
                </div>
            </div>
        `;

        this.element.querySelector('.overlay-login-close-button').addEventListener('click', () => {

            this.element.remove();
            this.showing = false;
            if (loginPoll !== null) {
                clearInterval(loginPoll);
            }
        });

        const modalContent = this.element.querySelector('.overlay-login-content');

        if (error) {
            modalContent.classList.remove('loading');
            modalContent.classList.add('error');

            const errorHTML = `
                <div class="login-error">
                    <div>${this.i18n['i18n_LOGIN_ERROR']}</div>
                    <div>${this.i18n['i18n_TRY_AGAIN_LATER']}</div>
                </div>  
            `;

            modalContent.insertAdjacentHTML('beforeend', errorHTML);
            document.body.appendChild(this.element);
            return;
        }

        loginPoll = setInterval(() => {
            const customerCookie = getCookie('customer');
            const tokenCookie = getCookie('token');

            if (customerCookie !== null && tokenCookie !== null) {
                clearInterval(loginPoll);
                document.dispatchEvent(new CustomEvent('login-event', {detail: {eventType: 'login'}}));
                this.element.remove();
                this.showing = false;
            }

        }, 1000);

        document.body.appendChild(this.element);

    }
}

export default LoginWindow;