const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export function getNextActiveDay(activeDaysOfWeek,previousDate= null,allowToday=true,initialDate=null,useTomorrow=false){
    // Get the days of the week that the product is active on
    let todaySelected = false;
    const today = new Date();
    const tomorrow = new Date(new Date().setDate(today.getDate() + 1));

    let startDay = today;

    if (useTomorrow)
        startDay = tomorrow;

    if (initialDate !== null) {
        startDay = generateDate(initialDate);
    }

    // If the previous search date is in the future (not today), and the product is active on that day, use that date
    let usePreviousDate = false;
    if (previousDate !== null){

        const prevDate = generateDate(previousDate);

        if (prevDate > today && prevDate > generateDate(initialDate) && activeDaysOfWeek.includes(prevDate.getDay())) {
            startDay = prevDate;
            usePreviousDate = true;
        }
    }

    // Find the next active day of the week and the date of that day
    const dayOfWeek = startDay.getDay();
    let nextDay = null;
    let dateOfNextDay = null;
    for (let i = 0; i < 7; i++) {
        if (i === 0 && !allowToday && startDay === today) {
            todaySelected = false;
            continue;
        }
        const day = (dayOfWeek + i) % 7;
        if (activeDaysOfWeek.includes(day)) {
            nextDay = day;
            dateOfNextDay = formatDate(new Date(new Date(startDay).setDate(startDay.getDate() + i)));
            todaySelected = !usePreviousDate && i === 0;
            break;
        }
    }
    return [nextDay,dateOfNextDay,todaySelected];
}

export function formatTimeToAMPM(time){
    if (time === null || time === undefined || time === '' || time === '--:--')
        return '--:--';
    let timeComponents = time.split(':');
    timeComponents[1] += (timeComponents[0] < 12) ? 'AM' : 'PM';
    timeComponents[0] = parseInt(timeComponents[0])%12 || 12;
    timeComponents[0] = timeComponents[0] < 10 ? `0${timeComponents[0]}` : timeComponents[0];

    return timeComponents.join(':');
}

export function formatDate(date) {
    if (!date)
        return null;

    if (date.toString().match(/\d{4}-\d{2}-\d{2}/))
        return date;

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (day < 10)
        day = `0${day}`;

    if (month < 10)
        month = `0${month}`;

    return `${year}-${month}-${day}`;
}

export function generateDate(date){

    if(date === null) {
        const generatedDate = new Date(null);
        return new Date(generatedDate.getFullYear(), generatedDate.getMonth(), generatedDate.getDate());
    }

    if (date === undefined) {
        const generatedDate = new Date();
        return new Date(generatedDate.getFullYear(), generatedDate.getMonth(), generatedDate.getDate());
    }

    if (date.toString().match(/\d{4}-\d{2}-\d{2}/)) {
        const currentVals = date.split('-');
        return new Date(currentVals[0], currentVals[1] - 1, currentVals[2]);
    }
    else {
        const generatedDate = new Date(date);
        return new Date(generatedDate.getFullYear(), generatedDate.getMonth(), generatedDate.getDate());
    }
}



export function timeToMinutes(time) {
    const parts = time.replace('.',':').split(':');
    if (parts[1].includes('pm')){
        parts[0] = parseInt(parts[0]) + 12;
        parts[1] = parts[1].replace('pm', '');
    }
    else if (parts[1].includes('am')){
        parts[1] = parts[1].replace('am', '');
    }
    return parseInt(parts[0]) * 60 + parseInt(parts[1]);
}

export function getClosestTime(scheduledTimes,todaySelected=false,previousTime = null){
    let timeAsMinutes = 0;
    if (previousTime) {
        timeAsMinutes = timeToMinutes(previousTime);
    }

    const now = new Date();
    const hour = now.getHours();
    const minute = now.getMinutes();
    const nowAsMinutes = timeToMinutes(`${hour}:${minute}`);

    if (todaySelected && timeAsMinutes < nowAsMinutes) {
        timeAsMinutes = nowAsMinutes;
    }

    let closestTime = null;
    let closestTimeDifference = null;

    for (let scheduledTime of scheduledTimes) {
        const scheduledTimeAsMinutes = timeToMinutes(scheduledTime);
        if (todaySelected && scheduledTimeAsMinutes < timeAsMinutes)
            continue;

        const difference = Math.abs(timeAsMinutes - scheduledTimeAsMinutes);
        if (closestTimeDifference === null || difference < closestTimeDifference) {
            closestTime = scheduledTime;
            closestTimeDifference = difference;
        }
    }
    if (closestTime === null)
        closestTime = scheduledTimes[scheduledTimes.length - 1];
    return closestTime;
}
