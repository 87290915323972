
class CustomSelect{

    select;
    customSelect;
    keyboardPosition = -1;

    active;
    options;
    transformText = true;

    constructor(element, transformText = true) {
        if (element === null || element === undefined )
            return;
        if (!(element instanceof HTMLSelectElement)) {
            throw new Error('Element must be an HTMLSelectElement');
        }

        this.transformText = transformText;
        this.select = element;
        this.select.classList.add('hidden');
        this.render();
        return this;
    }

    updateSelect(index = null) {
        let newIndex = index;
        if (newIndex === null)
            newIndex = this.select.selectedIndex;

        let text = this.select.options[newIndex].innerText;
        if (this.transformText === true){
            text = text.toUpperCase();
        }

        this.active.querySelector('.label').innerHTML = text;
        this.options.querySelector('.selected').classList.remove('selected');
        this.options.children[newIndex].classList.add('selected');
    }

    updateSelectOptions(options){

        this.select.innerHTML = '';
        for (let option of options) {
            const optionElement = document.createElement('option');
            optionElement.value = option.value;
            optionElement.innerText = option.label;

            this.select.appendChild(optionElement);
        }
        this.render();
    }

    setOption(option) {
        this.select.value = option;
        this.updateSelect();
    }

    render() {
        if (this.customSelect === undefined) {
            this.customSelect = document.createElement('div');
        }
        const customSelect = this.customSelect;
        const hasOptions = this.select.options.length > 0;
        customSelect.classList.add('custom-select-container');

        if(this.select.selectedIndex === -1 && hasOptions){
            this.select.selectedIndex = 0;
        }
        let flag = null;
        if (hasOptions) {
            flag = this.select.options[this.select.selectedIndex].dataset.country;
            if (flag !== undefined && flag !== null)
                flag = `<div class="flag ${flag}"></div>`;
            else
                flag = '';
        }
        let labelText = '';
        if (hasOptions) {
            labelText = this.select.options[this.select.selectedIndex].innerText;
            if (this.transformText === true){
                labelText = labelText.toUpperCase();
            }
        }
        customSelect.innerHTML = `
            <div class="active-option">
                ${flag}
                <div class="label">${labelText}</div>
                <div class="caret"></div>
            </div>
            <div class="options hidden">
        `;
        customSelect.tabIndex = 0;

        this.active = customSelect.querySelector('.active-option');
        const active = this.active;
        this.options = customSelect.querySelector('.options');
        const options = this.options;
        const caret = customSelect.querySelector('.caret');

        for (let i = 0; i < this.select.options.length; i++) {
            const option = document.createElement('div');

            let labelText = this.select.options[i].innerText;
            if (this.transformText === true){
                labelText = labelText.toUpperCase();
            }

            let flag = this.select.options[i].dataset.country;
            if (flag !== undefined)
                flag = `<div class="flag ${flag}"></div>`;
            else
                flag = '';

            option.classList.add('option');
            option.innerHTML = `
                ${flag}
                ${labelText}
            `;

            option.addEventListener('click',()=>{
                if (active.querySelector('.flag') !== null) {
                    active.querySelector('.flag').remove();
                    active.insertAdjacentHTML('afterbegin', option.querySelector('.flag').outerHTML);
                }
                active.querySelector('.label').innerHTML = labelText;
                this.select.selectedIndex = i;
                this.select.dispatchEvent(new Event('change'));

                options.querySelector('.selected').classList.remove('selected');
                option.classList.add('selected');
                active.classList.remove('active');
                hideOptions(this);
            });
            options.appendChild(option);
        }
        if (options.children.length > 0)
            options.children[this.select.selectedIndex].classList.add('selected');

        active.addEventListener('click',()=>{
            if (options.classList.contains('hidden')) {
                options.classList.remove('hidden');
                active.classList.add('active');
                caret.classList.add('active');
                this.keyboardPosition = -1;
                const selected = options.querySelector('.selected');
                options.scrollTop = selected.offsetTop - selected.offsetHeight*2;

                removeHoveredStyle();
            }
            else {
                active.classList.remove('active');

                hideOptions(this);
            }
        });

        customSelect.addEventListener('focusout',(e)=>{
            if (e.relatedTarget === null || !customSelect.contains(e.relatedTarget)) {
                active.classList.remove('active');
                hideOptions(this);
            }
        });

        customSelect.addEventListener('mouseover',(e)=>{
            if (e.target.classList.contains('option')) {
                removeHoveredStyle();
                e.target.classList.add('hover');
            }
        });

        customSelect.addEventListener('keydown',(e)=>{
            switch (e.key) {
                case 'Escape':
                    hideOptions(this);
                    break;

                case 'Enter':
                    const hovered = options.querySelector('.hover');
                    const selected = options.querySelector('.selected');
                    if (hovered === selected) {
                        return;
                    }

                    if (active.querySelector('.flag') !== null) {
                        active.querySelector('.flag').remove();
                        active.insertAdjacentHTML('afterbegin', option.querySelector('.flag').outerHTML);
                    }

                    active.querySelector('.label').innerHTML = hovered.innerHTML;
                    hovered.classList.add('selected');
                    selected.classList.remove('selected');
                    this.select.selectedIndex = [...options.children].indexOf(hovered);
                    this.select.dispatchEvent(new Event('change'));
                    hideOptions(this);
                    break;

                case 'ArrowDown':
                    if (options.classList.contains('hidden')) {
                        options.classList.remove('hidden');
                        active.classList.add('active');
                        caret.classList.add('active');
                        this.keyboardPosition = -1;
                        removeHoveredStyle();
                        return;
                    }

                    this.keyboardPosition++;
                    if (this.keyboardPosition >= options.children.length) {
                        this.keyboardPosition = 0;
                    }

                    removeHoveredStyle();
                    options.children[this.keyboardPosition].classList.add('hover');
                    break;

                case 'ArrowUp':
                    this.keyboardPosition--;
                    if (this.keyboardPosition < 0) {
                        removeHoveredStyle();
                        options.classList.add('hidden');
                        active.classList.remove('active');
                        customSelect.focus();
                        return;
                    }

                    removeHoveredStyle();
                    options.children[this.keyboardPosition].classList.add('hover');
                    break;
            }
        });

        function hideOptions(classRef) {
            options.classList.add('hidden');
            classRef.keyboardPosition = -1;
            removeHoveredStyle();
            caret.classList.remove('active');
        }

        function removeHoveredStyle(){
            const hoveredOptions = options.querySelectorAll('.hover')
            if (hoveredOptions.length > 0) {
                for (let option of hoveredOptions) {
                    option.classList.remove('hover');
                }
            }
        }
        this.select.insertAdjacentElement('afterend',customSelect);
    }
}
export default CustomSelect;
