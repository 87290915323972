import CustomSelect from "./customSelect";

class Footer{
    constructor(element,useCurrencySelect = true){

        this.element = element;
        if (useCurrencySelect)
            this.initialiseCurrencySelect();
        else {
            this.element.querySelector('#currency-select').remove();
            this.element.querySelector('#currency-title').remove();
        }
    }

    initialiseCurrencySelect() {

        const currencySelect = document.getElementById('currency-select');
        let customSelect =null;
        if (currencySelect)
            customSelect = new CustomSelect(currencySelect);

        const currency = document.cookie.split(';').find(item => item.includes('currency='));
        if (currency) {
            currencySelect.value = currency.split('=')[1];
            if (customSelect)
                customSelect.updateSelect();
        }

        currencySelect.addEventListener('change',()=> {
            document.cookie = `currency=${currencySelect.value};path=/`;
            location.reload();
        });
    }
}
export default Footer;