import RequestLimiter from '../utils/requestLimiter';
import {destinationPropertiesSearch, destinationSearch} from '../services/search';
import Icons from "../utils/icons";
import {searchOffices} from "../services/carHire";

class DestinationSearch {
    i18n = com.tcl.data.i18n;

    // ALL ICONS NEED TO BE CHECKED AND SOURCED FROM CORRECT LOCATIONS
    searchIcon = Icons.searchIcon;
    nearMeIcon = Icons.nearMeIcon;
    locationIcon = Icons.locationIcon;
    propertyIcon = Icons.propertyIcon;
    activityIcon = Icons.activityIcon;

    element;
    searchElement;
    termInput;
    clearButton;
    requestLimiter;
    searchResults;
    searchResultsList
    maxTermLength;
    initialTerm;

    defaultState = {
        inputValue: '',
        searchTerm: '',
        start: 0,
        end: 0,
        validationPattern: RegExp(/^[\p{L}\p{N}\-\s\.,'/&]*$/u),
        keyboardPosition: -1
    }

    currentState = Object.assign({},this.defaultState);
    searchType = 'destination';
    separateCategories;

    constructor(element, searchElement, initialTerm = '', maxTermLength = 32, type = 'destination', separateCategories = false) {
        this.element = element;
        this.separateCategories = separateCategories;
        this.searchElement = searchElement;
        this.searchType = type;
        this.requestLimiter = new RequestLimiter();
        this.maxTermLength = maxTermLength;
        this.initialTerm = initialTerm;
        this.render()
        return this;
    }

    render() {  //Render the search widget
        // Create main search element consisting of the input and results holder

        this.termInput = this.searchElement.querySelector('input');
        this.searchResults = this.searchElement.querySelector('.sw-dropdown');
        this.searchResultsList = this.searchElement.querySelector('.sw-dd-content');

        const clearButton = this.searchElement.querySelector('.search-clear-button');
        this.clearButton = clearButton;

        if (this.searchType === 'destinationProperties') {
            this.addSearchNearby();
            this.addRecentSearches();
        }

        // If there is an initial term, set the input value, current state and show the clear button
        if (this.initialTerm !== null && this.initialTerm !== undefined && this.initialTerm !== '') {
            this.termInput.value = this.initialTerm;
            this.currentState.start = this.initialTerm.length;
            this.currentState.end = this.initialTerm.length;
            this.currentState.searchTerm = this.initialTerm;
            clearButton.classList.remove('hidden');
        }
        this.currentState.inputValue = this.termInput.value;

        // Clear button click and keydown handlers, clear the input and search results
        clearButton.tabIndex = 0;
        const clearInput = () =>{
            this.termInput.value = '';
            this.searchResultsList.innerHTML = '';
            this.resetState()
            this.removeHoveredStyle();
            clearButton.classList.add('hidden');
            this.termInput.focus();
            this.searchResults.classList.add('hidden');
            let targetElement = this.element;

            if (this.searchType === 'carHireOffice') {
                targetElement = this.searchElement;
            }

            targetElement.dispatchEvent(new CustomEvent('destination-selected',{
                    detail: {
                        value: {
                            code: null,
                            url: null,
                            display: null,
                            secondary: ''
                        },
                        type:'reset'
                    }
                })
            );
        }

        clearButton.addEventListener('click',(e)=>{
            e.preventDefault();
            clearInput();
        });

        clearButton.addEventListener('keydown',(e)=>{
            if (e.key === 'Enter' || e.key === 'Return') {
                clearInput();
            }
        });

        // Term input exit focus handler
        this.termInput.addEventListener('focusout', (e) => {
            // If the user is clicking on a search result, clear the input and navigate to the result
            if (e.relatedTarget !== null) {
                if (e.relatedTarget.classList.contains('clear-button')) {
                    this.termInput.focus();
                }
                else if(e.relatedTarget.classList.contains('search-result')) {
                    e.relatedTarget.dispatchEvent(new Event('click'));
                }
                else{
                    this.hideSearchResults();
                }
            }
            // Otherwise, hide the search results by adding 'hidden' css class
            else {
                this.hideSearchResults();
            }

        });

        // Term input focus handler, remove 'hidden' css class to show results
        this.termInput.addEventListener('focusin', () => {

            if (this.searchType === 'destinationProperties' && this.searchResultsList.children.length === 0){
                this.addSearchNearby();
                this.addRecentSearches();
            }

            if (this.searchResultsList.children.length > 0) {
                this.searchResults.classList.remove('hidden');
            }
        });

        // Term input keyup handler, check if the term is long enough to search and if so, search
        this.termInput.addEventListener('input', () => {

            // Ensure only unicode letters and spaces are entered
            if (this.currentState.validationPattern.test(this.termInput.value)) {
                this.currentState.inputValue  = this.termInput.value;
            }
            else {
                this.termInput.value = this.currentState.inputValue;
                this.termInput.setSelectionRange(this.currentState.start,this.currentState.end);
            }

            // If the input is empty, hide the clear button
            if (this.termInput.value === '') {
                clearButton.classList.add('hidden');
            }
            else {
                clearButton.classList.remove('hidden');
            }

            // Get the search term from the input
            const inputTerm = this.termInput.value;
            let searchTerm = inputTerm.trim();

            // Limit the length of the search term to the max input length
            if (searchTerm.length > this.maxTermLength) {
                searchTerm = searchTerm.substring(0, this.maxTermLength);
            }

            if (this.searchType === 'carHireOffice') {
                this.searchElement.dispatchEvent(new CustomEvent('input-changed', {
                        detail: {
                            value: {
                                code: null,
                                url: null,
                                display: null,
                                secondary: ''
                            }
                        }
                    })
                );
            }
            else {
                this.element.dispatchEvent(new CustomEvent('input-changed', {
                        detail: {
                            value: {
                                code: null,
                                url: null,
                                display: null,
                                secondary: ''
                            }
                        }
                    })
                );
            }

            // If the new search term is the same as the stored search term, don't search again
            if (searchTerm === this.currentState.searchTerm) {
                return;
            }
            else{
                this.currentState.searchTerm = searchTerm;
            }

            let minTermLength = 2;

            if(this.searchType === 'carHireOffice'){
                minTermLength = 3;
            }

            // Check if the search term is long enough to search
            if (searchTerm.length < minTermLength) {
                this.searchResultsList.innerHTML = '';
                if (this.searchType === 'destinationProperties') {
                    this.addSearchNearby()
                    this.addRecentSearches();
                }
                return;
            }
            this.removeRecentSearches();
            // If the search term is long enough, search and update the results
            // Use request limiter to manage calls to the server, only the last call will be executed
            this.requestLimiter.setNext(async () => {
                let results = [];

                if (this.searchType === 'destination'){
                    results = await destinationSearch(searchTerm);
                }
                else if (this.searchType === 'destinationProperties') {
                    results = await destinationPropertiesSearch(searchTerm);
                }
                else if (this.searchType === 'carHireOffice') {
                    results = await searchOffices(searchTerm);
                }

                this.updateSearchResults(results);
            });

        });

        // Input keydown handler, handle keyboard navigation,searching,clearing and selecting results
        this.termInput.addEventListener('keydown',(e)=>{
            const resultsList = this.searchResultsList
            this.currentState.start = this.termInput.selectionStart;
            this.currentState.end = this.termInput.selectionEnd;

            // Recursive function to select the next or previous result
            const selectResult = (direction,results) => {
                // If the current item is not a search result, move to the next result
                this.currentState.keyboardPosition += direction;
                if (this.currentState.keyboardPosition >= results.length) {
                    this.currentState.keyboardPosition = 0;
                }
                if (this.currentState.keyboardPosition < 0) {
                    this.currentState.keyboardPosition = -1;
                    this.removeHoveredStyle();
                    return;
                }
                const currentItem = results[this.currentState.keyboardPosition];
                if (currentItem.classList.contains('search-result') && !currentItem.classList.contains('hidden')){
                    this.removeHoveredStyle();
                    currentItem.classList.add('hover');
                    this.searchResults.scrollTop = currentItem.offsetTop-300;
                }
                else {
                    selectResult(direction, results);
                }
            }

            // If the user presses the down arrow, select the next result. Wrapping around to the first result after the last result
            if (e.key === 'ArrowDown') {
                if (resultsList === null || resultsList === undefined) {
                    return;
                }
                const results = resultsList.children;

                if (results.length === 0) {
                    return;
                }

                if (results[0].classList.contains('nearby')) {
                    return;
                }

                selectResult(1,results);
                return;
            }

            // If the user presses the up arrow, select the previous result. Clearing the selection after the first result
            if (e.key === 'ArrowUp') {
                if (resultsList === null || resultsList === undefined || this.currentState.keyboardPosition === -1) {
                    return;
                }
                const results = resultsList.children;
                if (results.length === 0) {
                    return;
                }
                selectResult(-1,results);
                return;
            }

            // If the user presses escape, clear the input and hide the search results
            if (e.key === 'Escape') {
                clearInput();
                return;
            }

            // If the user presses enter and three characters or more are entered, navigate to the search results or selected product page
            if (e.key === 'Enter' || e.key === 'Return') {
                if (this.currentState.keyboardPosition !== -1) {
                    const results = resultsList.children;
                    if (results.length === 0) {
                        return;
                    }
                    const result = results[this.currentState.keyboardPosition];
                    result.dispatchEvent(new Event('click'));
                    return;
                }
                else{
                    e.preventDefault();
                    this.termInput.dispatchEvent(new KeyboardEvent('keydown',{'key': 'ArrowDown'}));
                }
            }
        });

        // If the user hover over a search result, update the keyboard position for future keyboard navigation
        this.searchResults.addEventListener('mouseover',(e)=>{
            const hoveredResult = e.target;
            if (hoveredResult.classList.contains('search-result')) {
                this.currentState.keyboardPosition = [...hoveredResult.parentNode.children].indexOf(hoveredResult)

                this.removeHoveredStyle();
                hoveredResult.classList.add('hover');
            }
        });

        // Add the search element to the container and start the request limiter
        this.requestLimiter.start();
    }

    hideSearchResults(){
        //Hide the search results
        if (this.searchResults.classList.contains('hidden'))
            return;
        this.searchResults.scrollTop = -100;
        this.searchResults.classList.add('hidden');

        this.currentState.keyboardPosition = -1
        this.removeHoveredStyle();
    }

    storeSearchTerm(){
        //Store the search term in session storage
        let recentSearches = localStorage.getItem('recentDestinationSearches');

        if (recentSearches === null || recentSearches === undefined) {
            recentSearches = [];
        }
        else {
            recentSearches = recentSearches.split('|');
        }

        let searchTerm = this.currentState.searchTerm;
        searchTerm = searchTerm.charAt(0).toUpperCase() + searchTerm.slice(1);

        if (recentSearches.includes(searchTerm)) {
            return;
        }
        recentSearches.unshift(searchTerm);

        if (recentSearches.length > 3) {
            recentSearches.splice(3,recentSearches.length-3);
        }
        localStorage.setItem('recentDestinationSearches', recentSearches.join('|')+'|');
    }

    addSearchNearby() {

        //Add the search nearby button to the search results
        const searchNearby = document.createElement('li');
        searchNearby.classList.add('search-result');
        searchNearby.classList.add('nearby');
        searchNearby.tabIndex = -1;
        searchNearby.innerHTML = `
            ${this.nearMeIcon}
            <span class='search-result-text'>${this.i18n["i18n_NEAR_ME"]}</span>
        `
        searchNearby.addEventListener('click',()=>{
            this.currentState.searchTerm = '';
            this.clearButton.classList.add('hidden');
            navigator.geolocation.getCurrentPosition((position)=>{
                this.requestLimiter.setNext(async () => {
                    const results = await destinationPropertiesSearch(null,position.coords.latitude,position.coords.longitude);
                    this.updateSearchResults(results);
                });
            });
        });

        this.searchResultsList.append(searchNearby);
    }

    addRecentSearches() {
        //Add the recent searches to the search results
        let recentSearches = localStorage.getItem('recentSearches');
        if (recentSearches === null || recentSearches === undefined || recentSearches.length === 0) {
            return;
        }
        if (!recentSearches.includes('|')) {
            recentSearches = recentSearches.replaceAll(',', '|');
            localStorage.setItem('recentSearches',recentSearches);
        }

        for (const recentSearch of recentSearches.split('|').filter(Boolean)) {
            const li = document.createElement('li');
            li.classList.add('search-result');
            li.classList.add('recent');
            li.tabIndex = -1;
            li.innerHTML = `
                <div class='search-result-text'>${recentSearch}</div><div class="search-clear-button">
                    <svg width="16" height="16" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 4.20857L13.7914 3L9 7.79143L4.20857 3L3 4.20857L7.79143 9L3 13.7914L4.20857 15L9 10.2086L13.7914 15L15 13.7914L10.2086 9L15 4.20857Z"></path>
                    </svg>
                </div>
            `

            li.addEventListener('click',()=> {
                this.currentState.searchTerm = recentSearch;
                this.termInput.value = recentSearch;
                this.clearButton.classList.add('hidden');
                this.hideSearchResults();

                try{
                    window.open(`/search?term=${encodeURIComponent(recentSearch)}`, '_self');
                }
                catch (e) {
                    window.location = `/search?term=${encodeURIComponent(recentSearch)}`;
                }
            });

            const clearButton = li.querySelector('.search-clear-button');
            clearButton.tabIndex = -1;
            clearButton.addEventListener('click',()=>{
                recentSearches = localStorage.getItem('recentSearches');
                if (recentSearches === null || recentSearches === undefined || recentSearches.length === 0) {
                    return;
                }
                recentSearches = recentSearches.split('|').filter(Boolean);
                recentSearches.splice(recentSearches.indexOf(recentSearch),1);
                localStorage.setItem('recentSearches',recentSearches.join('|')+'|');
                li.remove();
            });

            this.searchResultsList.append(li);
        }
    }

    removeRecentSearches(){
        //Hide the recent searches
        const recentSearches = this.searchResultsList.querySelectorAll('.recent');
        if (recentSearches.length > 0) {
            for (let recentSearch of recentSearches) {
                recentSearch.remove();
            }
        }
    }

    removeHoveredStyle(){
        if (this.searchResultsList === null || this.searchResultsList === undefined) {
            return;
        }
        const hoveredResults = this.searchResultsList.querySelectorAll('.hover')
        if (hoveredResults.length > 0) {
            for (let result of hoveredResults) {
                result.classList.remove('hover');
            }
        }
    }

    updateSearchResults(results) {
        //Update the displayed search results
        // Clear the current search results and create a new list
        this.searchResultsList.remove();
        const resultsListDiv = document.createElement('div');
        resultsListDiv.classList.add('sw-dd-content');

        // If there are no results, display no results message as only list item
        if (results.length === 0) {
            let resultDiv = document.createElement('div');
            resultDiv.classList.add('search-result');
            resultDiv.innerHTML = "No results found";
            resultsListDiv.append(resultDiv);
        }
        else {

            // collect results into sections and add a data tag to each result to identify its type
            let prevType = null
            for (const result of results) {

                if(!result.type)
                    continue;

                let resultDiv = document.createElement('div');
                resultDiv.classList.add('search-result');
                resultDiv.tabIndex = -1;

                if (this.separateCategories) {
                    switch (result.type?.toLowerCase()) {
                        case 'location':
                            resultDiv.dataset.category = 'destinations';
                            break;
                        case 'property':
                            resultDiv.dataset.category = 'hotels';
                            break;
                        case 'airport':
                            resultDiv.dataset.category = 'airports';
                            break;
                        case 'hotel':
                            resultDiv.dataset.category = 'hotels';
                            break;
                        case 'city':
                            resultDiv.dataset.category = 'cities';
                            break;
                        case 'train':
                            resultDiv.dataset.category = 'stations';
                            break;
                    }
                }

                resultDiv.dataset.url = result.url;

                if (this.searchType === 'carHireOffice') {
                    const displayArr = result.display.split(',');
                    let primaryDisplay = displayArr[0];
                    let secondaryDisplay = displayArr.slice(1).join(', ');

                    resultDiv.innerHTML = `
                        <div class="icon ${result.type.toLowerCase()}"></div>
                        <div class='text-container'>
                            <div class='result-title'>${primaryDisplay}</div>
                            <div class='result-secondary'>${secondaryDisplay}</div>
                        </div>
                    `
                }
                else {

                    resultDiv.innerHTML = `
                        <div class="icon ${result.type.toLowerCase()}"></div>
                        <div class='text-container'>
                            <div class='result-title'>${result.display}</div>
                        </div>
                    `
                    if (result.displaySecondary !== null)
                        resultDiv.querySelector('.text-container').insertAdjacentHTML('beforeend', `<div class='result-secondary'>${result.displaySecondary}</div>`);
                }

                resultDiv.addEventListener('click',()=>{

                    let targetElement = this.element;


                    let secondary = '';

                    if (this.searchType === 'carHireOffice') {
                        targetElement = this.searchElement;
                    }
                    else{
                        const secondaryArr = result.displaySecondary ? result.displaySecondary.split(',') : [];
                        if (secondaryArr.length > 0) {
                            let count = 0
                            for(let i= secondaryArr.length-1; i>=0; i--){
                                if (count > 1) {
                                    break;
                                }
                                secondary = secondaryArr[i].trim() + ', ' + secondary;
                                count ++;
                            }
                        }

                        secondary = secondary.slice(0,-2);
                    }

                    this.hideSearchResults();
                    this.clearButton.classList.remove('hidden');
                    this.termInput.value = result.display;

                    targetElement.dispatchEvent(new CustomEvent('destination-selected',{
                        detail: {
                            value: {
                                code: result.code,
                                url: result.url,
                                display: result.display,
                                secondary: secondary ?? ''
                            },
                            type: result.type
                        }
                        })
                    );

                });

                if(this.separateCategories === true && result.type && result.type !== prevType){

                    let typeDiv = document.createElement('div');
                    typeDiv.classList.add('search-result-type');
                    const label = resultDiv.dataset.category;
                    typeDiv.innerHTML = label.charAt(0).toUpperCase() + label.slice(1);
                    resultsListDiv.append(typeDiv);
                    prevType = result.type;

                }

                resultsListDiv.append(resultDiv);
            }
        }

        if (this.searchResults.classList.contains('hidden')){
            this.searchResults.classList.remove('hidden');
        }

        // Add the list to the search results holder and clear the request limiter

        this.searchResults.append(resultsListDiv);
        this.searchResultsList = this.searchElement.querySelector('.sw-dd-content');
        this.requestLimiter.clear();
    }

    resetState(){
        Object.assign(this.currentState,this.defaultState);
    }

    setTerm(term){

        if (term === null || term === undefined) {
            term = '';
        }

        this.termInput.value = term;
        this.currentState.inputValue = term;
        this.currentState.searchTerm = term;

        if (term === '') {
            this.clearButton.classList.add('hidden');
        }
        else {
            this.clearButton.classList.remove('hidden');
        }
    }
}

export default DestinationSearch;

