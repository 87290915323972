class FilterItem {

    i18n = com.tcl.data.i18n;

    element;
    checkbox;
    filterCheckbox;
    filterCheckboxIcon;


    filterObject ={};


    constructor(element, filterObject, widgetObject) {
        this.element = element;
        this.filterObject = filterObject;
        this.widgetObject = widgetObject;
        this.render();
        return this;
    }

    render() {
        const filterItem = this.element;
        // Override the built-in checkbox with the filter item checkbox
        this.checkbox = filterItem.querySelector('input');
        this.filterCheckbox = filterItem.querySelector('.filter-item-checkbox');
        this.filterCheckbox.tabIndex = 0;
        this.filterCheckboxIcon = filterItem.querySelector('.filter-item-checkbox-icon');
        this.checkbox.classList.add('hidden');


        const label= filterItem.querySelector('label');
        label.addEventListener('click',(e)=>{
            e.preventDefault();
            this.toggleActive();
        });

        // If the filter should be active, check the checkbox
        if (this.filterObject.filterData[this.checkbox.name].includes(this.checkbox.value)) {
            this.checkbox.checked = true;
            this.filterCheckbox.classList.add('active');
            this.filterCheckboxIcon.classList.remove('hidden');
        }

        this.filterCheckbox.addEventListener('click',()=>{
            this.toggleActive();
        });
        this.filterCheckbox.addEventListener('keydown',(e)=>{
            if (e.key === 'Enter' || e.key === ' '){
                e.preventDefault();
                this.toggleActive();
            }
        });

        // Add the event listener to the checkbox
        this.checkbox.addEventListener('change', () => {
            if (this.checkbox.checked) {
                this.filterObject.addFilter(this.checkbox.name,this.checkbox.value);
                this.widgetObject.addFilter(this.checkbox.name,this.checkbox.value, this.checkbox.labels[0].innerText);
            }
            else {
                this.filterObject.removeFilter(this.checkbox.name,this.checkbox.value);
                this.widgetObject.removeFilter(this.checkbox.name,this.checkbox.value);
            }

            document.dispatchEvent(new CustomEvent('filterChange',{detail: {sender: this.element.parentElement}}));
        });
    }

    toggleActive(){

        this.checkbox.checked = !this.checkbox.checked;

        if (this.checkbox.checked) {

            this.filterCheckbox.classList.add('active');
            this.filterCheckboxIcon.classList.remove('hidden');

        }
        else {

            this.filterCheckbox.classList.remove('active');
            this.filterCheckboxIcon.classList.add('hidden');

        }

        this.checkbox.dispatchEvent(new Event('change'));
    }


}
export default FilterItem;