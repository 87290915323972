export async function getLoginURL() {
    const response = await fetch('/api/services/auth/marriott');
    const json = await response.json();
    if (response.status !== 200) {
        return {status:'error'};
    }
    return json;
}

export async function getUserCurrentPoints(){
    const response = await fetch('/api/services/auth/marriott_member_point_balance');
    const json = await response.json();
    if (response.status !== 200) {
        return {status:'error'};
    }
    return json;
}

export async function getTwoFactorMethods(){
    const response = await fetch('/api/services/auth/marriott_two_fa');
    const json = await response.json();
    if (response.status !== 200) {
        return {status:'error'};
    }
    return json;
}

export async function requestTwoFactorVerification(twoFactorMethodData = null){

    if (twoFactorMethodData === null || twoFactorMethodData.method.type === null || twoFactorMethodData.method.index === null || twoFactorMethodData.transactionId === null){
        return {status:'error'};
    }
    const method = twoFactorMethodData.method.type;
    const index = twoFactorMethodData.method.index;

    if (twoFactorMethodData[method].code === null || twoFactorMethodData[method].maskedValues[index] === null){
        return {status:'error'};
    }

    const bodyObject = {
        twoFaMethod: twoFactorMethodData[method].code,
        transactionId: twoFactorMethodData.transactionId,
        maskedValue: twoFactorMethodData[method].maskedValues[index]
    }

    let response = await fetch('/api/services/auth/marriott_two_fa/start',{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyObject)
    });

    const json = await response.json();
    if (response.status !== 200) {
        return {status:'error'};
    }
    return json;
}

export async function confirmTwoFactorVerification(twoFactorMethodData = null, otpValue = null){

    if (twoFactorMethodData === null || otpValue === null || twoFactorMethodData.method.type === null || twoFactorMethodData.method.index === null || twoFactorMethodData.transactionId === null){
        return {status:'error'};
    }
    const method = twoFactorMethodData.method.type;
    const index = twoFactorMethodData.method.index;

    if (twoFactorMethodData[method].code === null || twoFactorMethodData[method].maskedValues[index] === null){
        return {status:'error'};
    }

    const bodyObject = {
        twoFaMethod: twoFactorMethodData[method].code,
        transactionId: twoFactorMethodData.transactionId,
        otpValue: otpValue
    }

    let response = await fetch('/api/services/auth/marriott_two_fa/complete',{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyObject)
    });

    const json = await response.json();
    if (response.status !== 200) {
        return {status:'error'};
    }
    return json;
}