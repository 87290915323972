import FilterItem from "./filterItem";
import {getEventsProducts} from "../services/products";

class EventFiltersWidget {

    i18n = com.tcl.data.i18n;

    filtersObject = {
        major: [],
        minor: [],
    };

    filterData = {
        major: [],
        minor: [],
    }

    majorMinorMapping= [
        ['67','2','14','59','57','60','52','3','4','9','58','56','5','55','6','8','7'], // ARTS & FAMILY : 1
        ['10','51','54','11','13','15','17','18','19','22','20','21','16','23','49','24','25','26','27','12','53','28'], // MUSIC : 2
        ['44','29','30','31','61','41','63','48','32','40','33','66','65','34','62','42','35','37','38','43','64','39','36'] // SPORTS : 3
    ]



    filterItems = [];

    element;
    resultsCountElement;
    filterPillContainer;
    pillHolder;
    infoContainer;
    resultsContainer;

    timeout = null;
    outstandingRequests = 0;
    lazyLoader;


    constructor(lazyLoader) {
        this.element = document.getElementById('events-results-filters');
        this.resultsCountElement =  document.getElementById('num-results');
        this.filterPillContainer = document.getElementById('filter-pill-container');
        this.pillHolder = document.getElementById('active-filter-pills');
        this.infoContainer = document.getElementById('info-container');
        this.resultsContainer = document.getElementById('events-results');
        this.lazyLoader = lazyLoader;

        this.initialiseFilterItems();

        this.addEventListeners();
    }

    initialiseFilterItems() {

        const filterItems = this.element.querySelectorAll('.filter-item');

        for (let filterItem of filterItems) {
            this.filterItems.push(new FilterItem(filterItem, this, this));
        }
    }

    addEventListeners() {
        document.addEventListener('click', (e) => {
            const tgt = e.target;

            if (tgt.classList.contains('show-children-icon')) {
                tgt.classList.toggle('open');
                tgt.parentElement.nextElementSibling.classList.toggle('hidden');
            }
        });

        const filtersToggle = document.getElementById('toggle-filters');
        const filtersContainer = document.getElementById('events-results-filters');
        const countAndPills = document.getElementById('filter-pill-container').parentElement;
        const header = document.querySelector('header');
        const burgerButton = header.querySelector('nav .burger-button');

        filtersToggle.addEventListener('click', (e) => {
            filtersContainer.classList.toggle('filters-open');

            if (filtersContainer.classList.contains('filters-open')) {
                filtersToggle.innerHTML = `
                ${this.i18n['i18n_APPLY']}
                <div class="icon cross">
                    <svg class="styledIcon cross">
                        <use xlink:href="#crossIcon"></use>
                    </svg>
                </div>
            `;
                header.classList.add('eventFiltersOpen');
                countAndPills.classList.add('eventFiltersOpen');
                burgerButton.querySelector('.icon').classList.add('burgActive');
            }
            else {
                filtersToggle.innerHTML = `
                <div class="icon filters">
                    <svg class="styledIcon grid">
                        <use xlink:href="#gridIcon"></use>
                    </svg>
                </div>
                ${this.i18n['i18n_FILTERS']}
            `;
                header.classList.remove('eventFiltersOpen');
                countAndPills.classList.remove('eventFiltersOpen');
                burgerButton.querySelector('.icon').classList.remove('burgActive');
            }
        });

        burgerButton.addEventListener('click', (e) => {
            if (header.classList.contains('eventFiltersOpen')) {
                filtersContainer.classList.remove('filters-open');
                countAndPills.classList.remove('eventFiltersOpen');
                filtersToggle.innerHTML = `
                <div class="icon filters">
                    <svg class="styledIcon grid">
                        <use xlink:href="#gridIcon"></use>
                    </svg>
                </div>
                ${this.i18n['i18n_FILTERS']}
            `;
                header.classList.remove('eventFiltersOpen');
                burgerButton.querySelector('.icon').classList.remove('burgActive');
            }
        });
    }

    addFilter(filterType, filterValue, displayValue = null) {
        if (!displayValue)
            return;

        const type = filterType.toLowerCase();

        if (type === 'major') {
            const majorItem = this.filterItems.find(item => item.checkbox.name === filterType && item.checkbox.value === filterValue);
            const showChildrenIcon = majorItem.element.querySelector('.show-children-icon');

            if (!showChildrenIcon?.classList.contains('open') && !majorItem.filterCheckboxIcon.classList.contains('partial')) {
                showChildrenIcon.classList.add('open');
                majorItem.element.nextElementSibling.classList.remove('hidden');
            }

            if (majorItem.filterCheckboxIcon.classList.contains('partial')) {
                majorItem.filterCheckboxIcon.classList.remove('partial');
                majorItem.filterCheckboxIcon.classList.add('hidden');
                majorItem.filterCheckbox.classList.remove('active');
                majorItem.checkbox.checked = false;

                const minorCategories = this.majorMinorMapping[filterValue - 1];

                for (let minorCategory of minorCategories) {
                    const item = this.filterItems.find(item => item.checkbox.name === 'minor' && item.checkbox.value === minorCategory);

                    if (this.filterData['minor'].includes(minorCategory)) {
                        item.filterCheckbox.dispatchEvent(new Event('click'));
                        this.removeFilter('minor', minorCategory);
                    }

                    item.filterCheckbox.classList.remove('implicit');
                }

                this.requestResults();
                return;
            }
        }

        this.filterData[filterType].push(filterValue);

        if (type === 'major') {

            const minorCategories = this.majorMinorMapping[filterValue - 1];

            for (let minorCategory of minorCategories) {
                const item = this.filterItems.find(item => item.checkbox.name === 'minor' && item.checkbox.value === minorCategory);


                if (this.filterData['minor'].includes(minorCategory)) {
                    item.filterCheckbox.dispatchEvent(new Event('click'));
                    this.removeFilter('minor', minorCategory);
                }

                item.filterCheckboxIcon.classList.remove('hidden');
                item.filterCheckbox.classList.add('implicit');

            }
        }
        else if (type === 'minor') {
            const majorCategory = (this.majorMinorMapping.findIndex(minorCategories => minorCategories.includes(filterValue)) + 1).toString();
            const siblingMinorCategories = this.majorMinorMapping[majorCategory - 1];

            const majorItem = this.filterItems.find(item => item.checkbox.name === 'major' && item.checkbox.value === majorCategory);

            if (majorItem) {
                if (this.filterData['major'].includes(majorCategory)) {
                    majorItem.checkbox.checked = false;
                    this.removeFilter('major', majorCategory, true);
                }

                majorItem.filterCheckbox.classList.add('active');
                majorItem.filterCheckboxIcon.classList.remove('hidden');
                majorItem.filterCheckboxIcon.classList.add('partial');

                for (let minorCategory of siblingMinorCategories) {
                    const item = this.filterItems.find(item => item.checkbox.name === 'minor' && item.checkbox.value === minorCategory);

                    if (!this.filterData['minor'].includes(minorCategory)) {
                        item.filterCheckboxIcon.classList.add('hidden');
                    }

                    item.filterCheckbox.classList.remove('implicit');

                }
            }

        }


        const filterPill = document.createElement('div');
        filterPill.classList.add('filter-pill');
        filterPill.dataset.type = filterType;
        filterPill.dataset.value = filterValue;

        switch (type) {
            default: {
                filterPill.innerHTML = `
                    <div class='filter-pill-text ${filterType}'>${displayValue.toLowerCase()}</div>
                    <div class='icon cross'>
                        <svg class="styledIcon cross">
                            <use xlink:href="#crossIcon"></use>
                        </svg>
                    </div>
                `;
            }
        }

        const clearPill = Array(...this.pillHolder.children).find(pill => pill.dataset.type === 'clear');

        if (clearPill) {
            clearPill.insertAdjacentElement('beforebegin', filterPill);
            clearPill.querySelector('.filter-count').innerText = `(${this.pillHolder.children.length - 1})`;
        }
        else {
            const clearPill = document.createElement('div');
            clearPill.classList.add('filter-pill', 'clear');
            clearPill.dataset.type = 'clear';
            clearPill.innerHTML = `<div class='filter-pill-text'>${this.i18n['i18n_CLEAR_ALL']}</div><div class="filter-count">(1)</div><div class='icon cross'></div>`;
            this.pillHolder.insertAdjacentElement('afterbegin', clearPill);
            clearPill.insertAdjacentElement('beforebegin', filterPill);

            clearPill.addEventListener('click', () => {
                this.resetFilters();
            });
        }

        filterPill.addEventListener('click',()=> {

            const item = this.filterItems.find(item => item.checkbox.name === filterType && item.checkbox.value === filterValue);
            item.filterCheckbox.dispatchEvent(new Event('click'));
            this.removeFilter(filterType, filterValue);

        });

        this.requestResults();

    }

    removeFilter(filterType, filterValue, internal = false) {

        if (!this.filterData[filterType].includes(filterValue))
            return;

        if (filterType === 'major' && !internal) {
            const minorCategories = this.majorMinorMapping[filterValue - 1];

            for (let minorCategory of minorCategories) {
                const item = this.filterItems.find(item => item.checkbox.name === 'minor' && item.checkbox.value === minorCategory);

                if (this.filterData['minor'].includes(minorCategory)) {
                    item.filterCheckbox.dispatchEvent(new Event('click'));
                    this.removeFilter('minor', minorCategory);
                }

                item.filterCheckbox.classList.remove('implicit');
            }
        }

        if (filterType === 'minor' && !internal) {
            const majorCategory = (this.majorMinorMapping.findIndex(minorCategories => minorCategories.includes(filterValue)) + 1).toString();

            if (majorCategory !== '0') {

                const siblingMinorCategories = this.majorMinorMapping[majorCategory - 1];

                let siblingsActive = false;
                for (let minorCategory of siblingMinorCategories) {

                    if (minorCategory === filterValue) {
                        continue;
                    }

                    if (this.filterData['minor'].includes(minorCategory)) {
                        siblingsActive = true;
                        break;
                    }
                }
                if (!siblingsActive) {
                    const majorItem = this.filterItems.find(item => item.checkbox.name === 'major' && item.checkbox.value === majorCategory);

                    majorItem.filterCheckbox.classList.remove('active');
                    majorItem.filterCheckboxIcon.classList.add('hidden');
                    majorItem.filterCheckboxIcon.classList.remove('partial');
                }
            }
        }


        for (let i = 0; i < this.pillHolder.children.length; i++) {
            if (this.pillHolder.children[i].dataset.type === filterType && this.pillHolder.children[i].dataset.value === filterValue) {
                this.pillHolder.removeChild(this.pillHolder.children[i]);
                break;
            }
        }

        if (this.pillHolder.children.length === 1) {
            this.pillHolder.innerHTML = '';
        }
        else{
            const clearPill = Array(...this.pillHolder.children).find(pill => pill.dataset.type === 'clear');
            clearPill.querySelector('.filter-count').innerText = `(${this.pillHolder.children.length - 1})`;
        }

        this.filterData[filterType] = this.filterData[filterType].filter(value => value !== filterValue);

        this.requestResults();
    }

    resetFilters() {

        this.filterData = {
            major: [],
            minor: []
        }

        this.pillHolder.innerHTML = '';
        for (let item of this.filterItems) {
            item.checkbox.checked = false;

            item.filterCheckboxIcon.classList.add('hidden');

            item.filterCheckbox.classList.remove('active');
            item.filterCheckbox.classList.remove('implicit');
            item.filterCheckboxIcon.classList.remove('partial');
        }

        this.requestResults();

    }

    setStoredData() {
        //Needed for compatibility with filterItem.js
    }

    requestResults() {
        if (this.blockUpdate) {
            this.blockUpdate = false;
            return;
        }

        if (this.timeout)
            clearTimeout(this.timeout);

        this.infoContainer.classList.remove('hidden');
        this.resultsContainer.innerHTML = '';
        this.resultsCountElement.classList.add('hidden');
        this.infoContainer.querySelector('.info').innerHTML = this.i18n['i18n_LOADING'];
        this.infoContainer.querySelector('.loader').classList.remove('hidden');

        this.timeout = setTimeout(() => {
            this.sendResultsRequest();
            this.timeout = null;
        }, 2000);
    }

    sendResultsRequest() {
        this.outstandingRequests++;

        const params = new URLSearchParams(window.location.search);
        const term = params.get('term');
        const start = params.get('start');
        const end = params.get('end');

        let urlParams = {
            term: term,
            start: start,
            end: end,
        };

        getEventsProducts(urlParams, this.filterData['major'], this.filterData['minor']).then((data) => {
            this.outstandingRequests--;

            if (this.outstandingRequests > 0)
                return;

            if (data.status === 'failed') {
                this.infoContainer.querySelector('.info').innerHTML = this.i18n['i18n_NO_RESULTS'];
                this.infoContainer.querySelector('.loader').classList.add('hidden');

                return;
            }

            if (data.data.length === 0) {
                this.infoContainer.querySelector('.info').innerHTML = this.i18n['i18n_NO_RESULTS'];
                this.infoContainer.querySelector('.loader').classList.add('hidden');
                return;
            }

            this.infoContainer.classList.add('hidden');
            this.resultsCountElement.classList.remove('hidden');
            if (data.data.length === 200)
                this.resultsCountElement.innerHTML = `${this.i18n['i18n_TOP']} ${data.data.length} ${this.i18n['i18n_RESULTS']}`;
            else if (data.data.length === 1)
                this.resultsCountElement.innerHTML = `${data.data.length} ${this.i18n['i18n_RESULT']}`;
            else {
                this.resultsCountElement.innerHTML = `${data.data.length} ${this.i18n['i18n_RESULTS']}`;
            }

            for (let item of data.data) {
                this.resultsContainer.insertAdjacentHTML('beforeend', item);
            }

            this.lazyLoader.updateObserver();

        }, (error) => {
            this.outstandingRequests--;

            if (this.outstandingRequests > 0)
                return;

            this.infoContainer.querySelector('.info').innerHTML = this.i18n['i18n_NO_RESULTS'];
            this.infoContainer.querySelector('.loader').classList.add('hidden');
            console.log(error);
        });


    }

    setCategories(categories) {

        const categoryTypeArr = categories.split('|');
        const majorCategories = categoryTypeArr[0].split(',');

        for (let majorCategory of majorCategories) {
            const item = this.filterItems.find(item => item.checkbox.name === 'major' && item.checkbox.value === majorCategory);

            if (!item) {
                continue;
            }

            this.blockUpdate = true;
            item.filterCheckbox.dispatchEvent(new Event('click'));
        }

        if (categoryTypeArr.length === 1) {
            return;
        }

        const minorCategories = categoryTypeArr[1].split(',');

        for (let minorCategory of minorCategories) {
            const item = this.filterItems.find(item => item.checkbox.name === 'minor' && item.checkbox.value === minorCategory);

            if (!item) {
                continue;
            }

            this.blockUpdate = true;
            item.filterCheckbox.dispatchEvent(new Event('click'));
        }

    }

    showNoResultsMessage() {
        this.infoContainer.classList.remove('hidden');
        this.resultsContainer.innerHTML = '';
        this.resultsCountElement.classList.add('hidden');

        this.infoContainer.querySelector('.info').innerHTML = this.i18n['i18n_NO_RESULTS'];
    }

    static getCurrentSearchString() {

        let searchString = '';

        let majorCategories = [];
        let minorCategories = [];

        const filterItems = document.querySelectorAll('.filter-item');

        for (let item of filterItems) {
            if (item.querySelector('input').checked) {
                if (item.querySelector('input').name === 'major') {
                    majorCategories.push(item.querySelector('input').value);
                }
                else {
                    minorCategories.push(item.querySelector('input').value);
                }
            }
        }

        const urlParams = new URLSearchParams(window.location.search);

        let term = '';
        let dates = '';
        let categories = '';

        if (urlParams.has('term')) {
            term = `term=${urlParams.get('term')}`;
        }

        if (urlParams.has('start') && urlParams.has('end')) {
            if (term !== '') {
                dates = `&start=${urlParams.get('start')}&end=${urlParams.get('end')}`;
            }
            else{
                dates = `start=${urlParams.get('start')}&end=${urlParams.get('end')}`;
            }
        }

        if (majorCategories.length > 0 || minorCategories.length > 0) {

            if (term === '' && dates === '') {
                categories = `categories=`;
            }
            else {
                categories = `&categories=`;
            }

            if (majorCategories.length > 0) {
                categories += `${majorCategories.join(',')}`;
            }

            if (minorCategories.length > 0) {
                categories += `|${minorCategories.join(',')}`;
            }
        }

        searchString = `${term}${dates}${categories}`;

        return searchString;
    }
}

export default EventFiltersWidget;