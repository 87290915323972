import RequestLimiter from '../utils/requestLimiter';
import {destinationPropertiesSearch, searchService} from '../services/search';
import Icons from "../utils/icons";

class EventsSearch {
    i18n = com.tcl.data.i18n;

    // ALL ICONS NEED TO BE CHECKED AND SOURCED FROM CORRECT LOCATIONS
    searchIcon = Icons.searchIcon;

    element;
    searchElement;
    termInput;
    clearButton;
    requestLimiter;
    searchResults;
    searchResultsList
    maxTermLength;
    initialTerm;
    initialSearchTerm;

    defaultState = {
        inputValue: '',
        searchTerm: '',
        start: 0,
        end: 0,
        validationPattern: RegExp(/^[\p{L}\p{N}\-\s\.,'/&]*$/u),
        keyboardPosition: -1
    }

    currentState = Object.assign({},this.defaultState);
    widgetInstance;

    constructor(element, searchElement, initialDisplay, initialSearchTerm, maxTermLength, widgetInstance = null) {
        this.element = element;
        this.searchElement = searchElement;
        this.requestLimiter = new RequestLimiter();
        this.maxTermLength = maxTermLength;
        this.initialTerm = initialDisplay;
        this.initialSearchTerm = initialSearchTerm;
        this.widgetInstance = widgetInstance;
        this.render()
        return this;
    }

    render() {  //Render the search widget

        this.termInput = this.searchElement.querySelector('input');
        this.searchResults = this.searchElement.querySelector('.sw-dropdown');
        this.searchResultsList = this.searchElement.querySelector('.sw-dd-content');

        const clearButton = this.searchElement.querySelector('.search-clear-button');
        this.clearButton = clearButton;

        this.addRecentSearches();

        // If there is an initial term, set the input value, current state and show the clear button
        if (this.initialTerm !== null && this.initialTerm !== undefined && this.initialTerm !== '') {
            this.termInput.value = this.initialTerm;
            this.currentState.start = this.initialTerm.length;
            this.currentState.end = this.initialTerm.length;
            this.currentState.searchTerm = this.initialSearchTerm ?? this.initialTerm;
            clearButton.classList.remove('hidden');
        }

        this.currentState.inputValue = this.termInput.value;

        // Clear button click and keydown handlers, clear the input and search results
        clearButton.tabIndex = 0;
        const clearInput = () =>{
            this.termInput.value = '';
            this.searchResultsList.innerHTML = '';
            this.resetState();
            this.removeHoveredStyle();
            clearButton.classList.add('hidden');
            this.termInput.focus();
            this.searchResults.classList.add('hidden');

            this.element.dispatchEvent(new CustomEvent('result-selected',{
                    detail: {
                        type: 'reset',
                        term: null
                    }
                })
            );
        }

        clearButton.addEventListener('click',(e)=>{
            e.preventDefault();
            clearInput();
        });
        clearButton.addEventListener('keydown',(e)=>{
            if (e.key === 'Enter' || e.key === 'Return') {
                clearInput();
            }
        });

        // Term input exit focus handler
        this.termInput.addEventListener('focusout', (e) => {
            // If the user is clicking on a search result, clear the input and navigate to the result
            if (e.relatedTarget !== null) {
                if (e.relatedTarget.classList.contains('clear-button')) {
                    this.termInput.focus();
                }
                else if(e.relatedTarget.classList.contains('search-result')) {
                    e.relatedTarget.dispatchEvent(new Event('click'));
                }
                else if (e.relatedTarget.classList.contains('search-clear-button')) {
                    e.relatedTarget.dispatchEvent(new Event('click'));
                    this.termInput.focus();
                }
                else{
                    this.hideSearchResults();
                }
            }
            // Otherwise, hide the search results by adding 'hidden' css class
            else {
                this.hideSearchResults();
            }

        });

        // Term input focus handler, remove 'hidden' css class to show results
        this.termInput.addEventListener('focusin', () => {
            if (this.searchResultsList.children.length === 0){
                this.addRecentSearches();
            }

            if (this.searchResultsList.children.length > 0) {
                this.searchResults.classList.remove('hidden');
            }
        });

        // Term input keyup handler, check if the term is long enough to search and if so, search
        this.termInput.addEventListener('input', () => {

            // Ensure only unicode letters and spaces are entered
            if (this.currentState.validationPattern.test(this.termInput.value)) {
                this.currentState.inputValue  = this.termInput.value;
            }
            else {
                this.termInput.value = this.currentState.inputValue;
                this.termInput.setSelectionRange(this.currentState.start,this.currentState.end);
            }

            // If the input is empty, hide the clear button
            if (this.termInput.value === '') {
                clearButton.classList.add('hidden');
            }
            else {
                clearButton.classList.remove('hidden');
            }

            // Get the search term from the input
            const inputTerm = this.termInput.value;
            //let searchTerm = inputTerm.trim();
            let searchTerm = inputTerm;

            // Limit the length of the search term to the max input length
            if (searchTerm.length > this.maxTermLength) {
                searchTerm = searchTerm.substring(0, this.maxTermLength);
            }

            // If the new search term is the same as the stored search term, don't search again
            if (searchTerm.toLowerCase() === this.currentState.searchTerm.toLowerCase()) {
                return;
            }
            else{
                this.currentState.searchTerm = searchTerm;
            }

            this.element.dispatchEvent(new CustomEvent('input-changed',{
                    detail: {
                        term: this.currentState.searchTerm
                    }
                })
            );
        });

        // Input keydown handler, handle keyboard navigation,searching,clearing and selecting results
        this.termInput.addEventListener('keydown',(e)=>{
            const resultsList = this.searchResultsList
            this.currentState.start = this.termInput.selectionStart;
            this.currentState.end = this.termInput.selectionEnd;

            // Recursive function to select the next or previous result
            const selectResult = (direction,results) => {
                // If the current item is not a search result, move to the next result
                this.currentState.keyboardPosition += direction;
                if (this.currentState.keyboardPosition >= results.length) {
                    this.currentState.keyboardPosition = 0;
                }
                if (this.currentState.keyboardPosition < 0) {
                    this.currentState.keyboardPosition = -1;
                    this.removeHoveredStyle();
                    return;
                }
                const currentItem = results[this.currentState.keyboardPosition];
                if (currentItem.classList.contains('search-result') && !currentItem.classList.contains('hidden')){
                    this.removeHoveredStyle();
                    currentItem.classList.add('hover');
                    this.searchResults.scrollTop = currentItem.offsetTop-300;
                }
                else {
                    selectResult(direction, results);
                }
            }

            // If the user presses the down arrow, select the next result. Wrapping around to the first result after the last result
            if (e.key === 'ArrowDown') {
                if (resultsList === null || resultsList === undefined) {
                    return;
                }
                const results = resultsList.children;

                if (results.length === 0) {
                    return;
                }

                selectResult(1,results);
                return;
            }

            // If the user presses the up arrow, select the previous result. Clearing the selection after the first result
            if (e.key === 'ArrowUp') {
                if (resultsList === null || resultsList === undefined || this.currentState.keyboardPosition === -1) {
                    return;
                }
                const results = resultsList.children;
                if (results.length === 0) {
                    return;
                }
                selectResult(-1,results);
                return;
            }

            // If the user presses escape, clear the input and hide the search results
            if (e.key === 'Escape') {
                clearInput();
                return;
            }

            // If the user presses enter and three characters or more are entered, navigate to the search results or selected product page
            if (e.key === 'Enter' || e.key === 'Return') {
                if (this.currentState.keyboardPosition !== -1) {
                    const results = resultsList.children;
                    if (results.length === 0) {
                        return;
                    }
                    const result = results[this.currentState.keyboardPosition];
                    result.dispatchEvent(new Event('click'));
                }
                else{
                    this.element.dispatchEvent(new CustomEvent('search-requested'));
                    this.searchResults.classList.add('hidden');
                }
            }
        });

        // If the user hover over a search result, update the keyboard position for future keyboard navigation
        this.searchResults.addEventListener('mouseover',(e)=>{
            const hoveredResult = e.target;
            if (hoveredResult.classList.contains('search-result')) {
                this.currentState.keyboardPosition = [...hoveredResult.parentNode.children].indexOf(hoveredResult)

                this.removeHoveredStyle();
                hoveredResult.classList.add('hover');
            }
        });

        // Add the search element to the container and start the request limiter
        this.requestLimiter.start();
    }

    hideSearchResults(){
        //Hide the search results
        if (this.searchResults.classList.contains('hidden') || this.searchResultsList.children.length === 0)
            return;
        this.searchResults.scrollTop = -100;
        this.searchResults.classList.add('hidden');

        this.currentState.keyboardPosition = -1
        this.removeHoveredStyle();
    }

    storeSearchTerm(term = null){
        //Store the search term in session storage
        if(term === null)
            return;

        let recentSearches = localStorage.getItem('recentEventSearches');

        if (!recentSearches) {
            recentSearches = [];
        }
        else {
            recentSearches = recentSearches.split('|');
        }

        let searchString = `${term}`

        if (recentSearches.includes(searchString)) {
            return;
        }

        recentSearches.unshift(searchString);

        if (recentSearches.length > 3) {
            recentSearches.splice(3,recentSearches.length-3);
        }
        localStorage.setItem('recentEventSearches', recentSearches.join('|')+'|');
    }

    addRecentSearches() {
        //Add the recent searches to the search results
        let recentSearches = localStorage.getItem('recentEventSearches');
        if (recentSearches === null || recentSearches === undefined || recentSearches.length === 0) {
            return;
        }
        if (!recentSearches.includes('|')) {
            recentSearches = recentSearches.replaceAll(',', '|');
            localStorage.setItem('recentEventSearches',recentSearches);
        }

        for (const recentSearch of recentSearches.split('|').filter(Boolean)) {

            const term = recentSearch;
            const li = document.createElement('li');
            li.classList.add('search-result');
            li.classList.add('recent');
            li.tabIndex = -1;
            li.innerHTML = `
                <div class='search-result-text'>${term}</div>
                <div class="search-clear-button">
                    <svg class="styledIcon cross">
                        <use xlink:href="#crossIcon"></use>
                    </svg>
                </div>
            `

            li.addEventListener('click',()=> {
                this.currentState.searchTerm = term;
                this.termInput.value = term;
                this.clearButton.classList.remove('hidden');
                this.hideSearchResults();


                this.element.dispatchEvent(new CustomEvent('result-selected',{
                        detail: {
                            type: 'recent',
                            term: term
                        }
                    })
                );
            });

            const clearButton = li.querySelector('.search-clear-button');
            clearButton.tabIndex = -1;
            clearButton.addEventListener('click',()=>{
                recentSearches = localStorage.getItem('recentEventSearches');
                if (recentSearches === null || recentSearches === undefined || recentSearches.length === 0) {
                    return;
                }
                recentSearches = recentSearches.split('|').filter(Boolean);
                recentSearches.splice(recentSearches.indexOf(recentSearch),1);
                localStorage.setItem('recentEventSearches',recentSearches.join('|')+'|');
                li.remove();

                if (this.searchResultsList.children.length === 0) {
                    this.searchResults.classList.add('hidden');
                }

            });

            this.searchResultsList.append(li);
        }
    }

    removeRecentSearches(){
        //Hide the recent searches
        const recentSearches = this.searchResultsList.querySelectorAll('.recent');
        if (recentSearches.length > 0) {
            for (let recentSearch of recentSearches) {
                recentSearch.remove();
            }
        }
    }

    removeHoveredStyle(){
        if (this.searchResultsList === null || this.searchResultsList === undefined) {
            return;
        }
        const hoveredResults = this.searchResultsList.querySelectorAll('.hover')
        if (hoveredResults.length > 0) {
            for (let result of hoveredResults) {
                result.classList.remove('hover');
            }
        }
    }

    resetState(){
        Object.assign(this.currentState,this.defaultState);
    }

    setTerm(term = null,searchTerm = null){

        if (term === null || term === undefined) {
            term = '';
        }

        this.termInput.value = term;
        this.currentState.inputValue = term;
        this.currentState.searchTerm = searchTerm ?? term;

        if (term === '') {
            this.clearButton.classList.add('hidden');
        }
        else {
            this.clearButton.classList.remove('hidden');
        }
    }
}

export default EventsSearch;

