
class FavouritesButton{

    element;
    constructor(element) {
        this.element = element;
        this.element.tabIndex = 0;
        this.render();
    }
    render() {
        FavouritesButton.updateFavouritesCount();
        return this;
    }

    static updateFavouritesCount() {
        const favouritesButton = document.querySelector('.favourites-button');
        let iconType = '';
        let numFavourites = 0;
        if (localStorage.getItem('favourites') !== null) {
            let favourites = localStorage.getItem('favourites').split('|').filter(Boolean);
            if (favourites.length > 0) {
                iconType = ' filled';
                numFavourites = favourites.length;
            }
        }

        favouritesButton.innerHTML = `
            <div class="icon${iconType}">
                <svg class="styledIcon favourites-icon">
                    <use xlink:href="#favouriteIcon"></use>
                </svg>
            </div>${numFavourites}
        `;

        const favouritesCount = document.getElementById('favourites-count');
        if (favouritesCount)
            favouritesCount.innerText = numFavourites.toString();

        const userPinFavouritesCount = document.getElementById('user-pin-favourites-count');
        if (userPinFavouritesCount)
            userPinFavouritesCount.innerText = numFavourites.toString();
    }

    static showNotification(title, isFavourite) {
        //Create notification container if it doesn't exist
        let notificationContainer = document.querySelector('.card-notifications');
        if (notificationContainer === null || notificationContainer === undefined) {
            notificationContainer = document.createElement('div');
            notificationContainer.classList.add('card-notifications');
            document.body.appendChild(notificationContainer);
        }

        //Create notification
        const notification = document.createElement('div');
        notification.classList.add('card-notification-card');
        let notificationMessage = title
        notificationMessage += isFavourite ? ` has been added to your favorites.` :  ` has been removed from your favorites.`;
        notification.innerHTML = `<div class="card-notification">${notificationMessage}</div>`;

        //Slide out notification
        const slideOut = () => {
            if (notification.classList.contains("slide-out"))
                return;
            notification.classList.add("slide-out");
            notification.addEventListener("transitionend", () => {
                scaleOut();
            });
        }

        //Scale out notification once it has slid out
        const scaleOut = () => {
            notification.removeEventListener('transitionend', scaleOut);
            notification.classList.add("scale-out");
            notification.addEventListener("transitionend", () => {
                removeNotification();
            });
        }

        //Remove notification from DOM and container if no more notifications
        const removeNotification = () => {
            notification.remove();
            if (notificationContainer.children.length === 0){
                notificationContainer.remove();
            }
        }

        //Close popup on click
        notification.addEventListener('click', () => {
            slideOut();
        });

        //Close popup after 3 seconds
        setTimeout(()=>{
            slideOut();
        },2500);

        //Add notification to container
        notificationContainer.appendChild(notification);
    }



}
export default FavouritesButton;