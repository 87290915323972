import Card from "./card";

class PageBlocks{

    i18n = com.tcl.data.i18n;

    constructor() {

        if (document.querySelectorAll('.shelf-block').length === 0)
            return;

        this.render();
    }

    render() {
        for (let shelfBlock of document.querySelectorAll('.shelf-block')) {

            for (let card of shelfBlock.getElementsByClassName('product-card')){
                Card.initialiseCard(card);
            }
        }

        document.addEventListener('click',(e)=> {

            const tgt = e.target;

            if (tgt.classList.contains('view-all-button')) {

                const cards = document.getElementById(`block-${tgt.dataset.id}-cards`).children;

                let visibleItems = parseInt(tgt.dataset.cards);
                visibleItems = preloadedCardsMoreHandler(tgt, visibleItems, cards, 8, true);
                tgt.dataset.cards = visibleItems.toString();
            }
        });

        const preloadedCardsMoreHandler = (loadMoreButton,visibleItems,cards,cardsToLoad,allowViewLess = false)=>{

            if (loadMoreButton.innerText === this.i18n['i18n_VIEW_LESS']) {
                for (let i = 8; i < cards.length; i++) {
                    cards[i].classList.add('hidden');
                }
                loadMoreButton.innerText = this.i18n['i18n_VIEW_MORE'];
                return 8;
            }

            const targetProducts = visibleItems + cardsToLoad;
            for (let i = visibleItems; i < targetProducts; i++) {
                if (i >= cards.length-1) {
                    if (allowViewLess) {
                        loadMoreButton.innerText = this.i18n['i18n_VIEW_LESS'];
                    }
                    else {
                        loadMoreButton.classList.add('hidden');
                    }
                    cards[i].classList.remove('hidden');
                    visibleItems++;
                    break;
                }
                cards[i].classList.remove('hidden');
                visibleItems++;
            }
            return visibleItems;
        }


    }
}

export default PageBlocks;