import {getCookie} from "../utils/cookieHelpers";
import EventFiltersWidget from "./eventFiltersWidget";
import FilterObject from "./filterObject";

class ShareButton{

    socials = {
        "link":"",
        "email":"mailto:?subject=Check%20out%20this%20page&body=",
        "x": "https://twitter.com/intent/tweet?url=",
        "whatsapp":"whatsapp://send?text=",
        "facebook":"https://www.facebook.com/sharer/sharer.php?u=",
    };

    element;
    buttonContainer;
    linkOnly = false;


    constructor(element,linkOnly = false){
        this.element = element;
        this.buttonContainer = this.element.parentElement;

        this.linkOnly = linkOnly;
        if (linkOnly) {
            this.socials = {
                "link": "",
            };
            this.buttonContainer = this.element;
        }

        this.render();
    }

    render() {
        const socials = Object.keys(this.socials);
        for (let i = 0; i < socials.length; i++) {
            this.addShareButton(socials[i]);
        }

        if (!this.linkOnly) {
            this.element.addEventListener('click', () => {
                this.element.classList.toggle('active');
                this.buttonContainer.classList.toggle('open');
            });
        }

    }

    addShareButton(socialName){
        let shareButton = null;
        if (socialName === 'link'){
            shareButton = document.createElement('div');
            shareButton.className = `share-${socialName} icon-button`;
            if (this.linkOnly) {
                shareButton.classList.add('page');
                shareButton.innerHTML = `
                    <svg class="styledIcon shareIcon">
                        <use xlink:href="#shareIcon"></use>
                    </svg>
                `;
            }
            else {
                shareButton.innerHTML = `
                    <svg class="styledIcon linkIcon">
                        <use xlink:href="#linkIcon"></use>
                    </svg>
                `;
            }

            shareButton.addEventListener('click',()=> {
                let url = window.location.href;

                if (this.linkOnly){

                    if (url.match('/events$|/events/search')) {
                        const query = EventFiltersWidget.getCurrentSearchString();
                        url = `${window.location.origin}${window.location.pathname}${query.length > 0 ? `?${query}` : ''}`;
                    }
                    else {

                        const availabilitySearchTermData = getCookie('availabilitySearchTerm');
                        console.log(availabilitySearchTermData)
                        const availabilitySearchDatesData = getCookie('availabilitySearchDates');
                        let urlQuery = new URLSearchParams('');

                        if (availabilitySearchTermData?.length > 0)
                            urlQuery.set('term', availabilitySearchTermData);

                        if (availabilitySearchDatesData?.from?.length > 0)
                            urlQuery.set('from', availabilitySearchDatesData.from);

                        if (availabilitySearchDatesData?.to?.length > 0)
                            urlQuery.set('to', availabilitySearchDatesData.to);

                        const filtersData = FilterObject.getCurrentFilterData();

                        if (filtersData.categories.length > 0)
                            urlQuery.set('categories', filtersData.categories.join('_'));

                        if (filtersData.days.length > 0)
                            urlQuery.set('days', filtersData.days.join('_'));

                        if (filtersData.times.length > 0)
                            urlQuery.set('times', filtersData.times.join('_'));

                        if (filtersData.durations.length > 0)
                            urlQuery.set('durations', filtersData.durations.join('_'));

                        if (filtersData.term !== '')
                            urlQuery.set('filter', encodeURIComponent(filtersData.term));


                        const query = urlQuery.toString();
                        url = `${window.location.origin}${window.location.pathname}${query.length > 0 ? `?${query}` : ''}`;
                    }
                }

                navigator.clipboard.writeText(url).then(() => {
                    // show a notification to the user
                    const notification = document.createElement('div');
                    notification.className = 'copy-notify';
                    notification.innerText = 'Copied to clipboard';

                    if (this.linkOnly){
                        if (getComputedStyle(this.buttonContainer).getPropertyValue('display') === 'none') {
                            document.querySelector('header .share-menu.item')?.appendChild(notification);
                        }
                        else{
                            this.buttonContainer.appendChild(notification);
                        }
                    }
                    else{
                        this.buttonContainer.parentElement.appendChild(notification);
                    }

                    setTimeout(() => {
                        notification.remove();
                    }, 1000);
                }, (err) => {
                    console.error('Could not copy text: ', err);
                });
            });

        }
        else {
            shareButton = document.createElement('a');
            shareButton.className = `share-${socialName} icon-button`;
            shareButton.innerHTML = `
                <svg class="styledIcon ${socialName}Icon">
                    <use xlink:href="#${socialName}Icon"></use>
                </svg>
            `;
            shareButton.href = this.socials[socialName] + window.location.href;
            shareButton.target = "_blank";

        }
        this.buttonContainer.appendChild(shareButton);
    }

}
export default ShareButton;