export async function searchService(term) {

    if (term.length < 2) {
        return [];
    }

    const urlEncodedTerm = encodeURIComponent(term);
    const response = await fetch('/api/services/search?term=' + urlEncodedTerm);
    const json = await response.json();
    if (response.status !== 200) {
        return [];
    }
    return json;
}

export async function transfersSearch(term){

    const urlEncodedTerm = encodeURIComponent(term);

    const response = await fetch('/api/services/search/transfers?term=' + urlEncodedTerm);

    const json = await response.json();

    if (response.status !== 200) {
        return [];
    }

    return json;
}

export async function destinationSearch(term){
    const urlEncodedTerm = encodeURIComponent(term);
    const response = await fetch('/api/services/search/destinations?term=' + urlEncodedTerm);

    const json = await response.json();

    if (response.status !== 200) {
        return [];
    }
    return json;
}

export async function destinationPropertiesSearch(term = null,latitude = null,longitude = null) {

    let query = '';

    if(term!==null) {
        const urlEncodedTerm = encodeURIComponent(term);
        query += `term=${urlEncodedTerm}`;
    }
    else if(latitude!==null && longitude!==null) {
        query += query.length > 0 ? '&' : '';
        query += `latitude=${latitude}&longitude=${longitude}`;
    }

    if (query.length === 0) {
        return [];
    }

    const response = await fetch('/api/services/search/destinations_properties?' + query);

    const json = await response.json();

    if (response.status !== 200) {
        return [];
    }
    return json;
}

export async function activitySearch(term = null) {

    let query = '';

    if(term!==null) {
        const urlEncodedTerm = encodeURIComponent(term);
        query += `term=${urlEncodedTerm}`;
    }

    if (query.length === 0) {
        return [];
    }

    const response = await fetch('/api/services/search/destinations?' + query);

    const json = await response.json();

    if (response.status !== 200) {
        return [];
    }
    return json;
}