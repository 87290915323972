import {formatDate, generateDate} from "../utils/dateTimeHelpers";

class DatePickerDualMonth{

    element;
    i18n = com.tcl.data.i18n;
    currentSelectedDates = {
        from: null,
        to: null
    };
    firstPaneDate;
    activeDaysOfWeek = [0,1,2,3,4,5,6];
    allowToday = true;
    allowFrom = null;
    useDateArray = false;
    availableDates = [];
    shouldSelectFrom = true;

    constructor(element,currentSelectedDates = {from: formatDate(new Date()),to: formatDate(new Date())},activeDaysOfWeek = null,allowToday = true,allowFrom = null,availableDates = null){
        this.element = element;

        const from = currentSelectedDates.from ? currentSelectedDates.from : formatDate(new Date());
        const to = currentSelectedDates.to ? currentSelectedDates.to : formatDate(new Date());

        this.currentSelectedDates.from = generateDate(from);
        this.currentSelectedDates.from.setHours(12,0,0,0);

        this.currentSelectedDates.to = generateDate(to);
        this.currentSelectedDates.to.setHours(13,1,0,0);

        this.firstPaneDate = generateDate(from);

        this.allowToday = allowToday;
        this.allowFrom = allowFrom;
        if (activeDaysOfWeek !== null)
            this.activeDaysOfWeek = activeDaysOfWeek;

        if (availableDates !== null) {
            this.useDateArray = true;
            this.availableDates = availableDates;
        }

        this.render();
        return this;
    }

    render() {
        this.element.innerHTML = `
        <div class="panes">
        <div class="first pane">
            <div class="month-container">
                <div class="prev-month"></div>
                <div class="month-year"></div>
                <div class="padding"></div>
            </div>
            <div class="day-initials">
                <div class="day-initial">S</div>
                <div class="day-initial">M</div>
                <div class="day-initial">T</div>
                <div class="day-initial">W</div>
                <div class="day-initial">T</div>
                <div class="day-initial">F</div>
                <div class="day-initial">S</div>
            </div>
            <div class="days"></div>
        </div>
        <div class="divider"></div>
        <div class="second pane">
            <div class="month-container">
                <div class="padding"></div>
                <div class="month-year"></div>
                <div class="next-month"></div>
            </div>
            <div class="day-initials">
                <div class="day-initial">S</div>
                <div class="day-initial">M</div>
                <div class="day-initial">T</div>
                <div class="day-initial">W</div>
                <div class="day-initial">T</div>
                <div class="day-initial">F</div>
                <div class="day-initial">S</div>
            </div>
            <div class="days"></div>
        </div>   
        </div>
        <div class="buttons">
            <div class="skip-btn">${this.i18n['i18n_SKIP']}</div>
           <div class="apply-btn">${this.i18n['i18n_APPLY']}</div>
        </div>       
        `
        const firstPane = this.element.querySelector('.first');
        const firstPaneDays = firstPane.querySelector('.days');
        const firstPaneMonthYearLabel = firstPane.querySelector('.month-year');

        const secondPane = this.element.querySelector('.second');
        const secondPaneDays = secondPane.querySelector('.days');
        const secondPaneMonthYearLabel = secondPane.querySelector('.month-year');

        const applyBtn = this.element.querySelector('.apply-btn');
        const skipBtn = this.element.querySelector('.skip-btn');

        applyBtn.addEventListener('click',()=>{
            this.element.dispatchEvent(new CustomEvent('date-selected', {
                detail: {
                    type: 'apply',
                    value: {
                        from: formatDate(this.currentSelectedDates.from),
                        to: formatDate(this.currentSelectedDates.to)
                    }
                }
            }));
        });

        skipBtn.addEventListener('click',()=>{
            this.element.dispatchEvent(new CustomEvent('date-selected', {
                detail: {
                    type: 'skip',
                    value: {
                        from: null,
                        to: null
                    }
                }
            }));
        });

        let secondPaneDate = generateDate(this.firstPaneDate);
        secondPaneDate.setMonth(secondPaneDate.getMonth()+1);

        const refreshMonth = (monthYearDate,daysElement,monthYearLabel) => {
            const today = new Date();
            today.setHours(0,0,0,0);

            daysElement.innerHTML = '';
            monthYearLabel.innerHTML = `${monthYearDate.toLocaleString('en-US', { month: 'long' })} ${monthYearDate.getFullYear()}`;

            const month = monthYearDate.getMonth();
            const year = monthYearDate.getFullYear();

            const daysInMonth = new Date(year, month+1, 0).getDate();
            const startDay = new Date(year, month, 1).getDay();

            let dayNumber = 1;
            for (let i = 0; i < daysInMonth+startDay;i++) {
                const dayHolderElement = document.createElement('div');
                const dayElement = document.createElement('div');
                if (i >= startDay) {
                    dayHolderElement.classList.add('day-holder');
                    dayElement.classList.add('day');
                    let currentDate = new Date(year,month,dayNumber)
                    currentDate.setHours(13,0,0,0);
                    const dayOfWeek = currentDate.getDay();

                    if (!this.useDateArray) {
                        if (!this.allowToday && formatDate(currentDate) === formatDate(today))
                            dayElement.classList.add('disabled');
                        else if (currentDate < today || !this.activeDaysOfWeek.includes(dayOfWeek))
                            dayElement.classList.add('disabled');
                        else if (this.allowFrom !== null && currentDate < this.allowFrom) {
                            dayElement.classList.add('disabled');
                        }
                        else if (formatDate(currentDate) === formatDate(this.currentSelectedDates.from)) {
                            dayElement.classList.add('selected', 'from');
                        }
                        else if (formatDate(currentDate) === formatDate(this.currentSelectedDates.to)) {
                            dayElement.classList.add('selected', 'to');
                        }
                        if (this.currentSelectedDates.from && this.currentSelectedDates.to) {
                            if (formatDate(this.currentSelectedDates.from) !== formatDate(this.currentSelectedDates.to)) {
                                if (currentDate >= this.currentSelectedDates.from && currentDate <= this.currentSelectedDates.to) {
                                    dayHolderElement.classList.add('highlighted');
                                }
                            }
                        }

                    }
                    else {
                        if(!this.availableDates.find((date)=> Object.keys(date)[0] === formatDate(currentDate)))
                            dayElement.classList.add('disabled');
                        else if (formatDate(currentDate) === formatDate(this.currentSelectedDates.from))
                            dayElement.classList.add('selected', 'from');
                        else if (formatDate(currentDate) === formatDate(this.currentSelectedDates.to))
                            dayElement.classList.add('selected', 'to');
                    }

                    dayElement.innerHTML = dayNumber.toString();
                    dayNumber++;

                    dayElement.addEventListener('click',()=>{
                        if(dayElement.classList.contains('disabled'))
                            return;


                        if (this.shouldSelectFrom || this.currentSelectedDates.from > currentDate) {
                            this.currentSelectedDates.from = currentDate;
                            this.currentSelectedDates.to = currentDate;
                            this.shouldSelectFrom = false;
                        }
                        else {
                            this.currentSelectedDates.to = currentDate;
                            this.shouldSelectFrom = true;
                        }

                        secondPaneDate = generateDate(this.firstPaneDate);
                        secondPaneDate.setMonth(secondPaneDate.getMonth()+1);

                        refreshMonth(this.firstPaneDate,firstPaneDays,firstPaneMonthYearLabel);
                        refreshMonth(secondPaneDate,secondPaneDays,secondPaneMonthYearLabel);

                        this.element.dispatchEvent(new CustomEvent('date-selected', {
                            detail: {
                                type: 'date',
                                value: {
                                    from: formatDate(this.currentSelectedDates.from),
                                    to: formatDate(this.currentSelectedDates.to)
                                }
                            }
                        }));
                    });
                }
                dayHolderElement.appendChild(dayElement);
                daysElement.appendChild(dayHolderElement);
            }
        };

        refreshMonth(this.firstPaneDate,firstPaneDays,firstPaneMonthYearLabel);
        refreshMonth(secondPaneDate,secondPaneDays,secondPaneMonthYearLabel);

        const prevMonth = this.element.querySelector('.prev-month');
        const nextMonth = this.element.querySelector('.next-month');

        prevMonth.addEventListener('click',()=>{

            this.firstPaneDate.setMonth(this.firstPaneDate.getMonth()-1);

            secondPaneDate = generateDate(this.firstPaneDate);
            secondPaneDate.setMonth(secondPaneDate.getMonth()+1);

            if (this.useDateArray)
                this.element.dispatchEvent(new CustomEvent('month-changed', {detail:{direction:-1}}));

            refreshMonth(this.firstPaneDate,firstPaneDays,firstPaneMonthYearLabel);
            refreshMonth(secondPaneDate,secondPaneDays,secondPaneMonthYearLabel);
        }
        );
        nextMonth.addEventListener('click',()=>{

            this.firstPaneDate.setMonth(this.firstPaneDate.getMonth()+1);

            secondPaneDate = generateDate(this.firstPaneDate);
            secondPaneDate.setMonth(secondPaneDate.getMonth()+1);

            if (this.useDateArray)
                this.element.dispatchEvent(new CustomEvent('month-changed', {detail:{direction:1}}));

            refreshMonth(this.firstPaneDate,firstPaneDays,firstPaneMonthYearLabel);
            refreshMonth(secondPaneDate,secondPaneDays,secondPaneMonthYearLabel);
        }
        );

    }

    updateSettings(dateToSelect = formatDate(new Date()),activeDaysOfWeek = [0,1,2,3,4,5,6], allowToday = true,allowFrom = null) {

        const date = dateToSelect ? generateDate(dateToSelect) : formatDate(new Date());

        this.currentSelectedDates.from = generateDate(date);
        this.firstPaneDate = generateDate(date);

        this.activeDaysOfWeek = activeDaysOfWeek;
        this.allowToday = allowToday;
        if (allowFrom !== null) {
            this.allowFrom = generateDate(allowFrom);
            this.allowFrom.setHours(12, 0, 0, 0);
        }
        else {
            this.allowFrom = null;
        }
        this.render();
    }

    setDates(datesToSelect = {from: formatDate(new Date()),to: formatDate(new Date())}){

        const from = datesToSelect.from ? datesToSelect.from : formatDate(new Date());
        const to = datesToSelect.to ? datesToSelect.to : formatDate(new Date());

        this.currentSelectedDates.from = generateDate(from);
        this.currentSelectedDates.from.setHours(12,0,0,0);

        this.currentSelectedDates.to = generateDate(to);
        this.currentSelectedDates.to.setHours(13,1,0,0);

        this.firstPaneDate = generateDate(from);
        this.render();
    }


}
export default DatePickerDualMonth;