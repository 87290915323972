class CookieBanner{
    i18n = com.tcl.data.i18n;
    constructor() {
        this.cookieBanner = document.createElement('div');
        this.cookieBanner.classList.add('cookie-banner');
        this.cookieBanner.innerHTML = `
            <div class="cookie-banner-content">
                <p class="cookie-banner-text">${this.i18n['i18n_COOKIE_BANNER_TEXT']}</p>
                <div class="cookie-banner-close"></div>
            </div>
        `
        this.cookieBannerClose = this.cookieBanner.querySelector('.cookie-banner-close');
        this.cookieBannerClose.addEventListener('click', (e)=>{
            this.cookieBanner.remove();
            localStorage.setItem('cookieBannerClosed', 'true');
        });
        document.body.appendChild(this.cookieBanner);
    }
}
export default CookieBanner;