class LazyLoader {
    observer = null;
    elementsToObserve = [];
    className;

    constructor(className) {
        this.className = className;
        this.elementsToObserve = document.querySelectorAll(this.className);
        if (this.observer === null) {
            this.initObserver();
        }
        for (let element of this.elementsToObserve) {
            this.observer.observe(element);
        }
        return this;
    }

    updateObserver(){
        this.elementsToObserve = document.querySelectorAll(this.className);
        this.initObserver();

        for (let element of this.elementsToObserve) {
            this.observer.observe(element);
        }
    }

    initObserver() {
        this.observer = new IntersectionObserver(this.lazyLoadImage, {
            root: window.document,
            rootMargin: '100px 400px',
            threshold: 0
        });
    }

    lazyLoadImage(entries, observer) {
        for (let entry of entries) {
            if (entry.isIntersecting === false) {
                continue;
            }
            const element = entry.target;
            element.style.backgroundImage = `url(${element.dataset.image})`;
            observer.unobserve(element);
        }
    }
}
export default LazyLoader;