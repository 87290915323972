class RequestLimiter {
    current = null;
    next = null;
    reset = false;

    setNext(next) {
        this.next = next;
        this.reset = true;
    }

    clear() {
        this.current = null;
    }

    start() {
        var that = this;

        window.setInterval(function() {
            if (that.next === null || that.current !== null) {
                return;
            }

            if (that.reset === true) {
                that.reset = false;

                return;
            }

            that.current = that.next;
            that.next = null;
            that.current();

        }, 250)
    }

    isRunning() {
        return this.current !== null;
    }
}

export default RequestLimiter;
