import {generateDate} from "../utils/dateTimeHelpers";

export async function searchOffices(term) {
    const searchTerm = term.replace(/[^a-zA-Z0-9 ]/g, "");

    const response = await fetch('/api/services/search/car_hire_offices?term=' + searchTerm);
    const json = await response.json();
    if (response.status !== 200) {
        return [];
    }
    return json;
}

export async function getOfficeSlots(code) {

    const response = await fetch('/api/services/car_hire/office/slots?code=' + code);
    const json = await response.json();
    if (response.status !== 200) {
        return [];
    }
    return json;
}

export async function getOfficeAdditionals(code,brand) {

    const response = await fetch(`/api/services/car_hire/office/additionals?code=${code}&brand=${brand}`);
    const json = await response.json();
    if (response.status !== 200) {
        return [];
    }
    return json;
}

export async function getOfficeDetails(code) {

    const response = await fetch('/api/services/car_hire/office?code=' + code);
    const json = await response.json();
    if (response.status !== 200) {
        return [];
    }
    return json;
}


export async function getAvailability(data) {

    for (let key in data) {
        if (data[key] === null || data[key] === "") {
            return {results:[]};
        }
    }

    let sourceProperty = null;
    if (com.tcl.data.property)
        sourceProperty = com.tcl.data.property;
    if (sourceProperty === null)
        sourceProperty = '';
    else
        sourceProperty = `&source_property=${sourceProperty}`


    let pickupOffice = data.locations.pickup.code;
    pickupOffice = `&pickup_office=${pickupOffice}`;

    let pickupDate = generateDate(data.dateTimes.from.date);
    let pickupDay = pickupDate.getDate();
    if (pickupDay < 10)
        pickupDay =`0${pickupDay}`;
    let pickupMonth = pickupDate.getMonth() + 1;
    if (pickupMonth < 10)
        pickupMonth =`0${pickupMonth}`;
    let pickupYear = pickupDate.getFullYear();
    pickupDate = `${pickupYear}${pickupMonth}${pickupDay}`;
    pickupDate = `&pickup_date=${pickupDate}`;

    let pickupTime = data.dateTimes.from.time;
    pickupTime = pickupTime.replace(':', '');
    pickupTime = `&pickup_time=${pickupTime}`

    let dropoffOffice = data.locations.dropoff.code;
    dropoffOffice = `&dropoff_office=${dropoffOffice}`;

    let dropoffDate = generateDate(data.dateTimes.to.date);
    let dropoffDay = dropoffDate.getDate();
    if (dropoffDay < 10)
        dropoffDay =`0${dropoffDay}`;
    let dropoffMonth = dropoffDate.getMonth() + 1;
    if (dropoffMonth < 10)
        dropoffMonth =`0${dropoffMonth}`;
    let dropoffYear = dropoffDate.getFullYear();
    dropoffDate = `${dropoffYear}${dropoffMonth}${dropoffDay}`;
    dropoffDate = `&dropoff_date=${dropoffDate}`;

    let dropoffTime = data.dateTimes.to.time;
    dropoffTime = dropoffTime.replace(':', '');
    dropoffTime = `&dropoff_time=${dropoffTime}`

    const response = await fetch(`/api/services/car_hire/availability?${pickupOffice}${pickupDate}${pickupTime}${dropoffOffice}${dropoffDate}${dropoffTime}${sourceProperty}`);
    if (response.status !== 200) {
        return {results:[]};
    }
    const json = await response.json();
    return json;
}

export async function bookNow(data) {

    let body={
        firstName:null,
        lastName:null,
        email:null,
        phoneAreaCode:null,
        phoneNumber:null,
        loyalty:{
            marriottBonvoyMembershipNumber:null,
            hertzGoldClubMembershipNumber:null
        },
        token:null
    };

    body.firstName = data.customer.firstName;
    body.lastName = data.customer.lastName;
    body.email = data.customer.email;
    body.phoneAreaCode = data.customer.countryCode;
    body.phoneNumber = data.customer.phone;
    body.loyalty.marriottBonvoyMembershipNumber = data.customer.loyalty.marriottBonvoyMembershipNumber;
    body.loyalty.hertzGoldClubMembershipNumber = data.customer.loyalty.hertzGoldClubMembershipNumber;
    body.token = data.car.token;

    for (let key in body) {
        if (body[key] === null || body[key] === "") {
            return null;
        }
    }


    const response = await fetch('/api/services/car_hire/book', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
    });
    const json = await response.json();
    if (response.status !== 200) {
        return null;
    }
    return json;
}

export async function updateRate(bookingData) {

    let body ={
        token:null,
        extras:{
            sipp:null,
            equipments:null,
        }
    };
    body.token = bookingData.car.token;
    body.extras.equipments = bookingData.extras;
    body.extras.sipp = bookingData.car.vehicle.code;

    for (let key in body) {
        if (body[key] === null || body[key] === "") {
            return null;
        }
    }

    const response = await fetch('/api/services/car_hire/rate_update', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
    });

    if (response.status !== 200) {
        console.log(response.status);
        return null;
    }
    return await response.json();

}