import FavouritesButton from "./favouritesButton";
import CookieBanner from "./cookieBanner";
import shareButton from "./shareButton";
import LoginWindow from "./loginWindow";
import {getCookie, setCookie} from "../utils/cookieHelpers";
import {getLoginURL, getUserCurrentPoints} from "../services/login";


class Header {
    element;

    searchWidget;
    headerTabs;
    headerBody;
    headerSummary;
    pageTabs;
    pageBody;
    pageSummary;

    searchHeader = false;
    switching = false;
    ignoreMobileScroll = false;
    pageShare = false;

    loginWindow = null;
    loginUsingIFrame = false;

    constructor(element, pageShare = false) {
        this.element = element;
        this.pageShare = pageShare;
        this.setDefaultCurrency();
        if (window.location.pathname.match(/\/checkout/)) {
            if (this.loginUsingIFrame === false) {
                this.getMarriottLoginUrl();
            }
        }
        this.initialiseLoginButton();
        this.initialiseUserPin();
        this.initialiseLoginEvents();
        this.render();
        return this;
    }

    render() {

        const customer = getCookie('customer');
        const token = getCookie('token');

        if (customer && token) {
            this.loggedIn(customer);
        }

        if (getCookie('switchUser')) {
            setCookie('switchUser');

            //Uncomment if user switching rather than log-out is required. Causes pop-up blocker to fire if using external login method.
            //const loginWindow = new LoginWindow();
        }

        if (navigator.userAgent.indexOf('iPhone') > -1) {
            document
                .querySelector("[name=viewport]")
                .setAttribute("content", "width=device-width, initial-scale=1, maximum-scale=1");
        }

        if (!localStorage.getItem('cookieBannerClosed')) {
            new CookieBanner();
        }

        new FavouritesButton(this.element.querySelector('.favourites-button'));

        this.searchWidget = document.getElementById('search-widget');
        if (this.searchWidget) {

            if (!this.searchWidget?.parentElement.classList.contains('nav-container')) {

                this.headerTabs = document.querySelector('header .search-widget.tabs');
                this.headerBody = document.querySelector('header .search-widget.body');
                this.headerSummary = document.querySelector('header .search-widget.summary');

                this.pageTabs = this.searchWidget.querySelector('.sw-tabs');
                this.pageBody = this.searchWidget.querySelector('.sw-body');
                this.pageSummary = document.getElementById('sw-summary');

                window.addEventListener('scroll', () => {
                    if (this.switching)
                        return;

                    if (window.innerWidth <= 925 && this.ignoreMobileScroll) {
                        this.searchToHeader();
                        return;
                    }

                    let offset = 0;
                    if (this.searchWidget.parentElement.classList.contains('hero-content')){
                        offset = this.searchWidget.offsetHeight;
                    }
                    else if (getComputedStyle(this.headerTabs).getPropertyValue('display') === 'none') {
                        offset = this.pageTabs.offsetHeight;
                    }

                    if (window.scrollY > this.searchWidget.offsetTop + offset - this.element.offsetHeight) {
                        if (!this.searchHeader) {
                            this.searchToHeader();
                        }
                    } else {

                        if (this.searchHeader) {
                            this.searchToPage();
                        }
                    }
                });

                if (this.ignoreMobileScroll){
                    this.searchToHeader();
                }
            }
            window.dispatchEvent(new Event('scroll'));
        }

        if (this.pageShare) {
            const share = this.element.querySelector('.share-page-button');
            if (share)
                new shareButton(share, true);
        }
////// NON-ELEVATED BURGER MENU
        document.querySelector('.burger-button')?.addEventListener('click', (e) => {

            if (this.element.classList.contains('searchOpen') || this.element.classList.contains('carFiltersOpen'))
                return;
            if (this.element.classList.contains('searchOpen') || this.element.classList.contains('eventFiltersOpen'))
                return;

            let burgList= document.querySelectorAll('.burgEffect');
            for (let item of burgList){
                item.classList.toggle('burgActive');
            }

            if (this.loginUsingIFrame === false) {
                this.getMarriottLoginUrl();
            }
        });

        document.querySelector('.burgMenu')?.addEventListener('click', (e) => {
            if (e.target.classList.contains('share-menu')){
                document.querySelector('.share-link.icon-button').dispatchEvent(new Event('click'));
                return;
            }
            if (e.target.tagName.toLowerCase() === 'a' || e.target.classList.contains('item')){
                let burgList= document.querySelectorAll('.burgEffect');
                for (let item of burgList){
                    item.classList.remove('burgActive');
                }
            }
        });
///////////

        // Added to ensure safari on iphone catches the scroll event.
        addEventListener("load", (event) => {
            window.dispatchEvent(new Event('scroll'));
            setTimeout(() => {
                window.dispatchEvent(new Event('scroll'));
            }, 1000);
        });

    }

    searchToHeader() {
        this.switching = true;

        this.searchWidget.classList.add('empty');
        const widgetHeight = getComputedStyle(this.searchWidget).getPropertyValue('height');

        this.searchHeader = true;

        this.headerBody.classList.remove('hidden');
        this.headerTabs.classList.remove('hidden');
        this.headerSummary.classList.remove('hidden');


        this.headerTabs?.appendChild(this.pageTabs);
        this.headerBody?.appendChild(this.pageBody);
        this.headerSummary?.appendChild(this.pageSummary);

        this.searchWidget.setAttribute('style', `height: ${widgetHeight}`)


        this.switching = false;
    }

    searchToPage() {
        this.switching = true;

        this.searchHeader = false;

        this.searchWidget?.appendChild(this.pageTabs);
        this.searchWidget?.appendChild(this.pageBody);
        this.searchWidget?.appendChild(this.pageSummary);

        this.headerBody.classList.add('hidden');
        this.headerTabs.classList.add('hidden');
        this.headerSummary.classList.add('hidden');

        this.searchWidget.removeAttribute('style');

        this.searchWidget.classList.remove('empty');

        this.switching = false;
    }

    setDefaultCurrency() {
        const cookie = document.cookie.split(';').find(item => item.includes('currency='));
        if (!cookie) {
            document.cookie = 'currency=USD; path=/';
        }
    }

    initialiseUserPin() {

        const pin = document.getElementById('user-login');
        const menu = pin?.querySelector('.user-menu');
        const logOut = menu?.querySelector('#header-logout-button');

        if (pin && menu) {

            document.addEventListener('click', (e) => {

                if (this.loginUsingIFrame === false) {
                    this.getMarriottLoginUrl();
                }

                if (e.target !== pin && !pin.contains(e.target)) {
                    menu.classList.add('hidden');
                    pin.querySelector('.arrow-icon')?.classList.remove('active');
                }
                else {

                    const cookie = getCookie('customer');
                    if (cookie !== null) {
                        menu.querySelector('.user-points').innerText = cookie.points;
                    }

                    if (!e.target.closest('.user-menu')){
                        menu.classList.toggle('hidden');
                        pin.querySelector('.arrow-icon')?.classList.toggle('active');
                        return;
                    }

                    menu.classList.remove('hidden');
                    pin.querySelector('.arrow-icon')?.classList.add('active');


                }
            });
        }

        if (menu && logOut) {

            logOut.addEventListener('click', () => {
                document.dispatchEvent(new CustomEvent('login-event', {detail: {eventType: 'switch_user'}}));
            });
        }
    }

    async getMarriottLoginUrl(forceUpdate = false){
        let loginCookie = localStorage.getItem('marriottLogin');

        if (loginCookie !== null && forceUpdate === false) {
            return;
        }

        await getLoginURL().then(response => {

            if (response.status === 'error') {
                console.error('Error:', response);
                this.getMarriottLoginUrl();
            }
            else if (response.status === 'success') {
                localStorage.setItem('marriottLogin', `${btoa(response.url)}`);
            }

        }, error => {
            this.getMarriottLoginUrl();
            console.error('Error:', error);
        });

    }

    async initialiseLoginButton() {

        const loginButton = document.getElementById('header-login-button');

        if (loginButton) {
            loginButton.addEventListener('click', () => {

                const loginCookie = localStorage.getItem('marriottLogin');

                if (this.loginUsingIFrame === false){
                    if (loginCookie !== null) {

                        window.open(atob(loginCookie), '_blank');
                        this.getMarriottLoginUrl(true);
                    }
                    else{
                        this.getMarriottLoginUrl(true);
                        return;
                    }
                }

                if (this.loginWindow === null) {
                    this.loginWindow = new LoginWindow(this.loginUsingIFrame);
                }
                else {
                    this.loginWindow.showWindow();
                }
            });
        }
    }

    initialiseLoginEvents(){
        document.addEventListener('login-event', (e) => {

            if (e.detail.eventType === 'login') {
                const token = getCookie('token');
                const customer = getCookie('customer');
                if (customer === null || token === null)
                    return;

                this.loggedIn(customer);
            }

            if (e.detail.eventType === 'logout') {
                this.loggedOut();
            }

            if (e.detail.eventType === 'switch_user') {
                this.loggedOut();

                setCookie('token');
                setCookie('customer');
                setCookie('switchUser', {shouldSwitch:true});

                window.location.reload();
            }
        });

        document.addEventListener('points-update', () => {
            const customer = getCookie('customer');
            if (customer === null)
                return;

            const menu = document.getElementById('user-login').querySelector('.user-menu');
            menu.querySelector('.user-points').innerText = customer.points;
        });
    }

    loggedIn(customer = null){
        if (customer === null)
            return;

        const pin = document.getElementById('user-login');
        const initials = pin.querySelector('.user-initials');
        const menu = pin.querySelector('.user-menu');
        const userName = menu.querySelector('.user-name');
        const userPoints = menu.querySelector('.user-points');
        const myBookings = menu.querySelector('.my-bookings');

        initials.innerText = `${customer.firstName[0]}${customer.lastName[0]}`.toUpperCase();
        userName.innerText = `${customer.firstName} ${customer.lastName}`;
        userPoints.innerText = '';
        myBookings.href = '/bookings/list';

        pin.classList.remove('logged-out');
        pin.classList.add('logged-in');
        menu.classList.add('hidden');
    }

    loggedOut() {

        const pin = document.getElementById('user-login');
        const initials = pin.querySelector('.user-initials');
        const menu = pin.querySelector('.user-menu');
        const userName = menu.querySelector('.user-name');
        const userPoints = menu.querySelector('.user-points');
        const myBookings = menu.querySelector('.my-bookings');

        initials.innerText = '';
        userName.innerText = 'Guest';
        userPoints.innerText = '';
        myBookings.href = '/bookings';

        pin.classList.remove('logged-in');
        pin.classList.add('logged-out');
        menu.classList.add('hidden');
    }

}
export default Header;


